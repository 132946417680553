import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { FiTruck } from "react-icons/fi";
import { HiOutlineShoppingBag, HiOutlineClipboardList, HiOutlineDocumentText, HiOutlineLogout } from "react-icons/hi";
import boxesPile from '../../assets/icons/boxesPile.svg'
import boxesPileActive from '../../assets/icons/boxesPileActive.svg'

import { customerRouteName } from '../../data/InfoSource';
import { ButtonSmall } from '../units/Button';

export const PanelMainMenu = ({ ecommerce, isCondensed, showLogoutModal }) => {

    const shopifyPages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/secommerce/panel/order`, icon: <HiOutlineClipboardList size={20} />, isPersonalizedIcon: false },
        { title: "Tiendas", routeInfo: `/${customerRouteName}/secommerce/panel/location-list`, icon: <HiOutlineShoppingBag size={20} />, isPersonalizedIcon: false },
        { title: "Ajustes de envío", routeInfo: `/${customerRouteName}/secommerce/panel/delivery-services`, icon: <FiTruck size={16} />, isPersonalizedIcon: false },
        { title: "Productos", routeInfo: `/${customerRouteName}/secommerce/panel/products`, icon: null, isPersonalizedIcon: true },
        { title: "Carta porte", routeInfo: `/${customerRouteName}/secommerce/panel/waybill-config`, icon: <HiOutlineDocumentText size={20} />, isPersonalizedIcon: false },
    ];

    const bigcommercePages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/bigcommerce/panel/order`, icon: <HiOutlineClipboardList size={20} /> },
        { title: "Tienda", routeInfo: `/${customerRouteName}/bigcommerce/panel/location-list`, icon: <HiOutlineShoppingBag size={20} /> },
        { title: "Ajustes de envío", routeInfo: `/${customerRouteName}/bigcommerce/panel/delivery-services`, icon: <FiTruck size={16} /> },
        { title: "Carta porte", routeInfo: `/${customerRouteName}/bigcommerce/panel/waybill-config`, icon: <HiOutlineDocumentText size={20} /> },
    ];

    const vtexPages = [
        { title: "Órdenes", routeInfo: `/${customerRouteName}/vtex/panel/order`, icon: <HiOutlineClipboardList size={20} /> },
        { title: "Tiendas", routeInfo: `/${customerRouteName}/vtex/panel/location-list`, icon: <HiOutlineShoppingBag size={20} /> },
        { title: "Ajustes de envío", routeInfo: `/${customerRouteName}/vtex/panel/delivery-services`, icon: <FiTruck size={16} /> },
        { title: "Carta porte", routeInfo: `/${customerRouteName}/vtex/panel/waybill-config`, icon: <HiOutlineDocumentText size={20} /> },
    ];

    const [isLinkClicked, setIsLinkClicked] = useState(false);

    const detectClick = (key) => {
        if (key === 3) {
            setIsLinkClicked(true)
        } else {
            setIsLinkClicked(false)
        }
    };

    return (
        <>
            {ecommerce === "shopify" &&
                <div className="panel-menu">
                    {shopifyPages.map((navItem, index) => (
                        <div key={index} onClick={() => detectClick(index)}>
                            {isCondensed ?
                                <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce} condensed`}>
                                    {navItem.isPersonalizedIcon ?
                                        <img src={isLinkClicked ? boxesPileActive : boxesPile} alt="products" />
                                        :
                                        navItem.icon
                                    }
                                </NavLink>
                                :
                                <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`}>
                                    {navItem.isPersonalizedIcon ?
                                        <>
                                            <img src={isLinkClicked ? boxesPileActive : boxesPile} alt="products" /> {navItem.title}
                                        </>
                                        :
                                        <>
                                            {navItem.icon} {navItem.title}
                                        </>
                                    }
                                </NavLink>
                            }
                        </div>
                    )
                    )}
                </div>
            }
            {ecommerce === "bigcommerce" &&
                <div className="panel-menu">
                    {bigcommercePages.map((navItem, index) => (
                        <div key={index}>
                            {isCondensed ?
                                <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce} condensed`}>
                                    {navItem.icon}
                                </NavLink>
                                :
                                <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`}>
                                    {navItem.icon} {navItem.title}
                                </NavLink>
                            }
                        </div>
                    )
                    )}
                </div>
            }
            {ecommerce === "vtex" &&
                <div className="panel-menu">
                    {vtexPages.map((navItem, index) => (
                        <div key={index}>
                            {isCondensed ?
                                <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce} condensed`}>
                                    {navItem.icon}
                                </NavLink>
                                :
                                <NavLink to={navItem.routeInfo} activeClassName={`menu-selected ${ecommerce}`}>
                                    {navItem.icon} {navItem.title}
                                </NavLink>
                            }
                        </div>
                    )
                    )}
                    <div className={isCondensed ? "logout-wrapper condensed" : "logout-wrapper"}>
                        <ButtonSmall
                            type="button"
                            btnTxt={isCondensed ? "" : "Cerrar sesión"}
                            icon={<HiOutlineLogout size={18} />}
                            showSpinner={false}
                            isDisabled={false}
                            onClickFn={showLogoutModal}
                            extraClass="btn-link btn-logout p-1"
                        />
                    </div>
                </div>
            }
        </>
    )
}