import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { fadeInAndUp, fadeInAndUpNoDelay } from '../../services/shared/animation.service';
import { ButtonSmall } from '../../components/units/Button';
import Skeleton from 'react-loading-skeleton';
import refresh from '../../assets/icons/refresh.svg';
import { ProductsGridRow } from '../../components/panel/products/ProductsGridRow';
import { FiSearch } from "react-icons/fi";
import { showError, showSuccess } from '../../services/shared/alert.service';
import { getProductsList, saveProductsList } from '../../services/panel.service';
import { useAuthStore } from '../../store';
import { deepCopy } from '../../services/shared/utils.service';

export const Products = () => {
    const user = useAuthStore((state) => state.authData.user);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTag, setSearchTag] = useState("");
    const [isReachMinCharsAmount, setIsReachMinCharsAmount] = useState(true);
    const [productsList, setProductsList] = useState([]);

    useEffect(() => {
      getAllProducts()
    }, [])
    
    const getAllProducts = async (storeId = user?.storeId, tag = searchTag) => {
        setIsLoading(true);
        try {
            const result = await getProductsList(storeId, tag);
            if (result && result.success) {
                setProductsList(result.products)
            }
            setIsLoading(false);
        } catch (error) {
            showError(error);
            setIsLoading(false);
        }
    }

    const prepareAndSetAllProducts = async() => {
        setIsLoading(true);
        try {
            const result = await saveProductsList({ products: [ ...productsList ]}, user?.storeId);
            if (result && result.success) {
                showSuccess(result.message);
            }
            setIsLoading(false);
        } catch (error) {
            showError(error);
            setIsLoading(false);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if(searchTag.length >= 3){
            setIsReachMinCharsAmount(true);
            getAllProducts(user?.storeId, searchTag);
        } else {
            setIsReachMinCharsAmount(false);
        }
    }

    const handleSearchTagChange = (e) => {
        setSearchTag(e.target.value);
        if (searchTag === "") {
            setIsReachMinCharsAmount(true);
        }
    }

    const handleChange = (e, idSelected) => {
        let arrToUpdateCopy = deepCopy(productsList);
        let selectedObjCopy = arrToUpdateCopy.find((element) => element.shopifyProductId === idSelected);
        let selectedObjIndex = arrToUpdateCopy.findIndex((element) => element.shopifyProductId === idSelected);
        selectedObjCopy[e.target.id] = Number(e.target.value);
        arrToUpdateCopy[selectedObjIndex] = selectedObjCopy;
        setProductsList(arrToUpdateCopy);
    }

    return (
        <div id="products-form" className="content-wrapper">
            <motion.div variants={fadeInAndUpNoDelay} initial="hidden" animate="visible" className='products-title-wrapper mb-4 mb-md-5'>
                <div>
                    <h1 className="main-title">Productos</h1>
                    <h2 className="main-subtitle">Completa las dimensiones de tus productos.</h2>
                </div>
                <ButtonSmall
                    type="button"
                    btnTxt="Guardar"
                    showSpinner={false}
                    isDisabled={false}
                    onClickFn={prepareAndSetAllProducts}
                    extraClass="btn-save"
                />
            </motion.div>
            <motion.div id='products-table' variants={fadeInAndUp} initial="hidden" animate="visible">
                <div className='topBar-container'>
                    <div className='d-flex align-items-center'>
                        <form className='search-wrapper' onSubmit={handleSearch}>
                            <FiSearch size={20} />
                            <input onChange={handleSearchTagChange} value={searchTag} type="text" name="q" id="search-input" placeholder='Buscar producto' />
                            {searchTag && <button type='button' onClick={() => {setSearchTag(""); setIsReachMinCharsAmount(true)}} className="close-btn">X</button>}
                        </form>
                    </div>
                    <button className='update-btn'
                    onClick={() => getAllProducts()}
                    >
                        <img src={refresh} alt="refresh" />
                    </button>
                </div>
                        {!isReachMinCharsAmount && <span className="invalid-msg">Escribe al menos 3 caracteres</span> }
                {isLoading ?
                    <>
                        <Skeleton count={1} height={70} style={{ marginBottom: "10px" }} />
                        <Skeleton count={1} height={30} style={{ marginBottom: "10px" }} />
                        <Skeleton count={4} height={50} style={{ marginBottom: "10px" }} />
                    </>
                    :
                    <div className='table-container mt-3'>

                        <div className='table-grid'>
                            <ul className="table-header black-semibold">
                                <li>Producto</li>
                                <li>Dimensiones</li>
                            </ul>
                            <div className='table-body'>
                                {productsList.map((prod, index) => (
                                    <ProductsGridRow key={`prod-${index}`} productData={prod} handleChange={handleChange} />
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </motion.div>
        </div>
    )
}

