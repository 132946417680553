import { Route, Switch } from "react-router-dom";
import { Credentials } from "../../../pages/onboarding/Credentials";
import { LocationsList } from "../../../pages/onboarding/LocationsList";
import { LocationSingle } from "../../../pages/onboarding/LocationSingle";
import { DeliveryServices } from "../../../pages/onboarding/DeliveryServices";
import { FinishSuccess } from "../../../pages/onboarding/FinishSuccess";
import { FinishError } from "../../../pages/onboarding/FinishError";
import PanelRoute from "../panel/panelRoute";
import { CreateCenter } from "../../../pages/onboarding/vtex-only/CreateCenter";

export default function CustomerShopifyRoute({ rootCustomerPath }) {
  return (
    <>
      <Switch>
        <Route exact path={`/${rootCustomerPath}/secommerce/credentials`} component={Credentials} />
        <Route exact path={`/${rootCustomerPath}/secommerce/location-list`} component={LocationsList} />
        <Route exact path={`/${rootCustomerPath}/secommerce/location-list/:id`} component={LocationSingle} />
        <Route exact path={`/${rootCustomerPath}/secommerce/create-center/:id`} component={CreateCenter} />
        <Route exact path={`/${rootCustomerPath}/secommerce/delivery-services`} component={DeliveryServices} />
        <Route exact path={`/${rootCustomerPath}/secommerce/finish`} component={FinishSuccess} />
        <Route exact path={`/${rootCustomerPath}/secommerce/finish-error`} component={FinishError} />

        <Route path={`/${rootCustomerPath}/secommerce/panel`} render={() => <PanelRoute rootCustomerPath={rootCustomerPath} /> }/> 

        <Route exact path={`/${rootCustomerPath}/secommerce`} component={Credentials} />
      </Switch>
    </>
  );
}
