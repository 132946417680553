import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { useAuthStore, useonboardingStore } from '../../store';
import { LocationForm } from '../../components/onboarding/locationConfig/LocationForm';
import { OutOfCoverage } from '../../components/onboarding/locationConfig/OutOfCoverage';

export const LocationSinglePanel = () => {
    const location = useLocation();
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const [singleLocation, setSingleLocation] = useState({});
    const [singleLocationIndex, setSingleLocationIndex] = useState(null);
    const [allLocations, setAllLocations] = useState(store?.currentLocations);

    useEffect(() => {
        let pathArray = location.pathname.split("/");
        let selectedId = pathArray[pathArray.length - 1];
        let list = store?.currentLocations;
        let singleLocSelected = list.find(loc => loc.locationId === selectedId);
        let singleLocIndex = list.findIndex(loc => loc.locationId === selectedId);
        setSingleLocation(singleLocSelected);
        setSingleLocationIndex(singleLocIndex);
    }, [])
    
    return (
        <div id="locationSinglePanel">
            {singleLocation.inCoverage ?
                <LocationForm ecommerce={ecommerce} singleLocationIndex={singleLocationIndex} allLocations={allLocations} {...singleLocation} />
                :
                <OutOfCoverage ecommerce={ecommerce} singleLocationIndex={singleLocationIndex} {...singleLocation} />
            }
        </div>
    )
}
