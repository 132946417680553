import React from 'react';
import cellInfo from '../../../assets/icons/cellInfo.svg';

export const TableHeader = ({ setIsReferenceModalShown }) => {
    return (
        <ul className="table-header black-semibold">
            <li>Origen</li>
            <li>Destino</li>
            <li>Paquete</li>
            <li>Envío</li>
            <li className='header-status'>
                Estatus del envío
                <button
                    type='button'
                    onClick={() => setIsReferenceModalShown(true)}
                >
                    <img src={cellInfo} alt="info" />
                </button>
            </li>
        </ul>
    )
}
