import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthStore, useonboardingStore } from '../../../store';
import { motion } from 'framer-motion';
import { fadeInAndRight } from '../../../services/shared/animation.service';
import { OnboardingStepper } from '../../../components/onboarding/OnboardingStepper';
import { CreateNewCenter } from '../../../components/onboarding/locationConfig/CreateNewCenter';
import { getSingleLocation } from '../../../services/onboarding.service';
import { showError } from '../../../services/shared/alert.service';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const CreateCenter = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const user = useAuthStore((state) => state.authData.user);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [previousData, setPreviousData] = useState({});
    const [singleLocation, setSingleLocation] = useState({});
    const [singleLocationIndex, setSingleLocationIndex] = useState(null);
    const [allLocations, setAllLocations] = useState(store?.currentLocations);

    useEffect(() => {
        if (ecommerce === "bigcommerce") {
            updateBCCenter();
        } else {
            setSingleLocationInfo();
        }
    }, [])

    const updateBCCenter = async () => {
        try {
            setIsLoading(true)
            let result = await getSingleLocation(user.storeId);
            if (result && result.success) {
                setPreviousData({ ...result.locations[0] });
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }

    const setSingleLocationInfo = () => {
        setIsLoading(true)
        let pathArray = location.pathname.split("/");
        let selectedId = pathArray[pathArray.length - 1];
        let list = store?.currentLocations;
        let singleLocSelected = list.find(loc => loc.locationId === selectedId);
        let singleLocIndex = list.findIndex(loc => loc.locationId === selectedId);
        setSingleLocation(singleLocSelected);
        setSingleLocationIndex(singleLocIndex);
        setIsLoading(false)
    }

    return (
        <div className="container-fluid" id="distributionCenter">
            <div className="row">
                <div className="col-3 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        <OnboardingStepper step={1} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-9">
                    {!isLoading ?
                        <CreateNewCenter previousData={ecommerce === "bigcommerce" ? previousData : singleLocation} singleLocationIndex={singleLocationIndex} allLocations={allLocations} />
                        :
                        <>
                            <Skeleton count={1} height={400} style={{ marginTop: "40px" }} />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}