import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LocationItem, LocationItemError } from './LocationItem';
import { InputsCheckbox, InputsSwitch } from '../../../components/units/Inputs';
import { useAuthStore, useonboardingStore } from '../../../store';
import { checkConfiguration, getLocations, saveLocationsData } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { ButtonSmall, ButtonUpdate } from '../../../components/units/Button';
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { changeShopifyName } from '../../../services/shared/utils.service';
import { customerRouteName } from '../../../data/InfoSource';
import { childVariants, fadeInAndUp, fadeInAndUpNoDelay, growBtnSmallDelay } from '../../../services/shared/animation.service';
import { BiPlusCircle } from "react-icons/bi";

export const LocationsConfig = () => {
    const addData = useonboardingStore((state) => state.addData);
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const user = useAuthStore((state) => state.authData.user);
    const addAuth = useAuthStore((state) => state.addAuthData);
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [currentLocations, setCurrentLocations] = useState(store?.currentLocations || []);
    const [activationSwitch, setActivationSwitch] = useState(store?.activeAllSwitch);
    const [allActiveLocationsConfigurated, setAllActiveLocationsConfigurated] = useState(false);
    const [btnMessage, setbtnMessage] = useState("Debes activar al menos una para continuar");
    const [showEmptyMsg, setshowEmptyMsg] = useState(true);
    const nextRouteParam = user?.insidePanel ? "panel/location-list" : "delivery-services";

    useEffect(() => {
        if (currentLocations.length === 0) {
            updateLocations();
        } else {
            setshowEmptyMsg(false);
            setIsLoading(false);
            checkConfiguration(currentLocations, setAllActiveLocationsConfigurated, setbtnMessage);
            return
        }
    }, []);

    const updateLocations = async () => {
        try {
            setIsLoading(true)
            let records = {};
            records = await getLocations(user.storeId);
            if (records && records.success) {
                setshowEmptyMsg(false)
                checkConfiguration(records.locations, setAllActiveLocationsConfigurated, setbtnMessage);
                setCurrentLocations([...records.locations]);
                let inCoverageLocations = records.locations.filter( (loc) => loc.inCoverage);
                let isAllActive = inCoverageLocations.every( (loc) => loc.availableForDelivery)
                setActivationSwitch(isAllActive);
                addData({
                    store: {
                        activeAllSwitch: isAllActive,
                        currentLocations: [...records.locations],
                    },
                });
            } else {
                setshowEmptyMsg(true)
                setCurrentLocations([])
            }
            if (records.error) {
                showError(records.error);
            }
            setIsLoading(false)
        } catch (error) {
            setCurrentLocations([])
            showError(error)
            setIsLoading(false)
        }
    }

    const handleChange = (e, id, index) => {
        let stateCopy = currentLocations;
        let selectedObj = stateCopy.slice(index, index + 1);
        selectedObj[0].availableForDelivery = e.target.checked;
        stateCopy[index] = selectedObj[0];
        setCurrentLocations([...stateCopy]);
        checkConfiguration(currentLocations, setAllActiveLocationsConfigurated, setbtnMessage);
    }

    const handleActiveAll = (e) => {
        if (e.target.checked) {
            let stateCopy = currentLocations;
            stateCopy.forEach(loc => {
                if (loc.inCoverage) {
                    loc.availableForDelivery = true
                }
            })
            setCurrentLocations([...stateCopy]);
        } else {
            let stateCopy = currentLocations;
            stateCopy.forEach(loc => {
                if (loc.inCoverage) {
                    loc.availableForDelivery = false
                }
            })
            setCurrentLocations([...stateCopy]);
        }
        checkConfiguration(currentLocations, setAllActiveLocationsConfigurated, setbtnMessage);
        setActivationSwitch(e.target.checked);
        addData({
            store: {
                ...store,
                activeAllSwitch: e.target.checked,
            },
        });
    }

    const saveLocationsConfig = async () => {
        try {
            setIsLoading(true);
            const result = await saveLocationsData({ locations: currentLocations }, user.storeId);
            if (result && result.success) {
                showSuccess(result.message);
                addAuth({
                    user: {
                      ...user,
                      idCode: result?.shop_id || user?.idCode,
                    },
                  });
                history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextRouteParam}`)
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showError(error);
        }
    }

    return (
        <div id="locationsConfig" className="content-wrapper">
            <motion.div variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                <h1 className="main-title">Tiendas</h1>
                <h2 className="main-subtitle">Configura y activa las tiendas desde donde realizarás tus envíos.</h2>
                <div className="ps-2 mt-4 mb-3 d-flex justify-content-between align-items-center">
                    <InputsCheckbox
                        id="all"
                        value={activationSwitch}
                        checked={activationSwitch}
                        onChangeFn={handleActiveAll}
                        labelTxt={activationSwitch ? "Desactivar todas" : "Activar todas"}
                    />
                    <div className="d-flex justify-content-end align-items-center">
                        {ecommerce === "vtex"
                            &&
                            <ButtonSmall
                                type="button"
                                btnTxt="Crear muelle"
                                icon={<BiPlusCircle />}
                                showSpinner={false}
                                isDisabled={false}
                                onClickFn={() => history.push(`/${customerRouteName}/vtex${user.insidePanel ? '/panel' : ""}/create-center`)}
                                extraClass="btn-outline-primary"
                            />
                        }
                        {(user?.insidePanel || ecommerce === "vtex") &&
                            <ButtonUpdate isLoading={isLoading} updateFunction={updateLocations} />
                        }
                    </div>
                </div>
                {isLoading ?
                    <>
                        <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                        <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                        <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                        <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                    </>
                    :
                    <div className="content-locations-list">
                        {showEmptyMsg && <div className="empty-msg"><p>Aún no hay tiendas disponibles</p></div>}
                        {currentLocations.map((location, index) => (
                            <motion.div variants={childVariants} key={index}>
                                {location.inCoverage ?
                                    <LocationItem
                                        ecommerce={ecommerce}
                                        index={index + 1}
                                        id={location.locationId}
                                        checked={location.availableForDelivery}
                                        handleChange={(e) => handleChange(e, location.locationId, index)}
                                        value={location.availableForDelivery}
                                        insidePanel={user?.insidePanel}
                                        {...location}
                                    />
                                    :
                                    <LocationItemError
                                        ecommerce={ecommerce}
                                        index={index + 1}
                                        id={location.locationId}
                                        insidePanel={user?.insidePanel}
                                        {...location}
                                    />
                                }
                            </motion.div>
                        ))}
                    </div>
                }
            </motion.div>
            <motion.div className="bottomPage" variants={growBtnSmallDelay} initial="hidden" animate="visible">
                <ButtonSmall
                    type="button"
                    onClickFn={saveLocationsConfig}
                    btnTxt={allActiveLocationsConfigurated ? "Guardar" : btnMessage}
                    showSpinner={false}
                    isDisabled={allActiveLocationsConfigurated ? false : true}
                />
            </motion.div>
        </div>
    )
}