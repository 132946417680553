import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { createLocation, saveLocationsData } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { changeShopifyName, validateInputs } from '../../../services/shared/utils.service';
import { useAuthStore, useonboardingStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { motion } from 'framer-motion';
import { fadeInAndUp, fadeInAndUpFastStagger, fadeInAndUpNoDelay, growBtn } from '../../../services/shared/animation.service';
import { customerRouteName } from '../../../data/InfoSource';
import { useEffect } from 'react';
import { InputsGroupForCreation } from './InputsGroupForCreation';

export const CreateNewCenter = ({ previousData = {}, singleLocationIndex = 0, allLocations = [] }) => {
  const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
  let history = useHistory();
  const user = useAuthStore((state) => state.authData.user);
  const addAuth = useAuthStore((state) => state.addAuthData);
  const addData = useonboardingStore((state) => state.addData);
  const store = useonboardingStore((state) => state.onboardingData.store);
  const generalSwitch = useonboardingStore((state) => state.onboardingData.store?.activeAllSwitch);
  const idCode = useAuthStore((state) => state.authData.user.idCode);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({})
  const [fullList, setFullList] = useState((ecommerce === "vtex" && user?.insidePanel) ? store?.currentLocations : allLocations);

  const setNextRoute = (flowDirection) => {
    let paramOne = "";
    let paramTwo = "";
    if (user?.insidePanel) {
      paramOne = "panel/"
    }
    if (flowDirection === "next") {
      if (ecommerce === "bigcommerce") {
        paramTwo = "delivery-services";
      } else {
        paramTwo = "location-list";
      }
    } else {
      if (ecommerce === "bigcommerce") {
        paramTwo = "location-list";
      } else {
        paramTwo = "location-list";
      }
    }
    return `${paramOne}${paramTwo}`
  }

  const nextRouteParam = setNextRoute("next");
  const prevRouteParam = setNextRoute("previous");

  const setCountryCodeInitialState = () => {
    if (user?.countryCode) {
      return user?.countryCode
    } else if (previousData.countryCode) {
      return previousData.countryCode
    } else {
      return ""
    }
  }

  const [body, setBody] = useState({
    name: previousData.name || "",
    manager: previousData.manager || "",
    phoneNumber: previousData.phoneNumber || "",
    email: previousData.email || "",
    street: previousData.street || "",
    externalNumber: previousData.externalNumber || "",
    internalNumber: previousData.internalNumber || "",
    zip: previousData.zip || "",
    city: previousData.city || "",
    province: previousData.province || "",
    countryCode: setCountryCodeInitialState(),
    country: previousData.country || "",
    notes: previousData.notes || "",
  });

  useEffect(() => {
    checkFormFill();
  }, [body])

  useEffect(() => {
    updateCountryName(body?.countryCode);
  }, [body.countryCode])

  const checkFormFill = () => {
    let standardCondition = body.name && body.manager && body.phoneNumber && body.email && body.street && body.externalNumber && body.zip && body.city && body.province && body.countryCode;
    let conditionBigcommerce = body.manager && body.phoneNumber && body.email && body.street && body.externalNumber && body.zip && body.city && body.province && body.countryCode;
    if ((ecommerce === "vtex" && standardCondition) || (ecommerce === "shopify" && standardCondition) || (ecommerce === "bigcommerce" && conditionBigcommerce)) {
      setIsButtonEnabled(true)
    } else {
      setIsButtonEnabled(false)
    }
  };

  const updateCountryName = (countryCode) => {
    let countries = {
      MX: "México",
      CO: "Colombia"
    }
    setBody({ ...body, country: countries[countryCode] })
  }

  const handleChange = (e) => {
    setBody({ ...body, [e.target.id]: e.target.value });
  };

  const handleVtexCreation = async (e) => {
    e.preventDefault();
    if (validateInputs(body, setFormErrors)) {
      setIsLoading(true);
      const obj = { location: { ...body } };
      try {
        let result = await createLocation(obj, user?.storeId);
        if (result && result.success) {
          addData({
            store: {
              ...store,
              activeAllSwitch: generalSwitch
            },
          });
          let updatedLocations = [...fullList, result.location];
          try {
            const outcome = await saveLocationsData({ locations: updatedLocations }, user?.storeId);
            if (outcome && outcome.success) {
              showSuccess(result.message);
              history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextRouteParam}`)
            }
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
            showError(error);
          }
        }
      } catch (error) {
        setIsLoading(false);
        showError(error);
      }
    } else {
      return
    }
  }

  const handleCreationNewCenter = async (e) => {
    e.preventDefault();
    if (validateInputs(body, setFormErrors)) {
      setIsLoading(true);
      let obj = {};
      if (ecommerce === "shopify") {
        let composedAddress = `${body.externalNumber} ${body.street}`
        allLocations[singleLocationIndex] = { ...body, address: composedAddress, availableForDelivery: previousData.availableForDelivery, locationId: previousData.locationId, inCoverage: previousData.inCoverage };
        obj = { locations: allLocations };
      } else {
        obj = { location: { ...body } };
      }
      try {
        let result;
        if (ecommerce === "shopify") {
          result = await saveLocationsData(obj, user?.storeId);
        } else {
          result = await createLocation(obj, user?.storeId);
        }
        if (result && result.success) {
          addData({
            store: {
              ...store,
              activeAllSwitch: generalSwitch
            },
          });
          addAuth({
            user: {
              ...user,
              idCode: result?.shop_id || idCode,
            },
          });
          showSuccess(result.message);
          history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextRouteParam}`)
        }
        if (result.error) {
          showError(result.error);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showError(error);
      }
    } else {
      return
    }
  };

  return (
    <div id="createNewCenter" className='content-wrapper'>
      <motion.div variants={fadeInAndUp} initial="hidden" animate="visible">
        <h1 className="main-title mb-2">{ecommerce === "vtex" ? "Crear muelle" : "Configura tu tienda"}</h1>
        {ecommerce === "bigcommerce" &&
          <h2 className="main-subtitle mb-4 mb-md-5">Configura la dirección desde donde despacharemos tus paquetes.</h2>
        }
      </motion.div>
      {!isLoading &&
        <form onSubmit={ecommerce === "vtex" ? handleVtexCreation : handleCreationNewCenter}>
          <motion.div className="form-grid-two-columns" variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUpFastStagger} initial="hidden" animate="visible">
            <InputsGroupForCreation
              ecommerce={ecommerce}
              formErrors={formErrors}
              handleChange={handleChange}
              {...body}
            />
          </motion.div>

          <div className={`bottomPage ${((!user?.insidePanel && ecommerce !== "bigcommerce") || (user?.insidePanel && ecommerce !== "bigcommerce")) ? "doubleBtn" : ""}`}>
            {((!user?.insidePanel && ecommerce !== "bigcommerce") || (user?.insidePanel && ecommerce !== "bigcommerce")) &&
              <motion.div variants={growBtn} initial="hidden" animate="visible">
                <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}/${prevRouteParam}`} className="btn-link">Regresar</Link>
              </motion.div>
            }

            <motion.div variants={growBtn} initial="hidden" animate="visible">
              <ButtonSmall
                type="submit"
                btnTxt={(ecommerce === "bigcommerce" && !user?.insidePanel) ? "Continuar" : "Guardar"}
                showSpinner={isLoading}
                isDisabled={isButtonEnabled ? false : true}
              />
            </motion.div>
          </div>
        </form>
      }
    </div>
  );
};