import React from 'react'
import { useAuthStore } from '../../store';
import { motion } from 'framer-motion';
import { fadeInAndRight } from '../../services/shared/animation.service';
import { OnboardingStepper } from '../../components/onboarding/OnboardingStepper';
import { DeliverySelectionForm } from '../../components/onboarding/DeliverySelectionForm';
import { EmbeddedDeliveryConfig } from '../../components/onboarding/EmbeddedDeliveryConfig';

export const DeliveryServices = () => {
  const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
  const tokenForEmbedded = useAuthStore((state) => state.authData.user.tokenForEmbedded);
  const idCode = useAuthStore((state) => state.authData.user.idCode);

  return (
    <div className="container-fluid" id="deliveryServices">
      <div className="row">
        <div className="col-3 p-0">
          <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
            <OnboardingStepper step={2} ecommerce={ecommerce} />
          </motion.div>
        </div>
        <div className="col-9">
          {ecommerce === "vtex"
            ?
            <DeliverySelectionForm ecommerce={ecommerce} />
            :
            <EmbeddedDeliveryConfig ecommerce={ecommerce} token={tokenForEmbedded} idCode={idCode} />
          }
        </div>
      </div>
    </div>
  )
}
