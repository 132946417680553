import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthStore } from '../../store';
import { ButtonSmall } from '../../components/units/Button';
import { motion } from 'framer-motion';
import { customerRouteName } from '../../data/InfoSource';
import { fadeInAndRight, fadeInAndUp, growBtn } from '../../services/shared/animation.service';
import finishErrorIllustration from '../../assets/finishErrorIllustration.png'
import { OnboardingStepper } from '../../components/onboarding/OnboardingStepper';
import { changeShopifyName } from '../../services/shared/utils.service';

export const FinishError = () => {
    let history = useHistory();
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);

    return (
        <div className="container-fluid" id="finish">
            <div className="row">
                <div className="col-3 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        <OnboardingStepper step={3} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-9">
                    <div className="content-wrapper finish-wrapper">
                        <motion.div variants={fadeInAndUp} initial="hidden" animate="visible">
                            <div className='text-wrapper'>
                                <h1 className="main-title">No pudimos guardar tus datos</h1>
                                <h2 className="main-subtitle">Por favor, vuelve a intentarlo más tarde.</h2>
                            </div>
                            <img className='finish-illustration-error mb-5' src={finishErrorIllustration} alt="error" />

                            <div className='d-flex justify-content-center mt-5'>
                                <ButtonSmall
                                    type="button"
                                    btnTxt="Reintentar"
                                    onClickFn={() => history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/delivery-services`)}
                                    showSpinner={false}
                                    isDisabled={false}
                                />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    )
}
