import React from 'react';
import { colorSetter } from '../../../services/grid.service';

export const DefaultItemRenderer = ({
    checked,
    option,
    onClick,
    disabled
  }) => (
    <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
        style={{ marginRight: "15px" }}
      />
      <div className='d-flex justify-content-start align-items-center w-100'>
        {option.label !== "Todos" ? <img src={colorSetter(option.label)} alt={`${option.label}`} style={{ margin: "0", padding: "0" }} /> : ""}
        <span style={{ marginLeft: "8px" }}>{option.label}</span>
      </div>
    </div>
  );
