import React from 'react';
import Modal from "react-bootstrap/Modal";
import cancelOrderIllustration from '../../../assets/cancelOrderIllustration.svg'
import { cancelOrder } from '../../../services/panel.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { useAuthStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';

export const CancelConfirmationModal = ({ show, orderData, handleClose, updateTable }) => {
    const user = useAuthStore((state) => state.authData.user);

    const cancelCreateOrder = async (e) => {
        e.preventDefault();
        try {
            const result = await cancelOrder({ orderIds: [orderData.orderId] }, user?.storeId);
            if (result && result.success) {
                showSuccess(result?.message);
                setTimeout(() => {
                    updateTable(user?.storeId)
                }, 500);
            }
            if (result.error) {
                showError(result.error);
            }
            handleClose();
        } catch (error) {
            showError(error);
            handleClose();
        }
    }

    return (
        <Modal
            className="rounded-3"
            id="cancellation-modal"
            show={show}
            backdrop={true}
            onHide={handleClose}
            centered
            size="md"
            scrollable
        >
            <Modal.Header closeButton className="modal-header border-0">
            </Modal.Header>
            <Modal.Body className="modal-body">
                <img src={cancelOrderIllustration} alt="cancel confirmation" />
                <p>Espera, ¿deseas cancelar tu envío?</p>
            </Modal.Body>
            <Modal.Footer className="modal-footer d-flex justify-content-center border-0 p-0">
                <ButtonSmall
                    type="button"
                    btnTxt="Cerrar"
                    showSpinner={false}
                    isDisabled={false}
                    onClickFn={() => handleClose()}
                    extraClass="btn btn-outline-primary"
                />
                <ButtonSmall
                    type="button"
                    btnTxt="Sí, cancelar"
                    showSpinner={false}
                    isDisabled={false}
                    onClickFn={cancelCreateOrder}
                    extraClass="btn btn-primary"
                />
            </Modal.Footer>
        </Modal>
    )
}
