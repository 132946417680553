import React, { useState } from 'react'
import { changeShopifyName, validateInputs } from '../../../services/shared/utils.service';
import { useHistory } from 'react-router';
import { loginService } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { useAuthStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { InputsSelect, InputsSimple } from '../../units/Inputs';
import { motion } from 'framer-motion';
import { urlInfo, customerRouteName } from '../../../data/InfoSource';
import { childVariants, growBigBtn } from '../../../services/shared/animation.service';
import { FiEye, FiEyeOff, FiArrowRight } from "react-icons/fi";

export const LoginForm = ({ ecommerce }) => {
    const addAuth = useAuthStore((state) => state.addAuthData);
    const user = useAuthStore((state) => state.authData.user);
    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({})
    const [body, setBody] = useState({
        customerApiKey: "",
        password: "",
        countryCode: ""
    });
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: (e.target.value).trim() })
    }

    const signIn = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            try {
                const result = await loginService(body, user.storeId, user?.userId);
                if (result && result.success) {
                    addAuth({
                        user: {
                            ...user,
                            customerApiKey: body.customerApiKey,
                            countryCode: body.countryCode,
                            token: result.token,
                            tokenForEmbedded: body.customerApiKey || user?.tokenForEmbedded
                        }
                    });
                    showSuccess(result.message);
                    history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/location-list`)
                }
                if (result.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                showError(error);
            }
        } else {
            return
        }
    }

    return (
        <form onSubmit={signIn} className="special-input pt-2">
            <motion.div variants={childVariants}>
                <InputsSimple
                    value={body.customerApiKey}
                    id="customerApiKey"
                    type="text"
                    placeholder=""
                    labelTxt="API Key Skydropx*"
                    onChangeFn={handleChange}
                    resultValidation={formErrors?.customerApiKey ? "is-invalid" : ""}
                    errorTxt={false}
                />
                <p className="small-subtitle">
                    Tu API Key está en la sección de <a href={`${urlInfo.integrationsApiUrl}`} target="_blank" rel="noreferrer" className="btn-link initial-link">integraciones en Skydropx</a>.
                </p>
            </motion.div>

            {ecommerce === "vtex" &&
                <>
                    <motion.div variants={childVariants} className="parent-box-input">
                        <InputsSimple
                            value={body.password}
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder={showPassword ? body.password : '******'}
                            labelTxt="Contraseña*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.password ? "is-invalid" : ""}
                            errorTxt={false}
                        />
                        <div className="child-icon">
                            {showPassword ? <FiEyeOff onClick={() => setShowPassword(!showPassword)} /> : <FiEye onClick={() => setShowPassword(!showPassword)} />}
                        </div>
                    </motion.div>

                    <motion.div variants={childVariants}>
                        <InputsSelect
                            value={body.countryCode}
                            id="countryCode"
                            labelTxt="País*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.countryCode ? "is-invalid" : ""}
                            arrayList={[{ value: "MX", name: "México" }, { value: "CO", name: "Colombia" }]}
                            previouslySelected={false}
                        />
                    </motion.div>
                </>
            }

            <motion.div variants={growBigBtn} className="login-btn">
                <ButtonSmall
                    type="submit"
                    btnTxt="Comenzar configuración"
                    showSpinner={isLoading}
                    isDisabled={((ecommerce !== "vtex" && body.customerApiKey) || (ecommerce == "vtex" && body.customerApiKey && body.password && body.countryCode)) ? false : true}
                    icon={<FiArrowRight />}
                    extraClass="special-login"
                />
            </motion.div>
        </form>
    )
}
