import React, { useEffect, useState } from 'react';
import { useAuthStore } from '../../../store';
import { showError } from '../../../services/shared/alert.service';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ButtonSmall } from '../../units/Button';
import { FulfillClientInputs } from './FulfillClientInputs';
import alertRed from '../../../assets/icons/alertRed.svg';
import { createOrder, saveUserData } from '../../../services/panel.service';
import { AbstractSection } from './AbstractSection';
import Skeleton from 'react-loading-skeleton';
import { validateFullInfoComplete } from '../../../services/shared/utils.service';
import { packageSizeFormatter } from '../../../services/grid.service';

export const FulfillOrderModal = ({ show, handleClose, orderData, isPackageSet, isDeliveryServiceSet, updateTable, isWaiting }) => {
    const user = useAuthStore((state) => state.authData.user);
    const [isLoading, setIsLoading] = useState(false);
    const [isClientInfoLoading, setIsClientInfoLoading] = useState(false);
    const [isEditionAvailable, setIsEditionAvailable] = useState(false);
    const [clientInfo, setClientInfo] = useState({ ...orderData.userData });
    const [isFormComplete, setIsFormComplete] = useState(false);

    const prepareAndCreateOrder = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        isWaiting(true);
        handleClose();
        try {
            const result = await createOrder({ orderIds: [orderData.orderId] }, user?.storeId);
            if (result && result.success) {
                setTimeout(() => {
                    isWaiting(false);
                    updateTable(user?.storeId);
                }, 21000);
            }
            if (result.error) {
                showError(result.error);
                isWaiting(false);
            }
            setIsLoading(false);
        } catch (error) {
            showError(error);
            isWaiting(false);
            setIsLoading(false);
        }
    }

    const saveClientEditedData = async () => {
        setIsEditionAvailable(!isEditionAvailable);
        if (isEditionAvailable===true) {
            setIsClientInfoLoading(true);
            try {
                const result = await saveUserData({ orderId: orderData.orderId, userData: { ...clientInfo } }, user?.storeId);
                if (result && result.success) {
                    setClientInfo({ ...result.userData })
                    setIsEditionAvailable(!isEditionAvailable)
                }
                if (result.error) {
                    showError(result.error);
                }
                setIsClientInfoLoading(false);
            } catch (error) {
                showError(error);
                setIsClientInfoLoading(false);
            }
        }
    }

    useEffect(() => {
        checkIsClientInfoCompleteWithNoErrors();
    }, [clientInfo])

    const checkIsClientInfoCompleteWithNoErrors = () => {
        if (clientInfo.error.length > 0) {
            setIsFormComplete(false);
        } else {
            validateFullInfoComplete([clientInfo.name, clientInfo.email, clientInfo.phoneNumber, clientInfo.country, clientInfo.city, clientInfo.province, clientInfo.address, clientInfo.zip], setIsFormComplete);
        }
    }

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" id="fulfill-modal">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Cerrar</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <form onSubmit={prepareAndCreateOrder} className="p-0 m-0">
                    {orderData.userData.error.length > 0 &&
                        <div className='alert-toast'>
                            <img src={alertRed} alt="alert" />
                            <p>Corrige los datos para crear tu envío.</p>
                        </div>
                    }

                    <AbstractSection orderData={orderData} />

                    <div className='fulfill-body-module'>
                        <div className='with-btn'>
                            <h2 className='fulfill-body-mainTitle'>Destino</h2>
                            <ButtonSmall
                                type="button"
                                btnTxt={isEditionAvailable ? "Guardar" : "Editar"}
                                onClickFn={saveClientEditedData}
                                showSpinner={false}
                                isDisabled={false}
                                extraClass="btn-link p-0"
                            />
                        </div>
                        <div>
                            {isClientInfoLoading ?
                                <>
                                    <Skeleton count={1} height={250} />
                                </>
                                :
                                <FulfillClientInputs inputsInfo={clientInfo} setInputsInfo={setClientInfo} isEditionAvailable={isEditionAvailable} />
                            }
                        </div>

                    </div>
                    <div className='fulfill-body-module'>
                        <h2 className='fulfill-body-mainTitle'>Envío</h2>
                        <div className='fulfill-product-delivery'>
                            <span>Paquetería:</span>
                            <span className='secondary'>{orderData.deliveryService.provider}</span>
                        </div>
                        <div className='fulfill-product-delivery'>
                            <span>Servicio:</span>
                            <span className='secondary'>{orderData.deliveryService.serviceLevelName}</span>
                        </div>
                        <div className='fulfill-product-delivery'>
                            <span>Entrega estimada:</span>
                            {orderData.deliveryService.estimatedTime !== 0 ?
                                <span>{orderData.deliveryService.estimatedTime === 1 ? `${orderData.deliveryService.estimatedTime} día hábil` : `${orderData.deliveryService.estimatedTime} días hábiles`}</span>
                                :
                                ""
                            }
                        </div>
                    </div>

                    <div className='fulfill-body-module'>
                        <h2 className='fulfill-body-mainTitle'>Paquete</h2>
                        <div className='fulfill-product-delivery'>
                            <span>Clase:</span>
                            <span className='secondary'>{orderData?.packageInfo?.waybillClass?.name}</span>
                        </div>
                        <div className='fulfill-product-delivery'>
                            <span>Peso:</span>
                            <span className='secondary'>{orderData?.packageInfo?.weight ? `${orderData.packageInfo.weight} kg` : "-"}</span>
                        </div>
                        <div className='fulfill-product-delivery'>
                            <span>Medidas:</span>
                            <span className='secondary'>{(orderData?.packageInfo?.width && orderData?.packageInfo?.height) && orderData?.packageInfo?.length ? packageSizeFormatter(orderData?.packageInfo, " cm") : "-"}</span>
                        </div>
                        <div className='fulfill-product-delivery'>
                            <span>Cantidad:</span>
                            <span className='secondary'>{orderData?.packageInfo?.quantity}</span>
                        </div>
                    </div>

                    <div className='fulfill-body-module submit-btn'>
                        <ButtonSmall
                            type="submit"
                            btnTxt="Crear envío"
                            showSpinner={isLoading}
                            isDisabled={isPackageSet && isDeliveryServiceSet && isFormComplete ? false : true}
                            extraClass="block"
                        />
                    </div>
                </form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}
