import React, { useEffect, useRef, useState } from 'react';
import { ButtonSmall } from '../../components/units/Button';
import { motion } from 'framer-motion';
import { fadeInAndUp, growBtn } from '../../services/shared/animation.service';
import { useAuthStore } from '../../store';
import { getWaybillPackages, saveWaybillConfiguration } from '../../services/panel.service';
import { showError, showSuccess } from '../../services/shared/alert.service';
import { WaybillSelects } from './WaybillSelects';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const WaybillForm = ({ btnTxt }) => {
    const user = useAuthStore((state) => state.authData.user);
    const searchPackageInput = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [selectPackagesOptions, setSelectPackagesOptions] = useState([]);
    const [body, setBody] = useState({
        packageType: "",
        waybillClass: {
            id: "",
            name: ""
        },
    });

    useEffect(() => {
        updateWaybillPackages();
    }, [])

    const updateWaybillPackages = async () => {
        setIsLoading(true)
        try {
            let result = await getWaybillPackages(user.storeId);
            if (result && result.success) {
                let mappedPackages = result.packageType.map((packageItem) => { return { name: packageItem.name, value: packageItem.id } })
                setSelectPackagesOptions(mappedPackages);
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }

    const handlePackageChange = (e) => {
        setBody({ ...body, packageType: e });
    }

    const setWaybill = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            let result;
            result = await saveWaybillConfiguration({ waybillConfiguration: { ...body } }, user.storeId);
            if (result && result.success) {
                showSuccess(result.message);
            }
            setIsLoading(false);
        } catch (error) {
            showError(error);
            setIsLoading(false);
        }
    }

    return (
        <>
            {
                isLoading ?
                    <>
                        < Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                        < Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                        < Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                        < Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                    </>
                    :
                    <form onSubmit={setWaybill}>
                        <motion.div className="form-waybill-wrapper" variants={fadeInAndUp} initial="hidden" animate="visible">
                            <WaybillSelects
                                refPackage={searchPackageInput}
                                optionsPackage={selectPackagesOptions}
                                valuePackage={body.packageType}
                                handlePackageChange={handlePackageChange}
                                valueClass={body.waybillClass.name}
                                body={body}
                                setBody={setBody}
                            />
                        </motion.div>
                        <div className="bottomPage">
                            <motion.div variants={growBtn} initial="hidden" animate="visible">
                                <ButtonSmall
                                    type="submit"
                                    btnTxt={btnTxt ? btnTxt : "Guardar"}
                                    showSpinner={isLoading}
                                    isDisabled={(body.waybillClass.id && body.waybillClass.name && body.packageType) ? false : true}
                                />
                            </motion.div>
                        </div>
                    </form>
            }
        </>
    )
}
