import React from 'react';
import Modal from "react-bootstrap/Modal";
import { ButtonSmall } from '../units/Button';

export const LogOutModal = ({ show, handleClose, handleLogOut }) => {
    return (
        <Modal
            className="rounded-3"
            id="logout-modal"
            show={show}
            backdrop={true}
            onHide={handleClose}
            centered
            size="md"
            scrollable
        >
            <Modal.Header closeButton className="modal-header border-0">
            </Modal.Header>
            <Modal.Body className="modal-body">
                <p>Espera, ¿estás seguro que quieres cerrar tu sesión?</p>
            </Modal.Body>
            <Modal.Footer className="modal-footer d-flex justify-content-center border-0 p-0">
                <ButtonSmall
                    type="button"
                    btnTxt="Sí, cerrar sesión"
                    showSpinner={false}
                    isDisabled={false}
                    onClickFn={handleLogOut}
                    extraClass="btn btn-primary"
                />
            </Modal.Footer>
        </Modal>
    )
}
