import React from 'react';
import { LogoContainerShopify, LogoContainerBigcommerce, LogoContainerVtex } from '../units/LogoContainer';
import { capitalizeFirstLetter } from '../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { customerName } from '../../data/InfoSource';
import { childVariants, fadeInAndRight } from '../../services/shared/animation.service';
import { Background } from './Background';

export const IntroColumn = ({ ecommerce, storeName = "", isLoading }) => {
  return (
    <motion.div className={`col col-3 welcome-grid ${ecommerce}`} variants={fadeInAndRight} initial="hidden" animate="visible">
      {ecommerce === "shopify" && <LogoContainerShopify />}
      {ecommerce === "bigcommerce" && <LogoContainerBigcommerce ecommerce={ecommerce} />}
      {ecommerce === "vtex" && <LogoContainerVtex ecommerce={ecommerce} />}
      <div className="welcome-text">
        <div>
          <motion.h1 variants={childVariants} className="intro-title">
            {!isLoading && !storeName ? `¡Hola!` : `¡Hola ${storeName}!`}
          </motion.h1>
          <motion.p variants={childVariants} className="intro-subtitle">
            Gracias por usar la aplicación de {customerName} para {capitalizeFirstLetter(ecommerce)}.
          </motion.p>
        </div>
      </div>
      <Background/>
    </motion.div>
  )
}
