import React from 'react';
import { useAuthStore, useonboardingStore } from '../../store';
import { ButtonSmall } from '../../components/units/Button';
import { motion } from 'framer-motion';
import { customerRouteName } from '../../data/InfoSource';
import { childVariants, fadeInAndRight, fadeInAndUp, growBtn } from '../../services/shared/animation.service';
import finishIllustration from '../../assets/finishIllustration.png'
import { OnboardingStepper } from '../../components/onboarding/OnboardingStepper';

export const FinishSuccess = () => {
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const removeOnboardingData = useonboardingStore((state) => state.removeOnboardingData);

    let ecommerceUrls = {
        shopify: {
            goToPanelLink: `/${customerRouteName}/secommerce/panel/order`,
        },
        bigcommerce: {
            goToPanelLink: `/${customerRouteName}/bigcommerce/panel/order`,
        },
        vtex: {
            goToPanelLink: `/${customerRouteName}/vtex/sign-in`,
        },
    }

    const redirect = (url) => {
        window.location.href = `${url}`;
    }

    return (
        <div className="container-fluid" id="finish">
            <div className="row">
                <div className="col-3 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        <OnboardingStepper step={3} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-9">
                    <div className="content-wrapper finish-wrapper">
                        <motion.div variants={fadeInAndUp} initial="hidden" animate="visible">
                            <div className='text-wrapper'>
                                <h1 className="main-title">¡Listo! La conexión se configuró exitosamente</h1>
                            </div>
                            <motion.div className='notification' variants={childVariants}>
                                <div className="notification-text-wrapper">
                                    {ecommerce === "shopify" ?
                                        <p className='main-subtitle'>Para cotizar tus envíos correctamente, completa las dimensiones de tus productos.</p>
                                        :
                                        <p className='main-subtitle'>Recuerda que puedes hacer cambios cuando lo desees.</p>
                                    }
                                </div>
                            </motion.div>
                            <img className='finish-illustration' src={finishIllustration} alt="finish" />

                            <div className='d-flex justify-content-center mt-5'>
                                <ButtonSmall
                                    type="button"
                                    btnTxt="Finalizar"
                                    onClickFn={() => { redirect(ecommerceUrls[ecommerce].goToPanelLink); removeOnboardingData() }}
                                    showSpinner={false}
                                    isDisabled={false}
                                />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    )
}
