import React, { useEffect, useState } from 'react';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { useAuthStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { InputsSelect, InputsSimple } from '../../units/Inputs';
import close from '../../../assets/icons/close.svg';
import { getWaybillPackages, saveSingleOrderWaybillConfig } from '../../../services/panel.service';
import { ClassesInfiniteScroll } from '../ClassesInfiniteScroll';

export const PackageSetterModal = ({ orderData, handleClose, updateTable, rowIndex, pageSize }) => {
    const user = useAuthStore((state) => state.authData.user);
    const [isAllComplete, setIsAllComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [body, setBody] = useState({
        packageType: orderData.packageInfo.packageType.id || "",
        waybillClass: {
            id: orderData.packageInfo.waybillClass.id || "",
            name: orderData.packageInfo.waybillClass.name || ""
        },
        width: orderData.packageInfo.width || "",
        height: orderData.packageInfo.height || "",
        length: orderData.packageInfo.length || "",
        weight: orderData.packageInfo.weight || ""
    });
    const [selectPackagesOptions, setSelectPackagesOptions] = useState([]);

    useEffect(() => {
        updateWaybillConfig();
    }, [])

    useEffect(() => {
        checkForEnablingButton();
    }, [body])


    const updateWaybillConfig = async () => {
        setIsLoading(true)
        try {
            let result = await getWaybillPackages(user.storeId);
            if (result && result.success) {
                let mappedPackages = result.packageType.map((packageItem) => { return { name: packageItem.name, value: packageItem.id } })
                setSelectPackagesOptions(mappedPackages);
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }

    const handleChange = (e) => {
        if (e.target.id === "packageType") {
            setBody({ ...body, [e.target.id]: (e.target.value) })
        } else {
            setBody({ ...body, [e.target.id]: Number(e.target.value) })
        }
    }

    const sendPackageInfo = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const result = await saveSingleOrderWaybillConfig({ waybillConfiguration: { ...body }, orderId: orderData.orderId }, user?.storeId);
            if (result && result.success) {
                showSuccess(result?.message);
                setTimeout(() => {
                    updateTable(user?.storeId)
                }, 500);
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false);
            handleClose();
        } catch (error) {
            showError(error);
            setIsLoading(false);
            handleClose();
        }
    }

    const checkForEnablingButton = () => {
        if (body.packageType && body.waybillClass.name && body.waybillClass.id && body.width && body.height && body.length && body.weight && body.width > 0 && body.height > 0 && body.length > 0 && body.weight > 0) {
            setIsAllComplete(true);
        } else {
            setIsAllComplete(false);
        }
    }

    return (
        <div id='package-setter-modal' className={`${rowIndex + 1 === pageSize ? "last" : ""}`}>
            <div id="package-modal-header" className="border-0">
                <h2 className='package-setter-title'>Paquete</h2>
                <button
                    type='button'
                    onClick={() => handleClose()}
                >
                    <img src={close} alt="close" />
                </button>
            </div>
            <div className="modal-body">
                <p className='package-setter-message'>Completa los datos del paquete.</p>
                <InputsSelect
                    id="packageType"
                    value={body.packageType}
                    labelTxt="Empaque"
                    onChangeFn={handleChange}
                    arrayList={selectPackagesOptions}
                />
                <h3 className='package-setter-subtitle'>Dimensiones</h3>
                <div className='form-wrapper'>
                    <div className='input-wrapper-parent'>
                        <InputsSimple
                            value={String(body.length).replace(/^0+/, '')}
                            id="length"
                            type="number"
                            placeholder=""
                            labelTxt="Largo"
                            onChangeFn={handleChange}
                            errorTxt={false}
                            min={1}
                        />
                        <span className='input-wrapper-child'>cm</span>
                    </div>
                    <div className='input-wrapper-parent'>
                        <InputsSimple
                            value={String(body.height).replace(/^0+/, '')}
                            id="height"
                            type="number"
                            placeholder=""
                            labelTxt="Alto"
                            onChangeFn={handleChange}
                            errorTxt={false}
                            min={1}
                        />
                        <span className='input-wrapper-child'>cm</span>
                    </div>
                    <div className='input-wrapper-parent'>
                        <InputsSimple
                            value={String(body.width).replace(/^0+/, '')}
                            id="width"
                            type="number"
                            placeholder=""
                            labelTxt="Ancho"
                            onChangeFn={handleChange}
                            errorTxt={false}
                            min={1}
                        />
                        <span className='input-wrapper-child'>cm</span>
                    </div>
                    <div className='input-wrapper-parent'>
                        <InputsSimple
                            value={body.weight}
                            id="weight"
                            type="number"
                            placeholder=""
                            labelTxt="Peso"
                            onChangeFn={handleChange}
                            errorTxt={false}
                            min={0.01}
                        />
                        <span className='input-wrapper-child'>kg</span>
                    </div>
                </div>
                <h3 className='package-setter-subtitle'>Carta Porte</h3>
                <p className='package-setter-message'>{ body?.waybillClass?.name ? `Clase seleccionada: ${body?.waybillClass?.name}` : "Selecciona la clase de tu producto."}</p>
                <ClassesInfiniteScroll body={body} setBody={setBody} fullWidthStyle={false} />
            </div>
            <div className='modal-footer'>
                <ButtonSmall
                    type="button"
                    btnTxt="Aplicar"
                    onClickFn={sendPackageInfo}
                    showSpinner={isLoading}
                    isDisabled={isAllComplete ? false : true}
                    extraClass="btn-outline-primary fw-light block"
                />
            </div>
        </div>
    )
}
