import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { vtexIntegration } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { validateInputs } from '../../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { useAuthStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { InputsSimple } from '../../units/Inputs';
import { childVariants, growBigBtn } from '../../../services/shared/animation.service';
import { customerRouteName, customerName } from '../../../data/InfoSource';

export const VtexIntegrationForm = () => {
    const [isLoading, setIsLoading] = useState("");
    const [formErrors, setFormErrors] = useState({})
    const [body, setBody] = useState({
        customerApiKey: "",
        customerApiToken: "",
        storeUrl: ""
    });

    const addAuth = useAuthStore((state) => state.addAuthData);
    const user = useAuthStore((state) => state.authData.user);

    let history = useHistory();

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: (e.target.value).trim() })
    }

    const configVtexKeys = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            try{
                const result = await vtexIntegration(body.customerApiKey, body.customerApiToken, body.storeUrl);
                if(result && result.success) {
                    addAuth({
                        user: {
                            ...user,
                            apiKey: body.customerApiKey,
                            apiSecret: body.customerApiToken,
                            storeUrl: body.storeUrl,
                            userId: result.userId
                        }
                    });
                    showSuccess(result.message);
                    history.push(`/${customerRouteName}/vtex/credentials`);
                }
                if(result.error){
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error){
                setFormErrors({customerApiKey: "error", customerApiToken: "error", storeUrl: "error"})
                setIsLoading(false);
                showError(error);
            }
        } else {
            return
        }
    }

    return (
        <form onSubmit={configVtexKeys}>
            <motion.div variants={childVariants}>
                <InputsSimple
                    value={body.customerApiKey}
                    id="customerApiKey"
                    type="text"
                    placeholder=""
                    labelTxt="API Key*"
                    onChangeFn={handleChange}
                    resultValidation={formErrors?.customerApiKey ? "is-invalid" : ""}
                    errorTxt={false}
                />
            </motion.div>

            <motion.div variants={childVariants}>
                <InputsSimple
                    value={body.customerApiToken}
                    id="customerApiToken"
                    type="text"
                    placeholder=""
                    labelTxt="API Token*"
                    onChangeFn={handleChange}
                    resultValidation={formErrors?.customerApiToken ? "is-invalid" : ""}
                    errorTxt={false}
                />
            </motion.div>

            <motion.div variants={childVariants}>
                <InputsSimple
                    value={body.storeUrl}
                    id="storeUrl"
                    type="text"
                    placeholder="https://nombredetienda.myvtex.com"
                    labelTxt="URL de la tienda VTEX*"
                    onChangeFn={handleChange}
                    resultValidation={formErrors?.storeUrl ? "is-invalid" : ""}
                />
            </motion.div>

            <motion.div variants={growBigBtn} className="login-btn">
                <ButtonSmall 
                type="submit"
                btnTxt="Siguiente"
                showSpinner={isLoading}
                isDisabled={(body.customerApiKey && body.customerApiToken && body.storeUrl) ? false : true}
                extraClass="block"
                />
            </motion.div>
        </form>
    )
}
