import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from "react-router";
import { capitalizeFirstLetter, setEcommerceURL } from '../../../services/shared/utils.service';
import { SignInForm } from '../../../components/onboarding/authentication/SignInForm';
import { IntroColumn } from '../../../components/onboarding/IntroColumn';
import { useAuthStore } from '../../../store';
import { motion } from 'framer-motion';
import { fadeInAndUp } from '../../../services/shared/animation.service';
import { customerRouteName, customerName } from '../../../data/InfoSource';

export const SignIn = () => {
    let history = useHistory();
    const location = useLocation();
    const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));
    const addAuth = useAuthStore((state) => state.addAuthData);

    useEffect(() => {
      localStorage.clear();
      if(ecommerce){
        addAuth({
          user: {
            ecommerce: ecommerce,
          },
        });
      }
    }, [ecommerce])

    useEffect(() => {
      document.title = `${customerName} - ${capitalizeFirstLetter(ecommerce)}`
    }, []);

    return (
    <div className="container-fluid" id="welcome">
      <div className="row">
          <IntroColumn ecommerce={ecommerce}/>
        <div className="col col-xxl-8 form-col">
          <motion.div className="form-wrapper" variants={fadeInAndUp} initial="hidden" animate="visible">
            <h2 className="main-title">
              Inicia sesión
            </h2>
            <SignInForm ecommerce={ecommerce} />
          </motion.div>
        </div>
      </div>
    </div>
    )
}
