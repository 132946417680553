import { Route, Switch } from "react-router-dom";
import { Credentials } from "../../../pages/onboarding/Credentials";
import { CreateCenter } from "../../../pages/onboarding/vtex-only/CreateCenter";
import { DeliveryServices } from "../../../pages/onboarding/DeliveryServices";
import { FinishSuccess } from "../../../pages/onboarding/FinishSuccess";
import { FinishError } from "../../../pages/onboarding/FinishError";
import PanelRoute from "../panel/panelRoute";


export default function CustomerBCRoute({ rootCustomerPath }) {
  return (
    <>
      <Switch>
        <Route exact path={`/${rootCustomerPath}/bigcommerce/credentials`} component={Credentials} />
        <Route exact path={`/${rootCustomerPath}/bigcommerce/location-list`} component={CreateCenter} />
        <Route exact path={`/${rootCustomerPath}/bigcommerce/delivery-services`} component={DeliveryServices} />
        <Route exact path={`/${rootCustomerPath}/bigcommerce/finish`} component={FinishSuccess} />
        <Route exact path={`/${rootCustomerPath}/bigcommerce/finish-error`} component={FinishError} />

        <Route path={`/${rootCustomerPath}/bigcommerce/panel`} render={() => <PanelRoute rootCustomerPath={rootCustomerPath} /> }/> 

        <Route exact path={`/${rootCustomerPath}/bigcommerce`} component={Credentials} />
      </Switch>
    </>
  );
}
