import React from 'react';
import { colorSetter, dateFormatter, statusTranslator, timeFormatter } from '../../../services/grid.service';
import alertPurple from '../../../assets/icons/alertPurple.svg';
import mapPin from '../../../assets/icons/mapPin.svg';

export const HistorySection = ({ historyRecords }) => {
    return (
        <div className='details-body-module'>
            <h2 className='details-body-mainTitle'>Historial de estatus</h2>
            <ul className='history-list'>
                {historyRecords.map( (step, index) => (
                    <li className='history-item' key={`history-${index}`}>
                        {colorSetter(statusTranslator(step.statusName)) === "circlePink" ? <img src={alertPurple} alt={`${step.statusName}`} className='icon' /> : <img src={mapPin} alt="pin" className='icon' />}
                        <div className='history-status-title'>
                            <img src={colorSetter(statusTranslator(step.statusName))} alt={`${step.statusName}`} />
                            <p className='history-status'>{statusTranslator(step.statusName)}</p>
                        </div>
                        <p className='secondary'>El {dateFormatter(step.statusDateChange)}</p>
                        <p className='secondary'>A las {timeFormatter(step.statusDateChange)}</p>
                    </li>
                ))}
            </ul>
        </div>
    )
}
