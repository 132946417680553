import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import queryString from 'query-string';
import { useAuthStore } from "../../store";
import { IntroColumn } from "../../components/onboarding/IntroColumn";
import { LoginForm } from "../../components/onboarding/authentication/LoginForm";
import { capitalizeFirstLetter, setEcommerceURL } from "../../services/shared/utils.service";
import { motion } from 'framer-motion';
import { fadeInAndUp } from "../../services/shared/animation.service";
import { urlInfo, customerName } from '../../data/InfoSource';
import { showError } from "../../services/shared/alert.service";
import { getInitialState } from "../../services/onboarding.service";

export const Credentials = () => {
    const location = useLocation();
    const [storeName, setStoreName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const addAuth = useAuthStore((state) => state.addAuthData);
    const user = useAuthStore((state) => state.authData.user);
    const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));

    const { shop = "" } = queryString.parse(location.search);

    useEffect(() => {
        // if (user.ecommerce !== ecommerce || !user.ecommerce) {   //revisar
        //     localStorage.clear();
        // }
        flowCorrector(user?.insidePanel)
        setUserInitialState()
    }, []);

    const flowCorrector = (isPanelFlow) => {
        if (isPanelFlow) {
            addAuth({
                user: {
                    ...user,
                    insidePanel: false
                },
            });
        }
        document.title = `${customerName} - ${capitalizeFirstLetter(ecommerce)}`
    }

    const setUserInitialState = async () => {
        if (!storeName) {
            setIsLoading(true);
            if (ecommerce === "shopify" && shop) {
                setStoreName(extractStoreName(shop));
                try {
                    const result = await getInitialState(shop);
                    if (result && result.success) {
                        addAuth({
                            user: {
                                storeId: shop,
                                ecommerce: ecommerce,
                                tokenForEmbedded: result?.data?.token || "",
                                idCode: result?.data?.shop_id || "",
                                accessToken: result?.data?.access_token || "",
                            },
                        });
                    }
                } catch (error) {
                    showError(error)
                }
            }
            if (ecommerce === "bigcommerce" && shop) {
                try {
                    const result = await getInitialState(shop);
                    if (result && result.success) {
                        setStoreName(result?.data?.user?.storeName);
                        addAuth({
                            user: {
                                storeId: shop,
                                ecommerce: ecommerce,
                                tokenForEmbedded: result?.data?.token || "",
                                idCode: result?.data?.shop_id || "",
                                accessToken: result?.data?.access_token || "",
                            },
                        });
                    }
                } catch (error) {
                    showError(error)
                }
            }
            if (ecommerce === "vtex") {
                addAuth({
                    user: {
                        ...user,
                        storeId: "",
                        ecommerce: ecommerce,
                    },
                });
            }
            setIsLoading(false);
        } else {
            showError();
        }
    }

    const extractStoreName = (fullShopName) => {
        let parts = fullShopName.split(".")
        return parts[0]
    }

    return (
        <div className="container-fluid" id="welcome">
            <div className="row">
                <IntroColumn ecommerce={ecommerce} storeName={storeName} isLoading={isLoading} />
                <div className="col col-9 form-col">
                    <motion.div className="form-wrapper" variants={fadeInAndUp} initial="hidden" animate="visible">
                        <h1 className="main-title align-left">
                            Conectar
                        </h1>
                        <p className="intro-subtitle align-left mt-4 mb-2">
                            Para conectar tu tienda, necesitas una cuenta en Skydropx. Si aún no la tienes, <a href={`${urlInfo.createAccount}`} target="_blank" rel="noreferrer" className="btn-link">crea tu cuenta</a>.
                        </p>
                        <LoginForm ecommerce={ecommerce} />
                    </motion.div>
                </div>
            </div>
        </div>
    );
};
