import React from 'react';
import bgLeft from '../../assets/bgLeft.svg';
import bgRight from '../../assets/bgRight.svg';

export const Background = () => {
    return (
        <div className='bg-illustration-wrapper'>
            <img src={bgLeft} alt="" className='bg-left' />
            <img src={bgRight} alt="" className='bg-right' />
        </div>
    )
}
