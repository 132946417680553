export const customerName = "Skydropx"

export const customerRouteName = "skydropx"

export const urlInfo = {
    integrationsApiUrl: "https://app.skydropx.com/store_connections",
    createAccount: "https://app.skydropx.com/users/sign_up",
}

export const maxInputLength = 300;
