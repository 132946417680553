import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AbstractSection } from './AbstractSection';
import { HistorySection } from './HistorySection';

export const OrderDetailsModal = ({ show, orderData, handleClose }) => {

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" id="details-modal">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Cerrar</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

                <AbstractSection orderData={orderData} />

                <div className='details-body-module'>
                    <h2 className='details-body-mainTitle'>Detalles del envío</h2>
                    <div className='details-product-delivery'>
                        <span>Núm. de envío:</span>
                        <span className='semibold'>{orderData.orderNumber}</span>
                    </div>
                    <div className='details-product-delivery'>
                        <span>Núm. de rastreo:</span>
                        <a href={orderData?.trackingUrl} className='blue semibold' target="_blank" rel="noreferrer">{orderData.trackingNumber}</a>
                    </div>
                </div>

                <HistorySection historyRecords={orderData.statusHistory} />

                <div className='details-body-module'>
                    <h2 className='details-body-mainTitle'>Detalles de entrega</h2>
                    <div className='details-product-delivery'>
                        <span>Entrega estimada:</span>
                        <span>{orderData.deliveryService.estimatedTime===1 ? `${orderData.deliveryService.estimatedTime} día hábil` : `${orderData.deliveryService.estimatedTime} días hábiles`}</span>
                    </div>
                </div>

                <div className='details-body-module'>
                    <h2 className='details-body-mainTitle'>Datos del cliente</h2>
                    <div className='details-product-delivery'>
                        <span>Nombre:</span>
                        <span className='limit-width align-right'>{orderData?.userData?.name}</span>
                    </div>
                    <div className='details-product-delivery'>
                        <span>Dirección:</span>
                        <span className='limit-width align-right'>{orderData?.userData?.address}</span>
                    </div>
                    <div className='details-product-delivery'>
                        <span>Correo electrónico:</span>
                        <span className='limit-width align-right'>{orderData?.userData?.email}</span>
                    </div>
                    <div className='details-product-delivery'>
                        <span>Teléfono:</span>
                        <span className='limit-width align-right'>{orderData?.userData?.phoneNumber}</span>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}
