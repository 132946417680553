import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import cellInfoBlack from "../../../assets/icons/cellInfoBlack.svg"
import { colorSetter } from '../../../services/grid.service';

export const ReferenceModal = ({ handleClose }) => {
  const [statusList, setStatusList] = useState([
    {
      name: "Por crear",
      meaning: "Hace falta información necesaria para crear tu envío.",
    },
    {
      name: "Recolectado",
      meaning: "Tu envío ya fue entregado a la paquetería.",
    },
    {
      name: "En camino",
      meaning: "Tu envío inició el recorrido a su destino.",
    },
    {
      name: "Por llegar",
      meaning: "Tu envío está cerca de terminar su recorrido.",
    },
    {
      name: "Entregado",
      meaning: "Tu envío ha llegado a su destinatario.",
    },
    {
      name: "Con Incidencia",
      meaning: "Tu envío presenta algún tipo de incidencia por parte de la paquetería.",
    },
    {
      name: "Cancelado",
      meaning: "Tu envío ha sido cancelado.",
    },
  ]);

  return (
    <Modal
      className="rounded-3"
      id="reference-modal"
      show={true}
      backdrop={true}
      onHide={handleClose}
      centered
      size="md"
      scrollable
    >
      <Modal.Header closeButton className="modal-header border-0">
        <p className='reference-title'> <img src={cellInfoBlack} alt="" /> Significado</p>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <ul className='status-list'>
          {statusList.map( (itemInfo, index) => (
            <li className='status-item' key={`status ${index}`}>
              <img src={colorSetter(itemInfo.name)} alt={`${itemInfo.name}`} />
              <div className='status-text-wrapper'>
                <p>{itemInfo.name}</p>
                <p className='secondary'>{itemInfo.meaning}</p>
              </div>
            </li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  )
}
