import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getWaybillClasses } from '../../services/panel.service';
import { showError } from '../../services/shared/alert.service';
import { useAuthStore } from '../../store';
import { FiSearch } from "react-icons/fi";

export const ClassesInfiniteScroll = ({ body, setBody, fullWidthStyle = true }) => {
    const user = useAuthStore((state) => state.authData.user);
    const [selectClassesOptions, setSelectClassesOptions] = useState([]);
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [searchTag, setSearchTag] = useState("");
    const [page, setPage] = useState(1);

    const fetchClasses = async (storeId, searchTag, page, pageSize) => {
        try {
            let result = await getWaybillClasses(storeId, searchTag, page, pageSize);
            if (result && result.success) {
                setSelectClassesOptions([...selectClassesOptions, ...result.classType]);
                if (result.classType.length === 0) {
                    setHasMoreResults(false);
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const searchTagHandler = (e) => {
        setSearchTag(e.target.value);
        if (e.target.value === "") {
            cleanUp();
        }
        if ((e.target.value).length > 3) {
            fetchClasses(user?.storeId, e.target.value);
        }
    }

    // const handleSearch = () => {
    //     fetchClasses(user?.storeId, searchTag);
    // }

    const cleanUp = () => {
        setSelectClassesOptions([]);
        setHasMoreResults(true);
    }

    const handleClassChange = (e, idValue, nameValue) => {
        setBody({ ...body, waybillClass: { id: idValue, name: nameValue } });
        setSearchTag(nameValue);
        cleanUp();
    }

    return (
        <div id="class-infinite-scroll">
            <div className={`search-wrapper ${fullWidthStyle ? "" : "smaller-version"}`}>
                <div className='w-100'>
                    <FiSearch size={20} />
                    <input onChange={searchTagHandler} value={searchTag} type="text" name="q" id="search-input" placeholder={fullWidthStyle ? "Escribe una palabra, por ej. zapatos" : "Escribe una palabra"} />
                </div>
                {/* {searchTag.length >= 3 && <button type='button' onClick={handleSearch} className="btn-link fw-bold">Buscar</button>} */}
            </div>
            {selectClassesOptions.length !== 0 &&
                <InfiniteScroll
                    dataLength={selectClassesOptions.length}
                    next={() => { fetchClasses(user?.storeId, searchTag, page + 1); setPage(page + 1) }}
                    hasMore={hasMoreResults}
                    height={200}
                    loader={<div className='spinner-wrapper'><div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Cargando...</span></div></div>}
                    endMessage={
                        <p className='noResults'>No hay más resultados para mostrar</p>
                    }
                >
                    {selectClassesOptions.map((option, index) => (
                        <li key={`classOption-${index}`} className="fetched-classes-item">
                            <button className='fetched-classes-btn' type='button' onClick={(e) => handleClassChange(e, option.id, option.name)}>
                                {option.name}
                            </button>
                        </li>
                    ))}
                </InfiniteScroll>
            }
        </div>
    )
}
