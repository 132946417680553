import React from 'react';
import { motion } from 'framer-motion';
import { fadeInAndUp } from '../../services/shared/animation.service';
import { WaybillForm } from '../../components/panel/WaybillForm';

export const WaybillConfiguration = () => {
    return (
        <div id="waybill-form" className="content-wrapper">
            <motion.div variants={fadeInAndUp} initial="hidden" animate="visible">
                <h1 className="main-title">Carta porte</h1>
                <h3 className="main-subtitle mb-4 mb-md-5">Configura una carta porte general para los envíos que se crearán de manera automática.</h3>
            </motion.div>
            <WaybillForm />
        </div>
    )
}
