import React, { useState } from 'react';
import { useLocation } from "react-router";
import { setEcommerceURL } from '../../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { IntroColumn } from '../../../components/onboarding/IntroColumn';
import { VtexIntegrationForm } from '../../../components/onboarding/authentication/VtexIntegrationForm';
import { fadeInAndUp } from '../../../services/shared/animation.service';

export const VtexKeysConfig = () => {
  const location = useLocation();
  const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));

  return (
    <div className="container-fluid" id="welcome">
      <div className="row">
        <IntroColumn ecommerce={ecommerce} />
        <div className="col col-xxl-8 form-col">
          <motion.div className="form-wrapper" variants={fadeInAndUp} initial="hidden" animate="visible">
            <h2 className="main-title">
              Configuración de claves - VTEX
            </h2>
            <VtexIntegrationForm />
          </motion.div>
        </div>
      </div>
    </div>
  )
}