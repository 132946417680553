import { Route, Switch } from "react-router-dom";
import CustomerShopifyRoute from './shopify/customerShopifyRoute';
import CustomerBCRoute from './bigcommerce/customerBCRoute';
import CustomerVTEXRoute from './vtex/customerVTEXRoute';

export default function CustomerRoute({ rootCustomerPath }) {
  return (
    <>
        <Switch>
            <Route path={`/${rootCustomerPath}/secommerce`} render={() => <CustomerShopifyRoute rootCustomerPath={rootCustomerPath} />} />
            <Route path={`/${rootCustomerPath}/bigcommerce`} render={() => <CustomerBCRoute rootCustomerPath={rootCustomerPath} />} />
            <Route path={`/${rootCustomerPath}/vtex`} render={() => <CustomerVTEXRoute rootCustomerPath={rootCustomerPath} />} />
        </Switch>
    </>
  );
}
