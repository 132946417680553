export const mockedLocations = {
    success: true,
    locations: [
        {
            availableForDelivery: true,
            inCoverage: true,
            // ecommerceConfigured: true, //propio shopify
            locationId: "65924169961",
            name: "",

            countryCode: "MX", //sumado en vtex
            country: "México",
            province: "CDMX",
            city: "cdmx",
            address: "Alberti 1598",
            zip: "1428",

            manager: "Juan Carlos García",
            phoneNumber: "456963852",
            notes: "No funciona el timbre ",
            email: "email@test.com",
        },
        {
            availableForDelivery: false,
            inCoverage: true,
            // ecommerceConfigured: true,
            locationId: "65924169962",
            name: "EjemploShopEjemploShopEjemploShopEjemploShop",

            countryCode: "MX", //sumado en vtex
            country: "México",
            province: "CDMX",
            city: "cdmx",
            address: "Alberti 1598",
            zip: "1428",

            manager: "Juan Carlos García",
            phoneNumber: "456963852",
            notes: "No funciona el timbre ",
            email: "email@test.com",
        },
        {
            availableForDelivery: false,
            inCoverage: false,
            // ecommerceConfigured: true,
            locationId: "65924169963",
            name: "",

            countryCode: "MX", //sumado en vtex
            country: "México",
            province: "CDMX",
            city: "cdmx",
            address: "Alberti 1598",
            zip: "1428",

            manager: "Juan Carlos García",
            phoneNumber: "456963852",
            notes: "No funciona el timbre ",
            email: "email@test.com",
        },
        {
            availableForDelivery: false,
            inCoverage: false,
            // ecommerceConfigured: false,
            locationId: "65924169964",
            name: "",

            countryCode: "MX", //sumado en vtex
            country: "México",
            province: "CDMX",
            city: "cdmx",
            address: "Alberti 1598",
            zip: "",

            manager: "Juan Carlos García",
            phoneNumber: "456963852",
            notes: "No funciona el timbre ",
            email: "email@test.com",
        },
    ]
}

export const mockedinitialState = {
    success: true,
    data: {
        user: {
            storeName: "TestStore",
        },
        shop_id: "62cd67a8b508650021166598",
        token: "p7A6bA.x_LLNxTgZl1t.6b/Y",
        access_token: ""
    }
}

export const mockedBCLocation = {
    success: true,
    locations: [
        {
            countryCode: "MX",
            country: "México",
            province: "CDMX",
            city: "cdmx",
            street: "Alberti",
            externalNumber: "1598",
            internalNumber: "4d",
            zip: "1428",
            manager: "Juan Carlos García",
            phoneNumber: "456963852",
            email: "email@test.com",
            notes: "No funciona el timbre ",
        }
    ]
}

export const deliveryServices = {
    success: true,
    deliveryServices: [
        {
            isSelected: true,
            provider: "Fedex",
            services: [
                {
                    code: 'FEDEX_EXPRESS',
                    serviceLevelName: 'Fedex Express Saver',
                    isActive: false,
                },
                {
                    code: 'STANDARD_OVERNIGHT',
                    serviceLevelName: 'Standard Overnight',
                    isActive: true,
                },
                {
                    code: 'INTERNATIONAL_PRIORITY',
                    serviceLevelName: 'International Priority',
                    isActive: false,
                },
                {
                    code: 'INTERNATIONAL_ECONOMY',
                    serviceLevelName: 'Internationa Economy',
                    isActive: false,
                }
            ]
        },
        {
            isSelected: false,
            provider: "Redpack",
            services: [
                {
                    code: 'ECOEXPRESS',
                    serviceLevelName: 'Ecoexpress',
                    isActive: false,
                },
                {
                    code: 'EXPRESS',
                    serviceLevelName: 'Express',
                    isActive: false,
                }
            ]
        },
        {
            isSelected: false,
            provider: "Estafeta",
            services: [
                {
                    code: 'ECOEXPRESS',
                    serviceLevelName: 'Ecoexpress',
                    isActive: false,
                },
                {
                    code: 'EXPRESS',
                    serviceLevelName: 'Express',
                    isActive: false,
                }
            ]
        },
        {
            isSelected: false,
            provider: "ups",
            services: [
                {
                    code: 'ECOEXPRESS',
                    serviceLevelName: 'Ecoexpress',
                    isActive: false,
                },
                {
                    code: 'EXPRESS',
                    serviceLevelName: 'Express',
                    isActive: false,
                }
            ]
        },
        {
            isSelected: false,
            provider: "ninetynineminutes",
            services: [
                {
                    code: 'ECOEXPRESS',
                    serviceLevelName: 'Ecoexpress',
                    isActive: false,
                },
                {
                    code: 'EXPRESS',
                    serviceLevelName: 'Express',
                    isActive: false,
                }
            ]
        },
        {
            isSelected: false,
            provider: "paquetexpress",
            services: [
                {
                    code: 'ECOEXPRESS',
                    serviceLevelName: 'Ecoexpress',
                    isActive: false,
                },
                {
                    code: 'EXPRESS',
                    serviceLevelName: 'Express',
                    isActive: false,
                }
            ]
        },
        {
            isSelected: false,
            provider: "otro",
            services: [
                {
                    code: 'ECOEXPRESS',
                    serviceLevelName: 'Ecoexpress',
                    isActive: false,
                },
                {
                    code: 'EXPRESS',
                    serviceLevelName: 'Express',
                    isActive: false,
                }
            ]
        }
    ]
}

export const mockedOrders = {
    success: true,
    total: 5,
    existingDeliveryServices: [],
    existingStoreNames: [],
    orders: [
        {
            orderId: "61954b6ad8b38d7f4b7d7cb1",
            orderNumber: "1258271022974-00",
            creationDate: "2022-03-04T18:35:22.833Z",
            trackingStatus: "PENDING", //aún falta lista definitiva de status
            updateStatusDate: "2022-03-05T19:20:14.833Z",
            trackingNumber: "#012345678910",
            trackingUrl: "https://www.google.com/",
            label: "https://shipkraken-demo.s3.amazonaws.com/uploads/label/label_file/ba5cfb8d-1f3e-4c1f-8ca5-de4c12e55398.pdf", //pdf
            storeName: "Tienda 1",
            statusHistory: [],
            packageInfo: {
                weight: 0,
                width: 0,
                height: 0,
                length: 0,
                quantity: 0,
                packageType: {
                    name: "Caja de cartón",
                    id: "1H1"
                },
                waybillClass: {
                    name: "Palabra clave",
                    id: "24112900"
                }
            },
            deliveryService: {
                provider: "DHL Express",
                serviceLevelName: "Estándar",
                serviceLevelCode: "DHL_EXPRESS_ESTANDAR",
                estimatedTime: 6,
                shippingCost: 200,
                currency: "MXN",
            },
            userData: {
                name: "Salvador Gamboa",
                email: "gamboa@outlook.com",
                phoneNumber: "81 6543 7235",
                country: "México",
                countryCode: "MX",
                city: "NAUCALPAN DE JUÁREZ",
                province: "méxico",
                address: "Albatroz 144A",
                colony: "Zoológico",
                zip: "64920",
                notes: "Casa Roja",
                error: ["colony"], //iría el nombre/s de la/s variable/s que falló/fallaron
            },
            products: [
                {
                    productName: "Perfume one million",
                    productPrice: 450,
                    quantity: 3 //si sigue estando disponible
                },
            ]
        },
        {
            orderId: "61954b6ad8b38d7f4b7d7cb1",
            orderNumber: "0000-0001",
            creationDate: "2022-03-03T18:35:22.833Z",
            trackingStatus: "PENDING", //aún falta lista definitiva de status
            updateStatusDate: "2022-03-05T19:20:14.833Z",
            trackingNumber: "#012345678910",
            trackingUrl: "https://www.google.com/",
            label: "https://shipkraken-demo.s3.amazonaws.com/uploads/label/label_file/ba5cfb8d-1f3e-4c1f-8ca5-de4c12e55398.pdf", //pdf
            storeName: "Tienda 1",
            statusHistory: [],
            packageInfo: {
                weight: 3,
                width: 10,
                height: 20,
                length: 30,
                quantity: 1,
                packageType: {
                    name: "Caja de cartón",
                    id: "1H1"
                },
                waybillClass: {
                    name: "Palabra clave",
                    id: "24112900"
                }
            },
            deliveryService: {
                provider: "Estafeta",
                serviceLevelName: "Estándar",
                serviceLevelCode: "DHL_EXPRESS_ESTANDAR",
                estimatedTime: 5,
                shippingCost: "150",
                currency: "MXN",
            },
            userData: {
                name: "Salvador Gamboa",
                email: "gamboa@outlook.com",
                phoneNumber: "81 6543 7235",
                country: "México",
                countryCode: "MX",
                city: "Monterrey",
                province: "N.L",
                address: "Albatroz 144A",
                colony: "Zoológico",
                zip: "64920",
                notes: "Casa Roja",
                error: ["zip", "address", "email"], //iría el nombre/s de la/s variable/s que falló/fallaron
            },
            products: [
                {
                    productName: "Tenis Nike no. 5 blancos edition ...",
                    productPrice: 100,
                    quantity: 3 //si sigue estando disponible
                },
                {
                    productName: "Perfume one million",
                    productPrice: 450,
                    quantity: 1 //si sigue estando disponible
                },
            ]
        },
        {
            orderId: "61954b6ad8b38d7f4b7d7cb1",
            orderNumber: "0000-0002",
            creationDate: "2022-03-04T18:35:22.833Z",
            trackingStatus: "PENDING", //aún falta lista definitiva de status
            updateStatusDate: "2022-03-05T19:20:14.833Z",
            trackingNumber: "#012345678910",
            trackingUrl: "https://www.google.com/",
            label: "https://shipkraken-demo.s3.amazonaws.com/uploads/label/label_file/ba5cfb8d-1f3e-4c1f-8ca5-de4c12e55398.pdf", //pdf
            storeName: "Tienda 1",
            statusHistory: [],
            packageInfo: {
                weight: 3,
                width: 10,
                height: 20,
                length: 20,
                quantity: 2,
                packageType: {
                    name: "Caja de cartón",
                    id: "1H1"
                },
                waybillClass: {
                    name: "Palabra clave",
                    id: "24112900"
                }
            },
            deliveryService: {
                provider: "",
                serviceLevelName: "",
                serviceLevelCode: "",
                estimatedTime: 0,
                shippingCost: 0,
                currency: "",
            },
            userData: {
                name: "Salvador Gamboa",
                email: "gamboa@outlook.com",
                phoneNumber: "81 6543 7235",
                country: "México",
                countryCode: "MX",
                city: "Monterrey",
                province: "N.L",
                address: "Albatroz 144A",
                colony: "Zoológico",
                zip: "64920",
                notes: "Casa Roja",
                error: [], //iría el nombre/s de la/s variable/s que falló/fallaron
            },
            products: [
                {
                    productName: "Perfume one million",
                    productPrice: 450,
                    quantity: 1 //si sigue estando disponible
                },
            ]
        },
        {
            orderId: "61954b6ad8b38d7f4b7d7cb1",
            orderNumber: "0000-0003",
            creationDate: "2022-03-04T18:35:22.833Z", //fecha de creación de la orden
            trackingStatus: "CREATED", //aún falta lista definitiva de status pero idealmente irían en inglés y minúsculas
            updateStatusDate: "2022-03-05T19:20:14.833Z", //fecha en que la orden cambió al status actual (en este ejemplo, el momento en que pasó a ser 'recollected')
            trackingNumber: "#012345678910",
            trackingUrl: "https://www.google.com/",
            label: "https://shipkraken-demo.s3.amazonaws.com/uploads/label/label_file/ba5cfb8d-1f3e-4c1f-8ca5-de4c12e55398.pdf", //pdf
            storeName: "Tienda 1",
            statusHistory: [
                {
                    statusName: "PENDING",
                    statusDateChange: "2022-03-04T18:35:22.833Z"
                },
                {
                    statusName: "CREATED",
                    statusDateChange: "2022-03-05T19:20:14.833Z"
                },
            ],
            packageInfo: {
                weight: 5, //asumo que sólo viene en kg ????
                width: 10,
                height: 20,
                length: 30,
                quantity: 1,
                packageType: {
                    name: "Caja de cartón",
                    id: "1H1"
                },
                waybillClass: {
                    name: "Palabra clave",
                    id: "24112900"
                }
            },
            deliveryService: {
                provider: "DHL",
                serviceLevelName: "Estándar",
                serviceLevelCode: "DHL_EXPRESS_ESTANDAR",
                estimatedTime: 6,
                shippingCost: 200,
                currency: "MXN",
            },
            userData: {
                name: "Salvador Gamboa",
                email: "gamboa@outlook.com",
                phoneNumber: "81 6543 7235",
                country: "México",
                countryCode: "MX",
                city: "Monterrey",
                province: "N.L",
                address: "Albatroz 144A",
                colony: "Zoológico",
                zip: "64920",
                notes: "Casa Roja",
                error: [], //iría el nombre/s de la/s variable/s que falló/fallaron
            },
            products: [
                {
                    productName: "Perfume one million",
                    productPrice: 450,
                    quantity: 3
                },
            ]
        },
        {
            orderId: "61954b6ad8b38d7f4b7d7cb2",
            orderNumber: "0000-0004",
            creationDate: "2022-03-04T18:35:22.833Z", //fecha de creación de la orden
            trackingStatus: "PENDING", //aún falta lista definitiva de status pero idealmente irían en inglés y minúsculas
            updateStatusDate: "2022-03-05T19:20:14.833Z", //fecha en que la orden cambió al status actual (en este ejemplo, el momento en que pasó a ser 'recollected')
            trackingNumber: "#012345678911",
            trackingUrl: "https://www.google.com/",
            label: "https://shipkraken-demo.s3.amazonaws.com/uploads/label/label_file/ba5cfb8d-1f3e-4c1f-8ca5-de4c12e55398.pdf", //pdf
            storeName: "Tienda 2",
            statusHistory: [
                {
                    statusName: "PENDING",
                    statusDateChange: "2022-03-05T19:20:14.833Z"
                },
                {
                    statusName: "ERROR",
                    statusDateChange: "2022-04-06T18:45:15.833Z"
                },
            ],
            packageInfo: {
                weight: 5, //asumo que sólo viene en kg ????
                width: 10,
                height: 20,
                length: 30,
                quantity: 1,
                packageType: {
                    name: "Caja de cartón",
                    id: "1H1"
                },
                waybillClass: {
                    name: "Palabra clave",
                    id: "24112900"
                }
            },
            deliveryService: {
                provider: "DHL",
                serviceLevelName: "Estándar",
                serviceLevelCode: "DHL_EXPRESS_ESTANDAR",
                estimatedTime: 6,
                shippingCost: 200,
                currency: "MXN",
            },
            userData: {
                name: "Salvador Gamboa",
                email: "gamboa@outlook.com",
                phoneNumber: "81 6543 7235",
                country: "México",
                countryCode: "MX",
                city: "Monterrey",
                province: "N.L",
                address: "Albatroz 144A",
                colony: "Zoológico",
                zip: "64920",
                notes: "Casa Roja",
                error: [], //iría el nombre/s de la/s variable/s que falló/fallaron
            },
            products: [
                {
                    productName: "Perfume one million",
                    productPrice: 450,
                    quantity: 3
                },
            ]
        },
    ]
}

export const mockQuotation = {
    success: true,
    deliveryServices: [
        {
            provider: "Fedex",
            serviceLevelName: "Fedex Express Saver",
            serviceLevelCode: "FEDEX_EXPRESS_ESTANDAR",
            estimatedTime: 3,
            shippingCost: "200",
            currency: "MXN",
        },
        {
            provider: "Estafeta",
            serviceLevelName: "Estándar",
            serviceLevelCode: "ESTAFETA_ESTANDAR",
            estimatedTime: 5,
            shippingCost: 150,
            currency: "MXN",
        },
    ]
}

export const mockedProductPackage = {
    success: true,
    packageType: [
        {
            id: "1H1",
            name: "Caja de cartón",
        },
        {
            id: "1H2",
            name: "Bolsa",
        }, 
    ],
}

export const mockedProductWaybillClass = {
    success: true,
    classType: [
        {
            id: "24112900",
            name: "Palabra clave",
        },
        {
            id: "24112901",
            name: "Categoria",
        },
    ],
}

export const mockedProductsList = {
    success: true,
    products: [
        {
            shopifyProductId: 7487589351651,
            title: "producto 1",
            height: null,
            width: null,
            length: null
        },
        {
            shopifyProductId: 7814122438883,
            title: "Remera Poniente MX",
            height: null,
            width: null,
            length: null
        },
    ]
}