import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { useAuthStore, useonboardingStore } from '../../store';
import { motion } from 'framer-motion';
import { fadeInAndRight } from '../../services/shared/animation.service';
import { OnboardingStepper } from '../../components/onboarding/OnboardingStepper';
import { LocationForm } from '../../components/onboarding/locationConfig/LocationForm';
import { OutOfCoverage } from '../../components/onboarding/locationConfig/OutOfCoverage';

export const LocationSingle = () => {
    const location = useLocation();
    const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const [singleLocation, setSingleLocation] = useState({});
    const [singleLocationIndex, setSingleLocationIndex] = useState(null);
    const [allLocations, setAllLocations] = useState(store?.currentLocations);

    useEffect(() => {
        let pathArray = location.pathname.split("/");
        let selectedId = pathArray[pathArray.length - 1];
        let list = store?.currentLocations;
        let singleLocSelected = list.find(loc => loc.locationId === selectedId);
        let singleLocIndex = list.findIndex(loc => loc.locationId === selectedId);
        setSingleLocation(singleLocSelected);
        setSingleLocationIndex(singleLocIndex);
    }, [])

    return (
        <div className="container-fluid" id="locationSingle">
            <div className="row">
                <div className="col-3 p-0">
                    <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
                        <OnboardingStepper step={1} ecommerce={ecommerce} />
                    </motion.div>
                </div>
                <div className="col-9 content-wrapper">
                    {singleLocation.inCoverage ?
                        <LocationForm ecommerce={ecommerce} singleLocationIndex={singleLocationIndex} allLocations={allLocations} {...singleLocation} />
                        :
                        <OutOfCoverage ecommerce={ecommerce} singleLocationIndex={singleLocationIndex} {...singleLocation} />
                    }
                </div>
            </div>
        </div>
    )
}
