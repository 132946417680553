import React from 'react'
import { deepCopy } from '../../services/shared/utils.service';
import { InputsCheckbox } from '../units/Inputs'

export const PolicyCard = ({ info, setFullList, fullList, setFilteredList, filteredList, objIndex }) => {

    const handleActiveAllFromSingleProvider = (e, id) => {
        //update body
        let mainArrToUpdateCopy = deepCopy(fullList);
        let selectedObjCopy = mainArrToUpdateCopy.find((element) => element.provider === id);
        let selectedObjIndex = mainArrToUpdateCopy.findIndex((element) => element.provider === id);
        selectedObjCopy.services.forEach( (service) => service.isActive = e.target.checked);
        selectedObjCopy.isSelected = e.target.checked;
        mainArrToUpdateCopy[selectedObjIndex] = selectedObjCopy;
        setFullList([...mainArrToUpdateCopy]);

        //update filteredBody
        let filteredArrToUpdateCopy = deepCopy(filteredList);
        let filteredObjCopy = filteredArrToUpdateCopy.find((element, index) => index === objIndex);
        filteredObjCopy.services.forEach( (service) => service.isActive = e.target.checked);
        filteredObjCopy.isSelected = e.target.checked;
        filteredArrToUpdateCopy[objIndex] = filteredObjCopy;
        setFilteredList([...filteredArrToUpdateCopy]);
    }

    const handleActiveSinglePolicy = (e, idSelected, providerId) => {
        let arrToUpdateCopy = deepCopy(info.services);
        let selectedObjCopy = arrToUpdateCopy.find((element) => element.serviceLevelName === idSelected);
        let selectedObjIndex = arrToUpdateCopy.findIndex((element) => element.serviceLevelName === idSelected);
        selectedObjCopy.isActive = e.target.checked;
        arrToUpdateCopy[selectedObjIndex] = selectedObjCopy;
        
        //updateBody
        let mainArrToUpdateCopy = deepCopy(fullList);
        let selectedArrCopy = mainArrToUpdateCopy.find((element, index) => element.provider === providerId);
        let selectedArrIndex = mainArrToUpdateCopy.findIndex((element) => element.provider === providerId);
        selectedArrCopy.services = arrToUpdateCopy;
        mainArrToUpdateCopy[selectedArrIndex] = selectedArrCopy;
        setFullList([...mainArrToUpdateCopy]);

        //update filteredBody
        let filteredArrToUpdateCopy = deepCopy(filteredList);
        let filteredArrCopy = filteredArrToUpdateCopy.find((element) => element.provider === providerId);
        let filteredArrIndex = filteredArrToUpdateCopy.findIndex((element) => element.serviceLevelName === idSelected);
        filteredArrCopy.services = arrToUpdateCopy;
        filteredArrToUpdateCopy[filteredArrIndex] = filteredArrCopy;
        setFilteredList([...filteredArrToUpdateCopy]);
    }

    return (
        <div id='policy-card-container'>
            <div className='policy-card-header'>
                <InputsCheckbox
                    id={info.provider}
                    value={true}
                    onChangeFn={(e) => handleActiveAllFromSingleProvider(e, info.provider)}
                    labelTxt={info.provider}
                    checked={info.isSelected === true}
                    disabled={false}
                />
            </div>

            <ul className='policy-card-body'>
                {info.services.map((item, index) => (
                    <li className='policy-card-item' key={`item-${item.serviceLevelName}-${index}`}>
                        <InputsCheckbox
                            id={item.serviceLevelName}
                            value={item.isActive}
                            onChangeFn={(e) => handleActiveSinglePolicy(e, item.serviceLevelName, info.provider)}
                            labelTxt={item.serviceLevelName}
                            checked={item.isActive === true}
                            disabled={false}
                        />
                    </li>
                ))}
            </ul>
        </div>
    )
}
