import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from './AppRoutes';
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.scss";

export const Routes = () => {

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};
