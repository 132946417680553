import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from "luxon";
import { mapFilterOptions } from '../../../services/grid.service';
import { useAuthStore } from '../../../store';
import Skeleton from 'react-loading-skeleton';
import refresh from '../../../assets/icons/refresh.svg';
import { SearchAndFilters } from './SearchAndFilters';
import { ReferenceModal } from './ReferenceModal';
import { GridRow } from './GridRow';
import { TableHeader } from './TableHeader';

export const Grid = ({ data, initialData, updateTable, ecommerce, originalLoading, totalAmountOfOrders, setFilterQuery, filterQuery, setIsLoaderModalShown, searchTag, setSearchTag, pageSize, paginationFrom, dateFilterSelected, setDateFilterSelected, dateFilterFrom, setDateFilterFrom, dateFilterTo, setDateFilterTo,
  statusFilterSelected, setStatusFilterSelected, deliveryServiceFilterSelected, setDeliveryServiceFilterSelected,
  storeFilterSelected, setStoreFilterSelected, deliveryServiceFilterOptions, setDeliveryServiceFilterOptions }) => {
  const auth = useAuthStore((state) => state.authData);
  const dateInput = useRef();
  const [isLoading, setIsLoading] = useState(originalLoading);
  const [isReferenceModalShown, setIsReferenceModalShown] = useState(false);
  const [isFilterDropdownShown, setIsFilterDropdownShown] = useState(false);
  const [showEmptyMsg, setshowEmptyMsg] = useState(false);

  const dateOptions = [
    { name: "Todas", value: "ALL" },
    { name: "Hoy", value: "TODAY" },
    { name: "Ayer", value: "YESTERDAY" },
    { name: "Últimos 7 días", value: "LAST_WEEK" },
    { name: "Últimos 15 días", value: "LAST_FIFTEEN_DAYS" },
    { name: "Últimos 30 días", value: "LAST_THIRTY_DAYS" },
    { name: "Últimos 2 meses", value: "LAST_TWO_MONTHS" },
    { name: "Últimos 3 meses", value: "LAST_THREE_MONTHS" },
  ];
  const statusOptions = [
    { label: "Por crear", value: "PENDING" },
    { label: "Recolectado", value: "PICKED_UP" },
    { label: "En camino", value: "IN_TRANSIT" },
    { label: "Por llegar", value: "LAST_MILE" },
    { label: "Entregado", value: "DELIVERED" },
    { label: "Con Incidencia", value: "DELIVERY_ATTEMPT" },
    { label: "Cancelado", value: "EXCEPTION" },
  ];
  const [storeFilterOptions, setStoreFilterOptions] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      setshowEmptyMsg(true)
    } else {
      setshowEmptyMsg(false)
    }
  }, [data]);

  useEffect(() => {
    prepareFilterOptions(deliveryServiceFilterOptions, "_id", "label", setDeliveryServiceFilterOptions);
    if(ecommerce !== "bigcommerce"){
      prepareFilterOptions(initialData, "storeName", "label", setStoreFilterOptions);
    }
  }, [])

  const prepareFilterOptions = (dataList, propertySelected, visibleTxtProp, stateSetter, objectContainerOfPropertySelected) => {
    setIsLoading(true)
    let preparationResult = mapFilterOptions(dataList, propertySelected, visibleTxtProp, objectContainerOfPropertySelected);
    stateSetter([...preparationResult])
    setIsLoading(false)
  }

  const multiplyParams = (keyString, valuesArr) => {
    let multipliedParams = valuesArr.map((obj) => { return `${keyString}${obj.value}` });
    let result = multipliedParams.join("");
    return result
  }

  const filterOrders = (e, searchValue) => {
    e.preventDefault();
    let paramsComposition = [];
    //date
    paramsComposition.push(`&dateFrom=${dateFilterFrom ? dateFilterFrom : "1970-01-01T00:00:00Z"}&dateTo=${dateFilterTo ? dateFilterTo : DateTime.now().toUTC().endOf("day")}`);
    //status
    if (statusFilterSelected.length === 0 || statusFilterSelected.length === statusOptions.length) {
      paramsComposition.push("&status=all")
    } else {
      let multipleParams = multiplyParams("&status=", statusFilterSelected);
      paramsComposition.push(multipleParams)
    }
    //delivery
    if (deliveryServiceFilterSelected.length === 0 || deliveryServiceFilterSelected.length === deliveryServiceFilterOptions.length) {
      paramsComposition.push("&delivery=all")
    } else {
      let multipleParams = multiplyParams("&delivery=", deliveryServiceFilterSelected);
      paramsComposition.push(multipleParams)
    }
    //store
    if (storeFilterSelected.length === 0 || storeFilterSelected.length === storeFilterOptions.length) {
      paramsComposition.push("&store=all")
    } else {
      let multipleParams = multiplyParams("&store=", storeFilterSelected);
      paramsComposition.push(multipleParams)
    }
    let settedTag = searchValue==="" ? "" : searchTag
    let finalFilterParams = paramsComposition.join("");
    paramsComposition = [];
    setFilterQuery(finalFilterParams);
    updateTable(auth?.user?.storeId, paginationFrom, pageSize, finalFilterParams, -1, settedTag);
  }

  const cleanupFilters = () => {
    setStatusFilterSelected([]);
    setDateFilterSelected("");
    setDateFilterFrom("");
    setDateFilterTo("");
    setStoreFilterSelected([]);
    setDeliveryServiceFilterSelected([]);
  }

  const cleanupSearchTag = () => {
    setSearchTag("");
  }

  let filtersProps = {
    setIsFilterDropdownShown,
    statusOptions,
    statusFilterSelected,
    setStatusFilterSelected,
    dateInput,
    dateOptions,
    dateFilterSelected,
    setDateFilterSelected,
    setDateFilterFrom,
    setDateFilterTo,
    storeFilterOptions,
    storeFilterSelected,
    setStoreFilterSelected,
    deliveryServiceFilterOptions,
    deliveryServiceFilterSelected,
    setDeliveryServiceFilterSelected,
    isFilterDropdownShown,
    cleanupFilters,
    searchTag,
    setSearchTag,
    filterOrders,
    ecommerce
  };

  return (
    <div id='fullgrid'>
      <div className='topBar-container'>
        <div className='d-flex align-items-center'>
          <SearchAndFilters {...filtersProps} />
        </div>
        <button className='update-btn'
          onClick={() => { updateTable(auth?.user?.storeId, paginationFrom, pageSize, `&dateFrom=1970-01-01T00:00:00Z&dateTo=${DateTime.now().toUTC().endOf("day")}&status=all&delivery=all&store=all`, -1, ""); cleanupFilters(); cleanupSearchTag() }}
        >
          <img src={refresh} alt="refresh" />
        </button>
      </div>

      {isLoading ?
        <>
          <Skeleton count={1} height={70} style={{ marginBottom: "10px" }} />
          <Skeleton count={1} height={30} style={{ marginBottom: "10px" }} />
          <Skeleton count={4} height={50} style={{ marginBottom: "10px" }} />
        </>
        :
        <div className='table-container mt-3' onClick={() => { setIsFilterDropdownShown(false) }}>

          <div className='table-grid'>
            <TableHeader setIsReferenceModalShown={setIsReferenceModalShown} />
            <div className='table-body'>
              {showEmptyMsg && <div className="empty-msg"><p>No hay órdenes para mostrar</p></div>}
              {data.map((itemInfo, index) => (
                <GridRow key={`row-${index}`} orderInfo={itemInfo} updateTable={updateTable} rowIndex={index} isWaiting={setIsLoaderModalShown} pageSize={pageSize} />
              ))}
            </div>
          </div>
          {isReferenceModalShown &&
            <ReferenceModal handleClose={() => setIsReferenceModalShown(false)} />
          }
        </div>
      }
    </div>
  );
}