import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { changeShopifyName, noSpacedNameShortener, validateFullAddress } from "../../../services/shared/utils.service";
import { InputsCheckbox } from "../../units/Inputs";
import { FiChevronRight, FiMinus, FiAlertCircle } from "react-icons/fi";
import { MdStorefront } from "react-icons/md";
import { customerRouteName } from '../../../data/InfoSource';

export const LocationItem = ({ index, value, ecommerce, id, checked, handleChange, insidePanel, ...location }) => {
  const [isConfigurated, setIsConfigurated] = useState(false);
  const [ecommerceConfigured, setEcommerceConfigured] = useState(false);

  useEffect(() => {
    if (location.countryCode && location.country && location.province && location.city && location.address && location.zip) {
      setEcommerceConfigured(true)
    } else {
      setEcommerceConfigured(false)
    }
    if (location.manager && location.phoneNumber && location.email) {
      setIsConfigurated(true)
    }
  }, [])

  return (
    <>
      {ecommerceConfigured || ecommerce !== "shopify" ?
        <div className="locationItem-grid mb-2">
          <InputsCheckbox
            id={id}
            value={value}
            checked={checked}
            onChangeFn={handleChange}
            labelTxt=""
          />
          <div className="address-wrapper">
            {/* {location.availableForDelivery &&
              <small className={isConfigurated ? "success" : ""}>{isConfigurated ? `Tienda configurada` : `Debes configurar tu tienda`}</small>
            } */}
            <div className={`loc-icon-wrapper ${checked===true ? "checked" : ""}`}>
              <MdStorefront size={20} />
            </div>
            <div className="address-text">
              <h4 className={`form-check-label ${checked ? "checked" : ""}`}>{location.name ? noSpacedNameShortener(location.name, 30) : `Tienda ${index}`}</h4>
              <h3>{validateFullAddress([location.address, location.city, location.country])}</h3>
            </div>
          </div>
          {location.availableForDelivery &&
            <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list/${id}`} className="step-subtitle">Configurar <FiChevronRight /></Link>
          }
        </div>
        :
        <div className="locationItem-grid warning mb-2">
          <div className="noSwitch">
            <FiAlertCircle size={20} />
            {/* <h4 className="form-check-label label-bold">{location.name ? noSpacedNameShortener(location.name, 10) : `Tienda ${index}`}</h4> */}
          </div>
          <div className="address-wrapper">
            <div className="loc-icon-wrapper">
              <MdStorefront size={20} />
            </div>
            <div className="address-text">
              <small>Tienda no configurada en Shopify</small>
              <h3 className="step-subtitle">{validateFullAddress([location.address, location.city, location.country])}</h3>
            </div>
          </div>
          <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/create-center/${id}`} className="step-subtitle">Configurar <FiChevronRight /></Link>
        </div>
      }
    </>
  );
};

export const LocationItemError = ({ index, ecommerce, id, insidePanel, ...location }) => {
  const [ecommerceConfigured, setEcommerceConfigured] = useState(false);

  useEffect(() => {
    if (location.countryCode && location.country && location.province && location.city && location.address && location.zip) {
      setEcommerceConfigured(true)
    } else {
      setEcommerceConfigured(false)
    }
  }, [])

  return (
    <>
      {ecommerceConfigured || ecommerce !== "shopify" ?
        <div className="locationItem-grid danger mb-2">
          <div className="noSwitch">
            <></>
          </div>
          <div className="address-wrapper">
            <div className="loc-icon-wrapper">
              <MdStorefront size={20} />
            </div>
            <div className="address-text">
              <small>{location.name ? noSpacedNameShortener(location.name, 30) : `Tienda ${index}`}</small>
              <h3>{validateFullAddress([location.address, location.city, location.country])}</h3>
            </div>
          </div>
          <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/location-list/${id}`} className="step-subtitle">Ver <FiChevronRight /></Link>
        </div>
        :
        <div className="locationItem-grid warning mb-2">
          <div className="noSwitch">
            <></>
          </div>
          <div className="address-wrapper">
            <div className="loc-icon-wrapper">
              <MdStorefront size={20} />
            </div>
            <div className="address-text">
              <small>Tienda no configurada en Shopify</small>
              <h3 className="step-subtitle">{validateFullAddress([location.address, location.city, location.country])}</h3>
            </div>
          </div>
          <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}${insidePanel ? '/panel' : ""}/create-center/${id}`} className="step-subtitle">Configurar <FiChevronRight /></Link>
        </div>
      }
    </>
  );
};
