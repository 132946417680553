import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router";
import queryString from 'query-string';
import { Route, Switch } from "react-router-dom";
import { capitalizeFirstLetter, changeShopifyName, setEcommerceURL } from '../../../services/shared/utils.service';
import { useAuthStore, useonboardingStore } from '../../../store';
import { customerName } from '../../../data/InfoSource';
import { FiChevronsRight, FiChevronsLeft } from "react-icons/fi";
import { PanelMainMenu } from '../../../components/panel/PanelMainMenu';
import { LogoContainerShopify } from '../../../components/units/LogoContainer';
import { LocationsConfig } from '../../../components/onboarding/locationConfig/LocationsConfig';
import { CreateNewCenter } from '../../../components/onboarding/locationConfig/CreateNewCenter';
import { DeliverySelectionForm } from '../../../components/onboarding/DeliverySelectionForm';
import { LocationSinglePanel } from '../../../pages/panel/LocationSinglePanel';
import { Products } from '../../../pages/panel/Products';
import { CreateCenterPanel } from '../../../pages/panel/bigcommerce-only/CreateCenterPanel';
import { Orders } from '../../../pages/panel/Orders';
import { WaybillConfiguration } from '../../../pages/panel/WaybillConfiguration';
import { EmbeddedDeliveryConfig } from '../../../components/onboarding/EmbeddedDeliveryConfig';
import { getInitialState } from '../../../services/onboarding.service';
import { showError } from '../../../services/shared/alert.service';
import { LogOutModal } from '../../../components/panel/LogOutModal';

export default function PanelRoute({ rootCustomerPath }) {
  const location = useLocation();
  const history = useHistory();
  const [ecommerce, setEcommerce] = useState(setEcommerceURL(location));
  const user = useAuthStore((state) => state.authData.user);
  const removeAuth = useAuthStore((state) => state.removeAuthData);
  const removeData = useonboardingStore((state) => state.removeOnboardingData);
  const addAuth = useAuthStore((state) => state.addAuthData);
  const tokenForEmbedded = useAuthStore((state) => state?.authData?.user?.tokenForEmbedded);
  const idCode = useAuthStore((state) => state?.authData?.user?.idCode);
  const accessToken = useAuthStore((state) => state?.authData?.user?.accessToken);
  const [isCondensed, setIsCondensed] = useState(false);
  const [isLogoutModalShown, setIsLogoutModalShown] = useState(false);

  const { shop = "" } = queryString.parse(location.search);

  useEffect(() => {
    if (window.innerWidth < 1280) {
      setIsCondensed(true)
    } else {
      setIsCondensed(false)
    }
  }, [])

  useEffect(() => {
    if (user && user?.storeId && shop) {
      if (shop && user?.storeId !== shop) {
        removeAuth();
        // localStorage.clear();
      }
    }
    document.title = `${customerName} - ${capitalizeFirstLetter(ecommerce)}`
  }, [user, shop, ecommerce, removeAuth]);

  useEffect(() => {
    if (ecommerce !== "vtex" && shop) {
      getInitialInfo(shop);
    } else {
      addAuth({
        user: {
          ...user,
          storeId: shop || "",
          ecommerce: ecommerce,
          insidePanel: true,
        },
      })
    }
  }, [])

  const getInitialInfo = async (shopIdentifier) => {
    try {
      const result = await getInitialState(shopIdentifier);
      if (result && result.success) {
        addAuth({
          user: {
            ...user,
            storeId: shop || "",
            ecommerce: ecommerce,
            insidePanel: true,
            tokenForEmbedded: result?.data?.token || "",
            idCode: result?.data?.shop_id || "",
            accessToken: result?.data?.access_token || "",
          },
        });
      }
    } catch (error) {
      showError(error)
    }
  }

  const logout = () => {
    removeData();
    removeAuth();
    history.push(`/${rootCustomerPath}/vtex/sign-in`);
  }

  return (
    <div className="container-fluid" id="panel-m">
      <div className="row row-grid">
        <div className='col-12'>
          <div className={`main-nav ${isCondensed ? "condensed" : ""}`}>
            <div className='action-condensedBtn'>
              <button
                type='button'
                onClick={() => setIsCondensed(!isCondensed)}
              >
                {isCondensed ? <FiChevronsRight /> : <FiChevronsLeft />}
              </button>
            </div>
            {<LogoContainerShopify ecommerce={ecommerce} isCondensed={isCondensed} />}
            <PanelMainMenu ecommerce={ecommerce} isCondensed={isCondensed} showLogoutModal={() => setIsLogoutModalShown(true)} />
          </div>
          <LogOutModal show={isLogoutModalShown} handleClose={() => setIsLogoutModalShown(false)} handleLogOut={() => logout()} />
          <div className="content-wrapper">
            <Switch>
              <Route path={`/${rootCustomerPath}/${changeShopifyName(ecommerce)}/panel/order`} render={() => <Orders ecommerce={ecommerce} shop={shop} />} />

              <Route exact path={`/${rootCustomerPath}/secommerce/panel/location-list`}> <LocationsConfig /></Route>
              <Route exact path={`/${rootCustomerPath}/secommerce/panel/location-list/:id`}> <LocationSinglePanel /></Route>
              <Route exact path={`/${rootCustomerPath}/secommerce/panel/create-center/:id`}> <CreateCenterPanel /></Route>
              <Route exact path={`/${rootCustomerPath}/secommerce/panel/delivery-services`} render={() => <EmbeddedDeliveryConfig ecommerce={ecommerce} token={tokenForEmbedded} idCode={idCode} />} />
              <Route exact path={`/${rootCustomerPath}/secommerce/panel/products`}> <Products /></Route>
              <Route exact path={`/${rootCustomerPath}/secommerce/panel/waybill-config`}> <WaybillConfiguration /></Route>

              <Route exact path={`/${rootCustomerPath}/vtex/panel/location-list`}> <LocationsConfig /></Route>
              <Route exact path={`/${rootCustomerPath}/vtex/panel/location-list/:id`}> <LocationSinglePanel /></Route>
              <Route exact path={`/${rootCustomerPath}/vtex/panel/create-center`}> <CreateNewCenter /></Route>
              <Route exact path={`/${rootCustomerPath}/vtex/panel/delivery-services`} render={() => <DeliverySelectionForm ecommerce={ecommerce} />} />
              <Route exact path={`/${rootCustomerPath}/vtex/panel/waybill-config`}> <WaybillConfiguration /></Route>

              <Route exact path={`/${rootCustomerPath}/bigcommerce/panel/location-list`}> <CreateCenterPanel /></Route>
              <Route exact path={`/${rootCustomerPath}/bigcommerce/panel/delivery-services`} render={() => <EmbeddedDeliveryConfig ecommerce={ecommerce} token={tokenForEmbedded} idCode={idCode} />} />
              <Route exact path={`/${rootCustomerPath}/bigcommerce/panel/waybill-config`}> <WaybillConfiguration /></Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
