import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ButtonSmall } from '../units/Button';
import { motion } from 'framer-motion';
import { fadeInAndUp, fadeInAndUpNoDelay, growBtn } from '../../services/shared/animation.service';
import { changeShopifyName } from '../../services/shared/utils.service';
import { useAuthStore } from '../../store';
import { customerRouteName } from '../../data/InfoSource';
import { showError, showSuccess } from '../../services/shared/alert.service';
import { getDeliveryService, saveDeliveryService } from '../../services/onboarding.service';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { PolicyCard } from './PolicyCard';
import { SizePerPageSetter } from '../panel/grid/SizePerPageSetter';
import { PageInfo } from '../panel/grid/PageInfo';
import { Pagination } from '../../components/panel/grid/Pagination';
import { FiSearch } from "react-icons/fi";

const sizePerPageOptions = [
    {
        name: "6",
        value: 6
    },
    {
        name: "10",
        value: 10
    },
    {
        name: "20",
        value: 20
    },
    {
        name: "40",
        value: 40
    },
    {
        name: "50",
        value: 50
    },
    {
        name: "100",
        value: 100
    },
];

export const DeliverySelectionForm = ({ ecommerce }) => {
    let history = useHistory();
    const sizeInput = useRef();
    const user = useAuthStore((state) => state.authData.user);
    const [isLoading, setIsLoading] = useState(true);
    const nextRouteParamSuccess = user?.insidePanel ? "panel/delivery-services" : "finish";
    const nextRouteParamError = user?.insidePanel ? "panel/delivery-services" : "finish-error";

    const [body, setBody] = useState([]);
    const [filteredBody, setFilteredBody] = useState([]);
    const [isOneSelected, setIsOneSelected] = useState(false);

    const [searchTag, setSearchTag] = useState("");
    const [isReachMinCharsAmount, setIsReachMinCharsAmount] = useState(true);

    const [pageSize, setPageSize] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        updateServices();
    }, [])

    useEffect(() => {
        checkValidation();
    }, [body])

    const updateServices = async () => {
        try {
            setIsLoading(true)
            let result = await getDeliveryService(user.storeId);
            if (result && result.success) {
                setBody(result.deliveryServices);
                setFilteredBody(result.deliveryServices);
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false)
        } catch (error) {
            showError(error)
            setIsLoading(false)
        }
    }

    const checkValidation = () => {
        let allServicesArr = [];
        body.forEach(listItem => allServicesArr.push(...listItem?.services));
        if (allServicesArr.some((element) => element.isActive === true)) {
            setIsOneSelected(true)
        } else {
            setIsOneSelected(false)
        }
        return
    }

    const handleSearchFilter = () => {
        let filteredBySearchTag = body.filter((service) => ((service.provider).toLowerCase()).includes(searchTag.toLowerCase()));
        setFilteredBody(filteredBySearchTag);
    }

    const paginatedPolicies = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return filteredBody.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, filteredBody, pageSize]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchTag.length >= 3) {
            setIsReachMinCharsAmount(true);
            handleSearchFilter()
        } else {
            setIsReachMinCharsAmount(false);
        }
    }

    const handleSearchTagChange = (e) => {
        setSearchTag(e.target.value);
        if (e.target.value === "") {
            setIsReachMinCharsAmount(true);
            setFilteredBody(body);
        }
    }

    const setDeliveryService = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const result = await saveDeliveryService({ deliveryServices: body }, user.storeId);
            if (result && result.success) {
                showSuccess(result.message);
                history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextRouteParamSuccess}`)
            }
            if (result.error) {
                showError(result.error);
                history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextRouteParamError}`)
            }
            setIsLoading(false);
        } catch (error) {
            showError(error);
            history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextRouteParamError}`)
            setIsLoading(false);
        }
    }

    return (
        <div id="delivery-service-form" className='content-wrapper'>
            <motion.div variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                <h1 className="main-title">Políticas de envío</h1>
                <h2 className="main-subtitle mb-4 mb-md-5">Elige los servicios de envío que vas a utilizar. Ten en cuenta que estas políticas se crearán en Vtex.</h2>
            </motion.div>
            {(isLoading && body.length === 0) ?
                <>
                    <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                    <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                    <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                    <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                </>
                :
                <>
                    <motion.div className='policy-searchbox' variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                        <div className='d-flex align-items-center'>
                            <form className='search-wrapper' onSubmit={handleSearch}>
                                <FiSearch size={20} />
                                <input onChange={handleSearchTagChange} value={searchTag} type="text" name="q" id="search-input" placeholder='Buscar paquetería...' />
                                {searchTag && <button type='button' onClick={() => { setSearchTag(""); setIsReachMinCharsAmount(true); setFilteredBody(body) }} className="close-btn">X</button>}
                            </form>
                        </div>
                        {!isReachMinCharsAmount && <span className="invalid-msg">Escribe al menos 3 caracteres</span>}
                    </motion.div>
                    <form onSubmit={setDeliveryService}>
                        <motion.div className="form-grid-two-columns" variants={fadeInAndUp} initial="hidden" animate="visible">

                            {paginatedPolicies.map((policy, index) => (
                                <PolicyCard key={`policy-${index}`} info={policy} setFullList={setBody} fullList={body} setFilteredList={setFilteredBody} filteredList={filteredBody} objIndex={index} />
                            ))}

                        </motion.div>
                        <div className={`bottomPage ${!user?.insidePanel ? "doubleBtn" : ""}`}>
                            {!user?.insidePanel &&
                                <motion.div variants={growBtn} initial="hidden" animate="visible">
                                    <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}/location-list`} className="btn-link">Regresar</Link>
                                </motion.div>
                            }
                            <motion.div variants={growBtn} initial="hidden" animate="visible">
                                <ButtonSmall
                                    type="submit"
                                    btnTxt={!user?.insidePanel ? "Continuar" : "Guardar"}
                                    showSpinner={isLoading}
                                    isDisabled={isOneSelected ? false : true}
                                />
                            </motion.div>
                        </div>
                    </form>
                    <motion.div id="sizePerPage-pageInfo-pagination-container" variants={fadeInAndUp} initial="hidden" animate="visible">
                        <SizePerPageSetter sizeOptions={sizePerPageOptions} currentSize={pageSize} setSize={setPageSize} sizeRef={sizeInput} titleTxt="Paqueterías por página" />
                        <PageInfo currentPage={currentPage} pageSize={pageSize} totalAmount={filteredBody.length} />
                        <Pagination
                            className="pagination-bar"
                            siblingCount={2}
                            currentPage={currentPage}
                            totalCount={filteredBody.length}
                            pageSize={pageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </motion.div>
                </>
            }
        </div>
    )
}
