import { mockedOrders, mockedProductPackage, mockedProductWaybillClass, mockQuotation, mockedProductsList } from '../data/mockData';
import { post as restPost, get as restGet, put as restPut } from '../services/shared/rest.service';
import { DateTime } from "luxon";

//only for developing purposes
const devMode = false;

export const spanishStrings = {
    "allItemsAreSelected": "Todos seleccionados",
    "clearSearch": "Limpiar búsqueda",
    "clearSelected": "Limpiar",
    "noOptions": "Vacío",
    "search": "Buscar",
    "selectAll": "Seleccionar todos",
    "selectAllFiltered": "Seleccionar todos (filtrados)",
    "selectSomeItems": "Seleccionar...",
    "create": "Crear",
}

export const getOrders = async (storeId = "", from = 0, limit = 5, filterParams = `&dateFrom=1970-01-01T00:00:00Z&dateTo=${DateTime.now().toUTC().endOf("day")}&status=all&delivery=all&store=all`, sort = -1, tag = "" ) => {
    if (!devMode) {
        try {
            const endpoint = `/panel/orders?limit=${limit}&from=${from}&shop=${storeId}&sort=${sort}${filterParams}&tag=${tag}`;
            const result = await restGet(endpoint);
            return result.data;
        } catch (error) {
            throw error.message
        }
    } else {
        return mockedOrders
    }
};

// Order actions
export const createOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/panel/fulfill-orders?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const deleteOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/panel/cancel-orders?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const cancelOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/panel/cancel-deliveries?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const retryOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/panel/repeat-orders?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

export const restoreSingleOrder = async (ordersArr, storeId = "") => {
    try {
        const endpoint = `/panel/restore-orders?shop=${storeId}`;
        const result = await restPost(endpoint, ordersArr);

        return result.data;
    } catch (error) {
        throw error.message
    }
};

//client's info edition
export const saveUserData = async (params, storeId = "") => {
    try {
        const endpoint = `/panel/user-data?shop=${storeId}`;
        const result = await restPut(endpoint, params);
        return result.data;

    } catch (error) {
        throw error.message
    }
}

// grid delivery service selection modal
export const getQuotationInfo = async (storeId = "", orderId) => {
    if (!devMode) {
        try {
            const endpoint = `/panel/delivery-services?shop=${storeId}&orderId=${orderId}`;
            const result = await restGet(endpoint);

            return result.data;
        } catch (error) {
            throw error.message
        }
    } else {
        return mockQuotation
    }
}

export const saveQuotationInfo = async (params, storeId = "") => {
    try {
        const endpoint = `/panel/delivery-services?shop=${storeId}`;
        const result = await restPut(endpoint, params);
        return result.data;

    } catch (error) {
        throw error.message
    }
}

//waybill screen and grid package modal
export const getWaybillClasses = async (storeId = "", tag = "", page = "1", pageSize = "20" ) => {
    if (!devMode) {
        try {
            const endpoint = `/panel/classes?shop=${storeId}&search=${tag}&page_number=${page}&page_size=${pageSize}`;
            const result = await restGet(endpoint);

            return result.data;
        } catch (error) {
            throw error.message
        }
    } else {
        return mockedProductWaybillClass
    }
}

export const getWaybillPackages = async (storeId = "") => {
    if (!devMode) {
        try {
            const endpoint = `/panel/packaging?shop=${storeId}`;
            const result = await restGet(endpoint);

            return result.data;
        } catch (error) {
            throw error.message
        }
    } else {
        return mockedProductPackage
    }
}

export const saveWaybillConfiguration = async (params, storeId = "") => {
    try {
        const endpoint = `/panel/waybill-configuration?shop=${storeId}`;
        const result = await restPut(endpoint, params);
        return result.data;

    } catch (error) {
        throw error.message
    }
}

export const saveSingleOrderWaybillConfig = async (params, storeId = "") => {
    try {
        const endpoint = `/panel/waybill-configuration-order?shop=${storeId}`;
        const result = await restPut(endpoint, params);
        return result.data;

    } catch (error) {
        throw error.message
    }
}

//products screen
export const getProductsList = async (storeId = "", tag = "") => {
    if (!devMode) {
        try {
            const endpoint = `/panel/products?shop=${storeId}&tag=${tag}`;
            const result = await restGet(endpoint);

            return result.data;
        } catch (error) {
            throw error.message
        }
    } else {
        return mockedProductsList
    }
}

export const saveProductsList = async (params, storeId = "") => {
    try {
        const endpoint = `/panel/products?shop=${storeId}`;
        const result = await restPost(endpoint, params);
        return result.data;

    } catch (error) {
        throw error.message
    }
}
