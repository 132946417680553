import { maxInputLength } from '../../data/InfoSource';

//Formatters

export const setEcommerceURL = (location) => {
  let ecommerce = "";
  if (location.pathname.includes("vtex")) {
    ecommerce = "vtex";
  } else if (location.pathname.includes("secommerce")) {
    ecommerce = "shopify";
  } else {
    ecommerce = "bigcommerce";
  }
  return ecommerce;
}

export const changeShopifyName = (ecommerce) => {
  if (ecommerce === "shopify") {
    return "secommerce"
  } else {
    return ecommerce
  }
}

export const capitalizeFirstLetter = (string) => {
  let normalizeWord = string.toString().toLowerCase();
  if (normalizeWord === "vtex") {
    return "VTEX"
  } else if (normalizeWord === "99MINUTOS") {
    return "99minutos"
  } else {
    return normalizeWord.charAt(0).toUpperCase() + normalizeWord.slice(1);
  }
}

export const plusFormatterPhoneNumber = (plainNum) => {
  const regex = /\+{1,}/gi;
  if (!plainNum) return
  if (regex.test(plainNum)) {
    let cleaner = plainNum.replace(regex, "").trim();
    return `+${cleaner}`
  }
  return `+${plainNum}`
}

export const mapFee = (valueNumber) => {
  let separatedValues = {};
  if (valueNumber.toString().includes("-")) {
    const splited = valueNumber.toString().split("");
    const operator = splited.shift();
    const number = splited.join("");
    separatedValues = { operator, number }
  } else {
    separatedValues = { operator: '+', number: valueNumber }
  }
  return separatedValues
}

export const noSpacedNameShortener = (nameString, finalSliceIndex) => {
  if (!nameString.includes(" ") && nameString.length > 10) {
    return `${nameString.slice(0, finalSliceIndex)}...`
  }
  return nameString
}

export const nameShortener = (nameString, maxCharsRequired = 10, finalSliceIndex = 10) => {
  if (String(nameString).length > maxCharsRequired) {
    return `${nameString.slice(0, finalSliceIndex)}...`
  }
  return nameString
}

// Validations

export const validateFullInfoComplete = (dataArr, flagSetter) => {
  const isMissingData = dataArr.some(arrItem => !arrItem || arrItem.toString().includes("null"))
  if (isMissingData) {
    flagSetter(false)
  } else {
    flagSetter(true)
  }
}

export const validateEmail = (email) => {
  const rgx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
  return rgx.test(String(email).toLowerCase());
}

export const validatePhone = (phoneNumber) => {
  const re = /(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})/gm;
  return re.test(phoneNumber);
}

export const validateLength = (inputData, maxChars = maxInputLength) => {
  if (String(inputData).length <= maxChars) return true
}

export const checkBiggerNum = (from, to) => {
  if (Number(from) <= Number(to)) return true
}

export const checkPercentageLimits = (value) => {
  if (Number(value) >= -100 && Number(value) <= 100) return true
}

export const validateFullAddress = (dataArr, failedMsg = "- Dirección incompleta -") => {
  const isMissingData = dataArr.some(geoInfo => !geoInfo || geoInfo.toString().includes("null"))
  if (isMissingData) {
    return failedMsg
  } else {
    return dataArr.join(", ")
  }
}

export const validateInputs = (dataObj, setErrorsMethod) => {
  let errors = {};

  for (const property in dataObj) {

    if (!validateLength(dataObj[property])) {
      errors[property] = `No debe superar los ${maxInputLength} caracteres`
    }
    if (property === "email" && dataObj.email && validateLength(dataObj.email)) {
      if (!validateEmail(dataObj.email)) {
        errors.email = "El formato de email es incorrecto"
      }
    }
    if (property === "phoneNumber" && dataObj.phoneNumber && validateLength(dataObj.phoneNumber)) {
      if (!validatePhone(dataObj.phoneNumber)) {
        errors.phoneNumber = "Formatos ejemplo: 11234567890, 123-456-7890, (123) 456-7890, +1 123.456.7890, +91 (123) 456-7890"
      }
    }
    if (property === "to" && dataObj.to && validateLength(dataObj.to)) {
      if (!checkBiggerNum(dataObj.from, dataObj.to)) {
        errors.to = "El segundo día debe ser igual o mayor al primero"
      }
    }
    if (property === "fee" && dataObj.typeOfFee === "adjusted") {
      if (!checkPercentageLimits(dataObj.fee)) {
        errors.fee = "Debe ser mayor a -100% y menor a 100%"
      }
    }
    if (property === "promoDynFeeAmount") {
      if (!checkPercentageLimits(dataObj.promoDynFeeAmount)) {
        errors.promoDynFeeAmount = "Debe ser mayor a -100% y menor a 100%"
      }
    }
  }
  setErrorsMethod(errors);

  if (Object.keys(errors).length === 0) {
    return true;
  } else {
    return false;
  }
};

// Others

export function deepCopy(src) {
  let target = Array.isArray(src) ? [] : {};
  for (let key in src) {
    let v = src[key];
    if (v) {
      if (typeof v === "object") {
        target[key] = deepCopy(v);
      } else {
        target[key] = v;
      }
    } else {
      target[key] = v;
    }
  }

  return target;
}

export const getCountryCoords = (countryName) => {
  const countriesCoords = {
    "Argentina": { lat: -34.61315, lng: -58.37723 },
    "España": { lat: 40.4165, lng: -3.70256 },
  }
  return countriesCoords[countryName];
};