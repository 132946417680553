
export const getBaseUrl = (host) => {
  const {
    REACT_APP_BASE_URL_VTEX,
    REACT_APP_BASE_URL_SHOPIFY,
    REACT_APP_BASE_URL_BIGCOMMERCE,
    REACT_APP_BASE_URL
  } = process.env;

  let baseUrl = '';
  const api = '/api/v1';

  if (host.includes('vtex')) {
    baseUrl = REACT_APP_BASE_URL_VTEX;
  }

  if (host.includes('ecommerce')) {
    baseUrl = REACT_APP_BASE_URL_SHOPIFY;
  }

  if (host.includes('bigcommerce')) {
    baseUrl = REACT_APP_BASE_URL_BIGCOMMERCE;
  }

  if (host.includes('localhost')) {
    baseUrl = REACT_APP_BASE_URL;
  }

  return `${baseUrl}${api}`;
}