import React from 'react';
import { nameShortener } from '../../../services/shared/utils.service';
import { InputsSimple } from '../../units/Inputs';

export const ProductsGridRow = ({ productData, handleChange }) => {

    return (
        <ul className="product-row">
            <li className='cell-info'>
                <p>{nameShortener(productData.title, 58, 58)}</p>
                {(!productData.length && !productData.width && !productData.height) && <span className='badge-new mx-1'>Nuevo</span>}
            </li>
            <li className='cell-inputs'>
                <div className='input-wrapper-parent'>
                    <InputsSimple
                        value={String(productData.length || 0).replace(/^0+/, '')}
                        id="length"
                        type="number"
                        placeholder=""
                        labelTxt="Largo"
                        onChangeFn={(e) => handleChange(e, productData.shopifyProductId)}
                        errorTxt={false}
                        min={1}
                    />
                    <span className='input-wrapper-child'>cm</span>
                </div>
                <span className='black-semibold px-1'>X</span>
                <div className='input-wrapper-parent'>
                    <InputsSimple
                        value={String(productData.width || 0).replace(/^0+/, '')}
                        id="width"
                        type="number"
                        placeholder=""
                        labelTxt="Ancho"
                        onChangeFn={(e) => handleChange(e, productData.shopifyProductId)}
                        errorTxt={false}
                        min={1}
                    />
                    <span className='input-wrapper-child'>cm</span>
                </div>
                <span className='black-semibold px-1'>X</span>
                <div className='input-wrapper-parent'>
                    <InputsSimple
                        value={String(productData.height || 0).replace(/^0+/, '')}
                        id="height"
                        type="number"
                        placeholder=""
                        labelTxt="Alto"
                        onChangeFn={(e) => handleChange(e, productData.shopifyProductId)}
                        errorTxt={false}
                        min={1}
                    />
                    <span className='input-wrapper-child'>cm</span>
                </div>
                <span className='black-semibold px-1'>X</span>
                <div className='input-wrapper-parent'>
                    <InputsSimple
                        value={productData.weight}
                        id="weight"
                        type="number"
                        placeholder=""
                        labelTxt="Peso"
                        onChangeFn={(e) => handleChange(e, productData.shopifyProductId)}
                        errorTxt={false}
                        min={0.01}
                    />
                    <span className='input-wrapper-child'>Kg</span>
                </div>
            </li>
        </ul>
    )
}
