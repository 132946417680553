import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ButtonSmall } from '../../units/Button';
import { InputsSimple, InputsTextarea } from '../../units/Inputs';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { useAuthStore, useonboardingStore } from '../../../store';
import { changeShopifyName, noSpacedNameShortener, validateFullAddress, validateInputs } from '../../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { customerRouteName } from '../../../data/InfoSource';
import { childVariants, fadeInAndUp, fadeInAndUpNoDelay, growBtn } from '../../../services/shared/animation.service';
import { saveLocationsData } from '../../../services/onboarding.service';

export const LocationForm = ({ ecommerce, singleLocationIndex, allLocations, ...singleLocation }) => {
    let history = useHistory();
    const user = useAuthStore((state) => state.authData.user);
    const store = useonboardingStore((state) => state.onboardingData.store);
    const addData = useonboardingStore((state) => state.addData);
    const generalSwitch = useonboardingStore((state) => state.onboardingData.store?.activeAllSwitch);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [currentLocations, setCurrentLocations] = useState(allLocations);
    const nextRouteParam = user?.insidePanel ? "panel/location-list" : "location-list";

    const [body, setBody] = useState({
        name: singleLocation?.name,
        availableForDelivery: true,
        inCoverage: singleLocation?.inCoverage,
        ecommerceConfigured: singleLocation?.ecommerceConfigured,
        locationId: singleLocation?.locationId,
        country: singleLocation?.country,
        countryCode: singleLocation?.countryCode,
        country_code: singleLocation?.country_code || "",
        province: singleLocation?.province,
        city: singleLocation?.city,
        address: singleLocation?.address,
        zip: singleLocation?.zip,
        manager: singleLocation?.manager || "",
        phoneNumber: singleLocation?.phoneNumber || "",
        email: singleLocation?.email || "",
        notes: singleLocation?.notes || "",
    })

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: e.target.value });
    }

    const setConfiguration = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            setCurrentLocations(currentLocations[singleLocationIndex] = body);
            try {
                const result = await saveLocationsData({ locations: currentLocations }, user.storeId);
                if (result && result.success) {
                    addData({
                        store: {
                            ...store,
                            activeAllSwitch: generalSwitch
                        },
                    });
                    showSuccess(result.message);
                    history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextRouteParam}`)
                }
                if (result.error) {
                    showError(result.error);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                showError(error);
            }
        } else {
            return
        }
    }

    return (
        <div id="location-form">
            <motion.div variants={fadeInAndUp} initial="hidden" animate="visible">
                <h1 className="main-title">{singleLocation.name ? noSpacedNameShortener(singleLocation.name, 30) : `Tienda ${singleLocationIndex + 1}`}</h1>
                <h2 className="main-subtitle mb-4 mb-md-5">{validateFullAddress([singleLocation.address, singleLocation.city, singleLocation.province, singleLocation.country])}</h2>
            </motion.div>
            <form onSubmit={setConfiguration}>
                <motion.div className="form-grid-two-columns" variants={user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp} initial="hidden" animate="visible">
                    <div className="d-flex flex-column justify-content-start">
                        <motion.div variants={childVariants}>
                            <InputsSimple
                                value={body.manager}
                                id="manager"
                                type="text"
                                labelTxt="Nombre de quien prepara los envíos*"
                                placeholder="Nombre y apellido"
                                onChangeFn={handleChange}
                                resultValidation={formErrors?.manager ? "is-invalid" : ""}
                                errorTxt={false}
                            />
                        </motion.div>
                        <motion.div variants={childVariants}>
                            <InputsSimple
                                value={body.email}
                                id="email"
                                type="text"
                                placeholder="email@example.com"
                                labelTxt="Email*"
                                onChangeFn={handleChange}
                                resultValidation={formErrors?.email ? "is-invalid" : ""}
                                errorTxt={false}
                            />
                        </motion.div>
                    </div>
                    <div className="d-flex flex-column justify-content-start">
                        <motion.div variants={childVariants}>
                            <InputsSimple
                                value={body.phoneNumber}
                                id="phoneNumber"
                                type="text"
                                placeholder=""
                                labelTxt="Teléfono*"
                                onChangeFn={handleChange}
                                resultValidation={formErrors?.phoneNumber ? "is-invalid" : ""}
                                errorTxt={formErrors.phoneNumber}
                            />
                        </motion.div>
                        <motion.div variants={childVariants}>
                            <InputsTextarea
                                value={body.notes}
                                id="notes"
                                labelTxt="Referencias adicionales"
                                onChangeFn={handleChange}
                                rows={1}
                                resultValidation={formErrors?.notes ? "is-invalid" : ""}
                                errorTxt={false}
                            />
                        </motion.div>
                    </div>
                </motion.div>
                <div className={`bottomPage doubleBtn`}>
                    <motion.div variants={growBtn} initial="hidden" animate="visible">
                        <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}/${nextRouteParam}`} className="btn-link">Regresar</Link>
                    </motion.div>

                    <motion.div variants={growBtn} initial="hidden" animate="visible">
                        <ButtonSmall
                            type="submit"
                            btnTxt="Continuar"
                            showSpinner={isLoading}
                            isDisabled={(body.email && body.phoneNumber && body.manager) ? false : true}
                        />
                    </motion.div>
                </div>
            </form>
        </div>
    )
}
