import { Route, Switch } from "react-router-dom";
import { SignIn } from "../../../pages/onboarding/vtex-only/SignIn";
import { VtexKeysConfig } from "../../../pages/onboarding/vtex-only/VtexKeysConfig";
import { Credentials } from "../../../pages/onboarding/Credentials";
import { LocationsList } from "../../../pages/onboarding/LocationsList";
import { CreateCenter } from "../../../pages/onboarding/vtex-only/CreateCenter";
import { LocationSingle } from "../../../pages/onboarding/LocationSingle";
import { DeliveryServices } from "../../../pages/onboarding/DeliveryServices";
import { FinishSuccess } from "../../../pages/onboarding/FinishSuccess";
import { FinishError } from "../../../pages/onboarding/FinishError";
import PanelRoute from "../panel/panelRoute";

export default function CustomerVTEXRoute({ rootCustomerPath }) {
  return (
    <>
      <Switch>
        <Route exact path={`/${rootCustomerPath}/vtex/sign-in`} component={SignIn} />
        <Route exact path={`/${rootCustomerPath}/vtex/vconfig`} component={VtexKeysConfig} />
        <Route exact path={`/${rootCustomerPath}/vtex/credentials`} component={Credentials} />
        <Route exact path={`/${rootCustomerPath}/vtex/location-list`} component={LocationsList} />
        <Route exact path={`/${rootCustomerPath}/vtex/location-list/:id`} component={LocationSingle} />
        <Route exact path={`/${rootCustomerPath}/vtex/create-center`} component={CreateCenter} />
        <Route exact path={`/${rootCustomerPath}/vtex/delivery-services`} component={DeliveryServices} />
        <Route exact path={`/${rootCustomerPath}/vtex/finish`} component={FinishSuccess} />
        <Route exact path={`/${rootCustomerPath}/vtex/finish-error`} component={FinishError} />

        <Route path={`/${rootCustomerPath}/vtex/panel`} render={() => <PanelRoute rootCustomerPath={rootCustomerPath} /> }/> 

        <Route exact path={`/${rootCustomerPath}/vtex`} component={SignIn} />
      </Switch>
    </>
  );
}
