import { post as restPost, get as restGet } from '../services/shared/rest.service';
import { mockedLocations, deliveryServices, mockedBCLocation, mockedinitialState } from '../data/mockData';

//only for developing purposes
const devMode = false;

//just for Vtex
export const signIn = async (params) => {
    try {
        const endpoint = `/auth/login`;
        const body = {
            vtexUrl: params.storeUrl,
            password: params.password,
        };
        const result = await restPost(endpoint, body);

        return result.data;
    } catch (error) {
        throw error.message
    }
}

export const vtexIntegration = async (apiKey, apiSecret, storeUrl) => {
    try {
        const endpoint = `/onboarding/vtex`;
        const body = {
            vtexKey: apiKey,
            vtexToken: apiSecret,
            vtexUrl: storeUrl
        };
        const result = await restPost(endpoint, body);

        return result.data;
    } catch (error) {
        throw error.message
    }
}

//shared endpoints
export const getInitialState = async (storeId) => {
    if (!devMode) {
        try {
            const endpoint = `/onboarding/initial-state?shop=${storeId}`;
            const result = await restGet(endpoint);

            return result.data;
        } catch (error) {
            throw error.message
        }
    } else {
        return mockedinitialState
    }
};

export const loginService = async (params, storeId, userId) => {
    try {
        const endpoint = `/onboarding/skydropx?shop=${storeId}`;
        const body = {
            apiKey: params.customerApiKey,
            password: params.password,
            countryCode: params.countryCode,
            userId: userId || ""
        };
        const result = await restPost(endpoint, body);
        return result.data;

    } catch (error) {
        throw error.message
    }
};

export const getLocations = async (storeId) => {
    if (!devMode) {
        try {
            const endpoint = `/onboarding/locations?shop=${storeId}`;
            const result = await restGet(endpoint);
            return result.data;

        } catch (error) {
            throw error.message
        }
    } else {
        return mockedLocations
    }
};

export const getSingleLocation = async (storeId) => {
    if (!devMode) {
        try {
            const endpoint = `/onboarding/locations?shop=${storeId}`;
            const result = await restGet(endpoint);
            return result.data;

        } catch (error) {
            throw error.message
        }
    } else {
        return mockedBCLocation
    }
};

export const createLocation = async (params, storeId = "") => {
    try {
        const endpoint = `/onboarding/location?shop=${storeId}`;
        const result = await restPost(endpoint, params);

        return result.data;
    } catch (error) {
        throw error.message
    }
}

export const saveLocationsData = async (params, storeId) => {
    try {
        const endpoint = `/onboarding/locations?shop=${storeId}`;
        const result = await restPost(endpoint, params);
        return result.data;

    } catch (error) {
        throw error.message
    }
};

export const getDeliveryService = async (storeId) => {
    if (!devMode) {
        try {
            const endpoint = `/onboarding/shipping-policies?shop=${storeId}`;
            const result = await restGet(endpoint);
            return result.data;

        } catch (error) {
            throw error.message
        }
    } else {
        return deliveryServices
    }
};

export const saveDeliveryService = async (params, storeId) => {
    try {
        const endpoint = `/onboarding/shipping-policies?shop=${storeId}`;
        const result = await restPost(endpoint, params);
        return result.data;

    } catch (error) {
        throw error.message
    }
};

// Others

export const checkConfiguration = (locationData, setAllActiveLocationsConfigurated, setbtnMessage) => {
    let activeLocations = locationData.filter(loc => loc.availableForDelivery === true);
    if (activeLocations && activeLocations.length !== 0) {
        if (activeLocations.length > 1) {
            if (activeLocations.every(loc => loc.manager && loc.phoneNumber && loc.email)) {
                setAllActiveLocationsConfigurated(true)
            } else {
                setAllActiveLocationsConfigurated(false)
                setbtnMessage(`Configura todas tus tiendas activas para continuar`)
            }
        } else {
            if (activeLocations[0]?.manager && activeLocations[0]?.phoneNumber && activeLocations[0]?.email) {
                setAllActiveLocationsConfigurated(true)
            } else {
                setAllActiveLocationsConfigurated(false)
                setbtnMessage(`Configura todas tus tiendas activas para continuar`)
            }
        }
    } else {
        setAllActiveLocationsConfigurated(false)
        setbtnMessage("Debes activar al menos una para continuar")
    }
}