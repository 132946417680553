import React, { useEffect } from 'react';

export const FulfillClientInputs = ({ inputsInfo, setInputsInfo, isEditionAvailable = false }) => {

    useEffect(() => {
        updateCountryName(inputsInfo?.countryCode);
    }, [inputsInfo.countryCode])
    
    const availableCountries = [
        {
            name: "México",
            value: "MX"
        },
        {
            name: "Colombia",
            value: "CO"
        }
    ];

    const handleChange = (e) => {
        setInputsInfo({ ...inputsInfo, [e.target.id]: (e.target.value) })
    }

    const updateCountryName = (countryCode) => {
        let countries = {
            MX: "México",
            CO: "Colombia"
        }
        setInputsInfo({ ...inputsInfo, country: countries[countryCode] })
    }

    const verifyIfHasErrors = (input) => {
        return inputsInfo.error.includes(input);
    }

    return (
        <>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="name" className="form-label">Nombre:</label>
                <input
                    type="text"
                    className={`form-control ${verifyIfHasErrors("name")===true ? "is-invalid-special" : ""}`}
                    id="name"
                    placeholder="Nombre y apellido"
                    onChange={handleChange}
                    value={inputsInfo.name}
                    disabled={isEditionAvailable ? false : true}
                />
            </div>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="email" className="form-label">Correo electrónico:</label>
                <input
                    type="text"
                    className={`form-control ${verifyIfHasErrors("email")===true ? "is-invalid-special" : ""}`}
                    id="email"
                    placeholder="Ej: example@email.com"
                    onChange={handleChange}
                    value={inputsInfo.email}
                    disabled={isEditionAvailable ? false : true}
                />
            </div>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="phoneNumber" className="form-label">Teléfono:</label>
                <input
                    type="text"
                    className={`form-control ${verifyIfHasErrors("phoneNumber")===true ? "is-invalid-special" : ""}`}
                    id="phoneNumber"
                    placeholder=""
                    onChange={handleChange}
                    value={inputsInfo.phoneNumber}
                    disabled={isEditionAvailable ? false : true}
                />
            </div>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="country" className={`form-label`}>País:</label>
                <select
                    className={`form-control ${verifyIfHasErrors("country")===true ? "is-invalid-special" : ""}`}
                    aria-label="country"
                    id="country"
                    onChange={handleChange}
                    disabled={isEditionAvailable ? false : true}
                >
                    {inputsInfo.countryCode ?
                        <option className="form-select-option" disabled></option>
                        :
                        <option className="form-select-option" selected disabled></option>
                    }
                    {availableCountries.map((item, index) =>
                        <option key={`${index}`} className="form-select-option" value={item.value} selected={item.value === inputsInfo.countryCode ? true : false} >{item.name}</option>
                    )}
                </select>

            </div>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="city" className="form-label">Ciudad:</label>
                <input
                    type="text"
                    className={`form-control ${verifyIfHasErrors("city")===true ? "is-invalid-special" : ""}`}
                    id="city"
                    placeholder=""
                    onChange={handleChange}
                    value={inputsInfo.city}
                    disabled={isEditionAvailable ? false : true}
                />
            </div>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="province" className="form-label">Estado:</label>
                <input
                    type="text"
                    className={`form-control ${verifyIfHasErrors("province")===true ? "is-invalid-special" : ""}`}
                    id="province"
                    placeholder=""
                    onChange={handleChange}
                    value={inputsInfo.province}
                    disabled={isEditionAvailable ? false : true}
                />
            </div>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="address" className="form-label">Calle y número:</label>
                <input
                    type="text"
                    className={`form-control ${verifyIfHasErrors("address")===true ? "is-invalid-special" : ""}`}
                    id="address"
                    placeholder=""
                    onChange={handleChange}
                    value={inputsInfo.address}
                    disabled={isEditionAvailable ? false : true}
                />
            </div>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="colony" className="form-label">Colonia</label>
                <input
                    type="text"
                    className={`form-control ${verifyIfHasErrors("colony")===true ? "is-invalid-special" : ""}`}
                    id="colony"
                    placeholder=""
                    onChange={handleChange}
                    value={inputsInfo.colony}
                    disabled={isEditionAvailable ? false : true}
                />
            </div>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="zip" className="form-label">Código postal:</label>
                <input
                    type="text"
                    className={`form-control ${verifyIfHasErrors("zip")===true ? "is-invalid-special" : ""}`}
                    id="zip"
                    placeholder=""
                    onChange={handleChange}
                    value={inputsInfo.zip}
                    disabled={isEditionAvailable ? false : true}
                />
            </div>
            <div className='input-wrapper-special py-1'>
                <label htmlFor="notes" className="form-label">Referencias:</label>
                <input
                    type="text"
                    className="form-control"
                    id="notes"
                    placeholder=""
                    onChange={handleChange}
                    value={inputsInfo.notes}
                    disabled={isEditionAvailable ? false : true}
                />
            </div>
        </>
    )
}
