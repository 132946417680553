import React from 'react';
import SelectSearch from 'react-select-search';
import dropdownFullArrow from "../../../assets/icons/dropdownFullArrow.svg";

export const SizePerPageSetter = ({ sizeOptions, currentSize, setSize, sizeRef, titleTxt = "Envíos por página" }) => {

    return (
        <>
            <p>{titleTxt}</p>
            <div className='sizePerPage-parent'>
                <SelectSearch
                    ref={sizeRef}
                    options={sizeOptions}
                    search={false}
                    value={currentSize}
                    name="sizePerPage"
                    placeholder=""
                    onChange={(e) => setSize(e)}
                    className="sizePerPage-select"
                />

                <button
                    type='button'
                    onClick={() => console.log("dropdown")}
                    className="sizePerPage-child"
                >
                    <img src={dropdownFullArrow} alt="package menu" />
                </button>
            </div>
        </>
    )
}
