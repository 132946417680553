import React from 'react';

export const AbstractSection = ({ orderData }) => {

    const calculateProductsSum = (objArr) => {
        return objArr.reduce((prev, curr) => { return prev + (curr.productPrice * curr.quantity) }, 0)
    }

    return (
        <div className='fulfill-body-module mt-0'>
            <h2 className='fulfill-body-mainTitle'>Orden</h2>
            <ul className='fulfill-product-list'>
                {orderData.products.map((item, index) => (
                    <li className='fulfill-product-item' key={`item-${index}`}>
                        <span className='limit-width lg'>x{item?.quantity || 1} {item?.productName}</span>
                        <span>${(item?.productPrice) * (item?.quantity || 1)}</span>
                    </li>
                ))}
            </ul>
            <hr />
            <div className='fulfill-product-cost'>
                <span>Subtotal</span>
                <span>${calculateProductsSum(orderData?.products)}</span>
            </div>
            <div className='fulfill-product-cost'>
                <span>Costo de envío</span>
                <span>${orderData?.deliveryService?.shippingCost}</span>
            </div>
            <div className='fulfill-product-cost'>
                <span>TOTAL</span>
                <span className='semibold large'>${(calculateProductsSum(orderData?.products) + Number(orderData?.deliveryService?.shippingCost)).toFixed(2)} {orderData?.deliveryService?.currency}</span>
            </div>
        </div>
    )
}
