import React, { useEffect, useState } from 'react';
import close from '../../../assets/icons/close.svg';
import magnifyingGlassIllustration from '../../../assets/magnifyingGlassIllustration.svg'
import { InputsRadioAsButton } from '../../units/Inputs';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { useAuthStore } from '../../../store';
import { getQuotationInfo, saveQuotationInfo } from '../../../services/panel.service';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const QuotationSetterModal = ({ orderData, handleClose, updateTable, isPackageSet, rowIndex, pageSize }) => {
    const user = useAuthStore((state) => state.authData.user);
    const [isLoading, setIsLoading] = useState(true);
    const [deliveryOptions, setDeliveryOptions] = useState([]);

    useEffect(() => {
        if(isPackageSet){
            getAndMapQuotationInfo();
        }
    }, [])

    const getAndMapQuotationInfo = async () => {
        setIsLoading(true);
        try {
            const result = await getQuotationInfo(user?.storeId, orderData.orderId);
            if (result && result.success) {
                let mappedOptions = result.deliveryServices.map((option, index) => { return { ...option, isSelected: false, id: index + 1 } });
                if (orderData?.deliveryService?.serviceLevelCode) {
                    let found = mappedOptions.find((el) => el.serviceLevelCode === orderData.deliveryService.serviceLevelCode);
                    if (found) {
                        found.isSelected = true;
                    }
                    setDeliveryOptions(mappedOptions);
                } else {
                    setDeliveryOptions(mappedOptions);
                }
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false);
        } catch (error) {
            showError(error);
            setIsLoading(false);
            handleClose();
        }
    }

    const setQuotationInfo = async (option) => {
        setIsLoading(true);
        try {
            const result = await saveQuotationInfo({
                selectedService: {
                    provider: option.provider,
                    serviceLevelName: option.serviceLevelName,
                    serviceLevelCode: option.serviceLevelCode,
                    estimatedTime: option.estimatedTime,
                    shippingCost: option.shippingCost,
                    currency: option.currency,
                },
                orderId: orderData.orderId
            },
                user?.storeId);
            if (result && result.success) {
                showSuccess(result?.message);
                setTimeout(() => {
                    updateTable(user?.storeId)
                }, 500);
            }
            if (result.error) {
                showError(result.error);
            }
            setIsLoading(false);
            handleClose();
        } catch (error) {
            showError(error);
            setIsLoading(false);
            handleClose();
        }
    }

    return (
        <div id='quotation-setter-modal' className={`${rowIndex + 1 === pageSize ? "last" : ""}`}>
            <div id="quotation-modal-header" className="border-0">
                <h2 className='quotation-setter-title'>Opciones de envío</h2>
                <button
                    type='button'
                    onClick={() => handleClose()}
                >
                    <img src={close} alt="close" />
                </button>
            </div>
            {!isPackageSet ?
                <div className='modal-body'>
                    <div className='not-found'>
                        <img src={magnifyingGlassIllustration} alt="not found" />
                        <p>Completa los datos del <span className='green-highlight'>Paquete</span> para cotizar tu envío.</p>
                    </div>
                </div>
                :
                <>
                    {!isLoading ?
                        <form className="modal-body radio-form">
                            {deliveryOptions.map((option, index) => (
                                <div key={`serviceMap-${index}`}>
                                    <InputsRadioAsButton
                                        id={option.id}
                                        name={option.id}
                                        value={option.id}
                                        children={<DeliveryRadioButtonContent itemValues={option} />}
                                        onChangeFn={() => setQuotationInfo(option)}
                                        checked={option.isSelected === true}
                                        disabled={false}
                                        extraClassName=""
                                    />
                                </div>
                            ))}
                        </form>
                        :
                        <Skeleton count={1} height={50} style={{ marginBottom: "8px" }} />
                    }
                </>
            }
        </div>
    )
}

const DeliveryRadioButtonContent = ({ itemValues }) => {
    return (
        <div className='delivery-radio-container'>
            <p className='radio-provider'>{itemValues.provider}</p>
            <div className='delivery-radio-description'>
                <div className='radio-item'>
                    <p className='secondary'>Servicio</p>
                    <p>{itemValues.serviceLevelName}</p>
                </div>
                <div className='radio-item'>
                    <p className='secondary'>Entrega estimada</p>
                    <p>{itemValues.estimatedTime} días hábiles</p>
                </div>
            </div>
            <p className='radio-total-price'>${Number(itemValues.shippingCost).toFixed(2)} {itemValues.currency}</p>
        </div>
    )
}