import circlePurple from '../assets/icons/circlePurple.svg';
import circleBlue from '../assets/icons/circleBlue.svg';
import circleGreen from '../assets/icons/circleGreen.svg';
import circlePink from '../assets/icons/circlePink.svg';
import circleYellow from '../assets/icons/circleYellow.svg';

export const statusTranslator = (statusName) => {
  const list = {
    //customer status
    "CREATING_LABEL": "Etiqueta en proceso",
    "FULFILLMENT": "Etiqueta creada",
    "CREATED": "Creado",
    "PICKED_UP": "Recolectado",
    "IN_TRANSIT": "En camino",
    "LAST_MILE": "Por llegar",
    "DELIVERED": "Entregado",
    "DELIVERY_ATTEMPT": "Con Incidencia",
    "EXCEPTION": "Por cancelar",
    "REVIEWING": "Por cancelar",
    //conexa's status:
    "PENDING": "Por crear",
    "CANCELLED": "Cancelado",
    "ERROR": "Con Problemas",
    "RESTORED": "Recuperado"
  }
  return list[statusName] || ""
}

export const colorSetter = (statusName) => {
  const list = {
    "Por crear": circlePurple,
    "Etiqueta en proceso": circlePurple,

    "Etiqueta creada": circleBlue,
    "Creado": circleBlue,
    "Recolectado": circleBlue,
    "En camino": circleBlue,
    "Por llegar": circleBlue,

    "Entregado": circleGreen,

    "Con Incidencia": circlePink,
    "Con Problemas": circlePink,

    "Por cancelar": circleYellow,
    "Cancelado": circleYellow,
    "Recuperado": circleYellow,
    "Por cancelar": circleYellow
  }
  return list[statusName] || ""
}

export const classColorSetter = (statusName) => {
  const list = {
    "PENDING": "purple",
    "CREATING_LABEL": "purple",

    "FULFILLMENT": "blue",
    "CREATED": "blue",
    "PICKED_UP": "blue",
    "IN_TRANSIT": "blue",
    "LAST_MILE": "blue",

    "DELIVERED": "green",

    "DELIVERY_ATTEMPT": "pink",
    "ERROR": "pink",

    "RESTORED": "yellow",
    "EXCEPTION": "yellow",
    "CANCELLED": "yellow",
    "REVIEWING": "yellow"
  }
  return list[statusName] || ""
}

export const dateFormatter = (dateInfo, isDateOfCreation = true) => {
  const dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "nomviembre", "diciembre"];
  const orderDate = new Date(dateInfo);
  if (isDateOfCreation) {
    return `${(dayNames[orderDate.getDay()]).slice(0, 2)} - ${orderDate.getDate()}/${(monthNames[orderDate.getMonth()]).slice(0, 3)}/${orderDate.getFullYear().toString().slice(2, 4)}`
  } else {
    return `Desde el ${(dayNames[orderDate.getDay()]).slice(0, 3).toLowerCase()} ${orderDate.getDate()}/${(monthNames[orderDate.getMonth()]).slice(0, 3)}/${orderDate.getFullYear().toString().slice(2, 4)}`
  }
}

export const packageSizeFormatter = (packageInfo = { width: 0, height: 0, length: 0 }, unit = "") => {
  if(unit){
    return `${packageInfo.width}${unit} x ${packageInfo.height}${unit} x ${packageInfo.length}${unit}`
  }
  return `${packageInfo.width}x${packageInfo.height}x${packageInfo.length}`
}

export const timeFormatter = (dateInfo) => {
  const orderDate = new Date(dateInfo);
  return `${orderDate.getHours()}:${orderDate.getMinutes()}`
}


// --- multiple select filter ---
export const mapFilterOptions = (originalData, propertyToUse, visibleTxtProperty = "label", objectContainerOfPropertySelected) => {
  let mapped = originalData.map((order) => { 
    if(objectContainerOfPropertySelected){
      return { [visibleTxtProperty]: order[objectContainerOfPropertySelected][propertyToUse], value: order[objectContainerOfPropertySelected][propertyToUse] }
    } else {
      return { [visibleTxtProperty]: order[propertyToUse], value: order[propertyToUse] } 
    }
  });
  const uniqueValues = [];
  const uniqueAndMappedOptions = mapped.filter(element => {
    const isDuplicateOrEmpty = uniqueValues.includes(element.value) || !element.value;
    if (!isDuplicateOrEmpty) {
      uniqueValues.push(element.value);
      return true;
    }
    return false;
  });
  return uniqueAndMappedOptions
}

export const filterWithMultipleChoices = (originalData, filterList) => {
  const newArrayFiltered = originalData.filter((el) => {
    return filterList.some((f) => {
      return f.value === el.deliveryMethod;
    });
  });
  if (newArrayFiltered.length > 0) {
    return newArrayFiltered
  } else {
    return originalData
  }
}