import React from 'react'
import { useAuthStore } from '../../store';
import { motion } from 'framer-motion';
import { fadeInAndRight } from '../../services/shared/animation.service';
import { OnboardingStepper } from '../../components/onboarding/OnboardingStepper';
import { LocationsConfig } from '../../components/onboarding/locationConfig/LocationsConfig';

export const LocationsList = () => {
  const ecommerce = useAuthStore((state) => state.authData.user.ecommerce);

  return (
    <div className="container-fluid" id="locationList">
      <div className="row">
        <div className="col-3 p-0">
          <motion.div variants={fadeInAndRight} initial="hidden" animate="visible">
            <OnboardingStepper step={1} ecommerce={ecommerce} />
          </motion.div>
        </div>
        <div className="col-9">
          <LocationsConfig />
        </div>
      </div>
    </div>
  )
}
