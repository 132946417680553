export const fadeInAndUp = {
    hidden: { 
        opacity: 0,
        y: 20
    },
    visible: { 
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            delay: 2.5,
            when: "beforeChildren",
            staggerChildren: 0.5
        }
    }
}

export const fadeInAndUpNoDelay = {
    hidden: { 
        opacity: 0,
        y: 20
    },
    visible: { 
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            delay: 0.2,
            when: "beforeChildren",
            staggerChildren: 0.2
        }
    }
}
export const growIn = {
    hidden: { 
        opacity: 0,
        scale: 0
    },
    visible: { 
        opacity: 1,
        scale: 1,
        transition: {
            type: 'tween',
            duration: 1,
            delay: 0.5,
            when: "beforeChildren",
            staggerChildren: 0.5
        }
    }
}
export const singleCardAn = {
    hidden: { 
        opacity: 0,
        scaleY: .85
    },
    visible: { 
        opacity: 1,
        scaleY: 1,
        transition: {
            type: 'tween',
            duration: 0.35,
            when: "beforeChildren",
        }
    },
    leave:{
        opacity: 0,
        scaleY: 0.85
    },
}

export const growBtn = {
    hidden: { 
        opacity: 0,
        scale: 0
    },
    visible: { 
        opacity: 1,
        scale: 1,
        transition: {
            type: 'tween',
            duration: 0.5,
            delay: 4.5
        }
    }
}
export const growBtnShortDelay = {
    hidden: {
        opacity: 0,
        scale: 0
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            type: 'tween',
            duration: 0.5,
            delay: 2.5
        }
    }
}
export const growCrads = {
    hidden: { 
        opacity: 0,
        scale: 0
    },
    visible: { 
        opacity: 1,
        scale: 1,
        transition: {
            type: 'tween',
            duration: 0.5,
            delay: 2
        }
    }
}

export const growBtnSmallDelay = {
    hidden: { 
        opacity: 0,
        scale: 0
    },
    visible: { 
        opacity: 1,
        scale: 1,
        transition: {
            type: 'tween',
            duration: 0.5,
            delay: 2
        }
    }
}

export const growBigBtn = {
    hidden: { 
        opacity: 0,
        scale: 0
    },
    visible: { 
        opacity: 1,
        scale: 1,
        transition: {
            type: 'tween',
            duration: 0.5,
            delay: 1
        }
    }
}

export const fadeInAndRight = {
    hidden: { 
        opacity: 0,
        x: -50
    },
    visible: { 
        opacity: 1,
        x: 0,
        transition: {
            type: 'spring',
            duration: 1,
            delay: 0.5,
            when: "beforeChildren",
            staggerChildren: 0.2
        }
    }
}

export const fadeInAndUpFastStagger = {
    hidden: { 
        opacity: 0,
        y: 20
    },
    visible: { 
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            delay: 2.5,
            when: "beforeChildren",
            staggerChildren: 0.2
        }
    }
}

export const childVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1
    }
}