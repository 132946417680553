import React from 'react';
import { motion } from 'framer-motion';
import { childVariants } from '../../services/shared/animation.service';

export const OnboardingNav = ({ step, ecommerce }) => {

  const pagesShopify = ["Tiendas", "Ajustes de envío", "¡Listo!"];
  const pagesBigcommerce = ["Tienda", "Ajustes de envío", "¡Listo!"];
  const pagesVtex = ["Tiendas", "Ajustes de envío", "¡Listo!"];

  return (
    <div className="col-6 onboarding-nav">
      {ecommerce === "vtex" && (
        <ul className="pages-titles-list">
          {pagesVtex.map((page, index) => (
            <NavListItem key={index} step={step} page={page} index={index} />
          ))}
        </ul>
      )}
      {ecommerce === "shopify" && (
        <ul className="pages-titles-list">
          {pagesShopify.map((page, index) => (
            <NavListItem key={index} step={step} page={page} index={index} />
          ))}
        </ul>
      )}
      {ecommerce === "bigcommerce" && (
        <ul className="pages-titles-list">
          {pagesBigcommerce.map((page, index) => (
            <NavListItem key={index} step={step} page={page} index={index} />
          ))}
        </ul>
      )}
    </div>
  );
}

export const NavListItem = ({ step, page, index }) => {

  const setStepClass = (i, stepNum) => {
    if(i + 1 === stepNum){
      return "selected"
    }
    if(i + 1 < stepNum ){
      return "visited"
    }
    return ""
  }

  return (
    <motion.li variants={childVariants} key={index} className={setStepClass(index, step)}>
      <div className="circle">{index+1}</div>
      <span>{page}</span>
    </motion.li>
  )
}