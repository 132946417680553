import React, { useEffect, useState, useRef } from 'react';
import { useAuthStore } from '../../store';
import { getOrders } from '../../services/panel.service';
import { showError } from '../../services/shared/alert.service';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Grid } from '../../components/panel/grid/Grid';
import { motion } from 'framer-motion';
import { fadeInAndUp, fadeInAndUpNoDelay } from '../../services/shared/animation.service';
import { Pagination } from '../../components/panel/grid/Pagination';
import { PageInfo } from '../../components/panel/grid/PageInfo';
import { SizePerPageSetter } from '../../components/panel/grid/SizePerPageSetter';
import { LoaderModal } from '../../components/panel/grid/LoaderModal';

const sizePerPageOptions = [
    {
        name: "5",
        value: 5
    },
    {
        name: "10",
        value: 10
    },
    {
        name: "20",
        value: 20
    },
    {
        name: "40",
        value: 40
    },
    {
        name: "50",
        value: 50
    },
    {
        name: "100",
        value: 100
    },
];

export const Orders = ({ ecommerce, shop }) => {
    const user = useAuthStore((state) => state.authData.user);
    const sizeInput = useRef();
    const [rawData, setRawData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [paginationFrom, setPaginationFrom] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterQuery, setFilterQuery] = useState("");
    const [searchTag, setSearchTag] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaderModalShown, setIsLoaderModalShown] = useState(false);
    const [dateFilterSelected, setDateFilterSelected] = useState("");
    const [dateFilterFrom, setDateFilterFrom] = useState("");
    const [dateFilterTo, setDateFilterTo] = useState("");
    const [statusFilterSelected, setStatusFilterSelected] = useState([]);
    const [deliveryServiceFilterSelected, setDeliveryServiceFilterSelected] = useState([]);
    const [storeFilterSelected, setStoreFilterSelected] = useState([]);
    const [deliveryServiceFilterOptions, setDeliveryServiceFilterOptions] = useState([]);

    const getOrdersList = async (storeId = user?.storeId, from = paginationFrom, limit = pageSize, filterParams, sort, tag = searchTag) => {
        setIsLoading(true);
        try {
            const result = await getOrders(storeId, from, limit, filterParams, sort, tag);
            if (result && result.success) {
                if (Object.keys(rawData).length === 0) {
                    setRawData(result.orders);
                }
                setFilteredData(result.orders)
                setTotalAmount(result.total)
                setDeliveryServiceFilterOptions(result?.existingDeliveryServices)
            }
            setSearchTag("");
            setIsLoading(false);
        } catch (error) {
            showError(error);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        changePagination();
    }, [currentPage])

    useEffect(() => {
        if (shop) {
            getOrdersList(shop)
        } else {
            if (user?.storeId) {
                getOrdersList(user?.storeId, paginationFrom, pageSize)
            }
            if (ecommerce === "vtex") {
                getOrdersList("", paginationFrom, pageSize)
            }
        }
    }, [shop, user?.storeId, paginationFrom, pageSize])

    const changePagination = () => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        setPaginationFrom(firstPageIndex);
    }

    let gridProps = {
        data: filteredData, initialData: rawData, updateTable: getOrdersList, ecommerce: ecommerce, originalLoading: isLoading, totalAmountOfOrders: totalAmount, setFilterQuery: setFilterQuery, filterQuery, setIsLoaderModalShown, searchTag, setSearchTag, pageSize, paginationFrom,
        dateFilterSelected, setDateFilterSelected, dateFilterFrom, setDateFilterFrom, dateFilterTo, setDateFilterTo, statusFilterSelected, setStatusFilterSelected, deliveryServiceFilterSelected, setDeliveryServiceFilterSelected, storeFilterSelected, setStoreFilterSelected, deliveryServiceFilterOptions,
        setDeliveryServiceFilterOptions
    }
    return (
        <div className="grid-container">
            <motion.div variants={fadeInAndUpNoDelay} initial="hidden" animate="visible">
                <h1 className="main-title">Órdenes</h1>
                <h2 className="main-subtitle mb-3">Consulta los detalles de tus órdenes y completa los datos faltantes para crear sus envíos.</h2>
            </motion.div>
            <motion.div className="grid-table" variants={fadeInAndUp} initial="hidden" animate="visible">
                {isLoading ?
                    <>
                        <Skeleton count={1} height={600} />
                    </>
                    :
                    <Grid {...gridProps} />
                }
                <div id="sizePerPage-pageInfo-pagination-container">
                    <SizePerPageSetter sizeOptions={sizePerPageOptions} currentSize={pageSize} setSize={setPageSize} sizeRef={sizeInput} />
                    <PageInfo currentPage={currentPage} pageSize={pageSize} totalAmount={totalAmount} />
                    <Pagination
                        className="pagination-bar"
                        siblingCount={2}
                        currentPage={currentPage}
                        totalCount={totalAmount}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
            </motion.div>
            {isLoaderModalShown &&
                <LoaderModal />
            }
        </div>
    )
}
