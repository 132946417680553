import React from 'react'
import { useAuthStore } from '../../../store';
import { retryOrder } from '../../../services/panel.service';
import { ButtonSmall } from '../../units/Button'
import gridPlusFile from "../../../assets/icons/gridPlusFile.svg";
import gridCancel from "../../../assets/icons/gridCancel.svg";
import { showError, showSuccess } from '../../../services/shared/alert.service';

export const SubactionsMenuModal = ({ orderData, updateTable, handleClose, showConfirmationModal }) => {
    const user = useAuthStore((state) => state.authData.user);

    const retryCreateOrder = async (e) => {
        e.preventDefault();
        try {
            const result = await retryOrder({ orderIds: [orderData.orderId] }, user?.storeId);
            if (result && result.success) {
                showSuccess(result?.message);
                setTimeout(() => {
                    updateTable(user?.storeId)
                }, 500);
            }
            if (result.error) {
                showError(result.error);
            }
            handleClose();
        } catch (error) {
            showError(error);
            handleClose();
        }
    }

    return (
        <div className='subactions-container'>
            <ul className='subactions-list'>
                <li className='subactions-item'>
                    <ButtonSmall
                        type="button"
                        btnTxt="Volver a crear envío"
                        showSpinner={false}
                        isDisabled={false}
                        onClickFn={retryCreateOrder}
                        extraClass="btn-link"
                        icon={<img src={gridPlusFile} alt="retry icon" />}
                    />
                </li>
                <li className='subactions-item'>
                    <ButtonSmall
                        type="button"
                        btnTxt="Cancelar envío"
                        showSpinner={false}
                        isDisabled={false}
                        onClickFn={() => {showConfirmationModal(true); handleClose()}}
                        extraClass="btn-link"
                        icon={<img src={gridCancel} alt="cancel icon" />}
                    />
                </li>
            </ul>
        </div>
    )
}
