import React from 'react';
import { motion } from 'framer-motion';
import { childVariants } from '../../../services/shared/animation.service';
import { InputsSelect, InputsSimple, InputsTextarea } from '../../units/Inputs';

export const InputsGroupForCreation = ({ ecommerce, formErrors, handleChange, ...body }) => {
    return (
        <>
            <div className="d-flex flex-column justify-content-start">
                {(ecommerce === "vtex" || ecommerce === "shopify") &&
                    <motion.div variants={childVariants}>
                        <InputsSimple
                            value={body.name}
                            id="name"
                            type="text"
                            labelTxt={ecommerce === "vtex" ? "Nombre del muelle*" : "Nombre personalizado de tienda*"}
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.name ? "is-invalid" : ""}
                            errorTxt={false}
                        />
                    </motion.div>
                }

                <motion.div variants={childVariants}>
                    <InputsSimple
                        value={body.phoneNumber}
                        id="phoneNumber"
                        type="text"
                        placeholder="Ej: 11234567890, 123-456-7890, (123) 456-7890, +1 123.456.7890, +91 (123) 456-7890"
                        labelTxt="Teléfono*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.phoneNumber ? "is-invalid" : ""}
                        errorTxt={formErrors.phoneNumber}
                    />
                </motion.div>

                <motion.div variants={childVariants}>
                    <InputsSimple
                        value={body.street}
                        id="street"
                        type="text"
                        labelTxt="Calle*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.street ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                </motion.div>

                <motion.div variants={childVariants}>
                    <InputsSimple
                        value={body.zip}
                        id="zip"
                        type="text"
                        labelTxt="Código postal*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.zip ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                </motion.div>

                <motion.div variants={childVariants}>
                    <InputsSimple
                        value={body.province}
                        id="province"
                        type="text"
                        labelTxt="Estado*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.province ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                </motion.div>

                <motion.div variants={childVariants}>
                    <InputsSelect
                        value={body.countryCode}
                        id="countryCode"
                        labelTxt="País*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.countryCode ? "is-invalid" : ""}
                        arrayList={[{ value: "MX", name: "México" }, { value: "CO", name: "Colombia" }]}
                        previouslySelected={body.countryCode}
                    />
                </motion.div>

            </div>
            <div className="d-flex flex-column justify-content-start">
                <motion.div variants={childVariants}>
                    <InputsSimple
                        value={body.manager}
                        id="manager"
                        type="text"
                        labelTxt="Nombre de quien prepara los envíos*"
                        placeholder="Nombre y apellido"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.manager ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                </motion.div>

                <motion.div variants={childVariants}>
                    <InputsSimple
                        value={body.email}
                        id="email"
                        type="text"
                        placeholder="email@example.com"
                        labelTxt={ecommerce === "bigcommerce" ? "Mail*" : "Email*"}
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.email ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                </motion.div>

                <div className="double-input-col">
                    <motion.div variants={childVariants}>
                        <InputsSimple
                            value={body.internalNumber}
                            id="internalNumber"
                            type="text"
                            labelTxt="Número interno"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.internalNumber ? "is-invalid" : ""}
                            errorTxt={false}
                        />
                    </motion.div>

                    <motion.div variants={childVariants}>
                        <InputsSimple
                            value={body.externalNumber}
                            id="externalNumber"
                            type="text"
                            labelTxt="Número ext.*"
                            onChangeFn={handleChange}
                            resultValidation={formErrors?.externalNumber ? "is-invalid" : ""}
                            errorTxt={false}
                        />
                    </motion.div>
                </div>

                <motion.div variants={childVariants}>
                    <InputsSimple
                        value={body.city}
                        id="city"
                        type="text"
                        labelTxt="Ciudad*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.city ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                </motion.div>

                <motion.div variants={childVariants}>
                    <InputsTextarea
                        value={body.notes}
                        id="notes"
                        labelTxt="Referencias adicionales"
                        onChangeFn={handleChange}
                        rows={1}
                        resultValidation={formErrors?.notes ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                </motion.div>
            </div>
        </>
    )
}
