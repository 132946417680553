import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { fadeInAndUp, growBtn } from '../../services/shared/animation.service';
import { useAuthStore } from '../../store';
import { Link, useHistory } from 'react-router-dom';
import { ButtonSmall } from '../units/Button';
import { customerRouteName } from '../../data/InfoSource';
import { changeShopifyName } from '../../services/shared/utils.service';

const accessCode = process.env.REACT_APP_ACCESS_CODE;

export const EmbeddedDeliveryConfig = ({ ecommerce, token, idCode }) => {
    let history = useHistory();
    const user = useAuthStore((state) => state.authData.user);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div id="delivery-service-form" className='content-wrapper'>
            <motion.div variants={fadeInAndUp} initial="hidden" animate="visible">
                <h1 className="main-title">{ecommerce === "vtex" ? "Crear políticas de envío" : "Ajustes de envío"}</h1>
                <h2 className="main-subtitle mb-4 mb-md-5">Personaliza y guarda los ajustes de tu integración.</h2>
            </motion.div>

            <motion.div className='iframe-container' variants={fadeInAndUp}>
                <iframe src={`https://uat-administrative-panel.skydropx.com/access?shop_id=${idCode}&access_code=${accessCode}`} className='iframe-embedded' frameBorder="0"></iframe>
            </motion.div>

            {!user?.insidePanel &&
                <div className="bottomPage doubleBtn">
                    <motion.div variants={growBtn} initial="hidden" animate="visible">
                        <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}/location-list`} className="btn-link">Regresar</Link>
                    </motion.div>

                    <motion.div variants={growBtn} initial="hidden" animate="visible">
                        <ButtonSmall
                            type="button"
                            btnTxt="Continuar"
                            showSpinner={isLoading}
                            isDisabled={false}
                            onClickFn={() => history.push(`/${customerRouteName}/${changeShopifyName(ecommerce)}/finish`)}
                        />
                    </motion.div>
                </div>
            }
        </div>
    )
}
