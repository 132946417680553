import React from 'react';

export const LoaderModal = () => {
  return (
    <div id="loader-modal">
      <svg
        width="191"
        height="116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M70.863 110.974c-8.052-4.016-16.841-15.687-16.841-15.687s-21.188 7.032-34.769-7.032C5.673 74.19 8.388 49.85 18.71 38.49c10.322-11.36 23.904-9.737 23.904-9.737s3.802-15.145 19.014-23.26c15.21-8.113 29.879-6.49 41.83 0 11.952 6.492 18.471 19.474 18.471 19.474s8.692-10.818 24.99-5.41c16.298 5.41 16.298 20.015 16.298 20.015s13.376.014 19.557 4.868c12.533 9.843 10.188 32.006-3.259 40.57-6.7 4.265-20.101 3.245-20.101 3.245s-2.173 9.59-15.754 10.818c-13.582 1.23-21.731-3.786-21.731-3.786s-7.38 11.514-14.668 15.687c-12.322 7.055-23.689 6.338-36.398 0Z"
          fill="#F8F8FD"
        />
        <g id="airplane1">
          <path
            d="M22.456 82.712 2 67.394l101.564-9.779L63.52 108.4l-13.937-8.532-17.712 7.098-9.415-24.254Z"
            fill="#D5D7F2"
          />
          <path
            d="M22.456 82.712 2 67.394l101.564-9.779M22.456 82.712l27.036-8.365 54.072-16.732M22.456 82.712l9.415 24.254m71.693-49.35L63.52 108.4m40.044-50.785L58.072 78.352 35.325 88.721M63.52 108.4l-13.937-8.532M63.52 108.4 35.325 88.72m14.258 11.148-17.712 7.098m17.712-7.098L35.325 88.72m-3.454 18.245 3.454-18.245"
            stroke="#031256"
            strokeWidth="{2.5}"
            strokeLinejoin="round"
          />
          <path
            d="M31.648 103.515 23.71 83.312l66.383-20.564-55.56 25.254-2.886 15.513Z"
            fill="#F6F6FB"
          />
        </g>
        <g id="airplane2">
          <path
            d="m130.587 91.948-10.182-6.58 47.747-7.949-17.291 25.344-6.875-3.583-8.15 3.939-5.249-11.17Z"
            fill="#D5D7F2"
          />
          <path
            d="m130.587 91.948-10.182-6.58 47.747-7.949m-37.565 14.53 12.521-4.844 25.044-9.686m-37.565 14.53 5.249 11.17m32.316-25.7-17.291 25.344m17.291-25.344L147.3 88.721l-10.426 5.65m13.987 8.392-6.875-3.583m6.875 3.583-13.987-8.392m7.112 4.81-8.15 3.938m8.15-3.939-7.112-4.809m-1.038 8.748 1.038-8.748"
            stroke="#031256"
            strokeWidth="{2}"
            strokeLinejoin="round"
          />
          <path
            d="m135.617 101.494-4.416-9.302 30.743-11.903-25.468 13.768-.859 7.437Z"
            fill="#F6F6FB"
          />
        </g>
        <g id="airplane3">
          <path
            d="m124.536 31.068-7.247-5.62 36.503-2.897-14.675 17.982-4.95-3.146-6.398 2.44-3.233-8.76Z"
            fill="#D5D7F2"
          />
          <path
            d="m124.536 31.068-7.247-5.62 36.503-2.897m-29.256 8.517 9.752-2.84 19.504-5.677m-29.256 8.517 3.233 8.76m26.023-17.277-14.675 17.982m14.675-17.982-16.449 7.167-8.225 3.583m9.999 7.232-4.95-3.146m4.95 3.146-9.999-7.232m5.049 4.086-6.398 2.44m6.398-2.44-5.049-4.086m-1.349 6.526 1.349-6.526"
            stroke="#031256"
            strokeWidth="{2}"
            strokeLinejoin="round"
          />
          <path
            d="m127.71 38.588-2.727-7.297 23.945-6.979-20.089 8.727-1.129 5.55Z"
            fill="#F6F6FB"
          />
        </g>
      </svg>
      <h2 className='loader-title'>Estamos creando tu envío</h2>
      <p className='loader-text'>Por favor, no recargues la página y aprovecha para pensar en tu siguiente envío.</p>
    </div>
  )
}
