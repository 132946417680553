import React from 'react';
import SelectSearch from 'react-select-search';
import { ClassesInfiniteScroll } from './ClassesInfiniteScroll';

export const WaybillSelects = ({
    refPackage,
    optionsPackage,
    valuePackage,
    handlePackageChange,
    body,
    setBody
}) => {

    const handleFilter = (list, defaultList) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return defaultList;
            }
            const foundOptions = list.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
            return foundOptions
        };
    };

    return (
        <div id='waybill-select-container'>
            <div className='waybill-select-wrapper'>
                <h4 className='main-subtitle semibold mb-3'>Empaque</h4>
                <p className='main-subtitle mb-3'>Selecciona el tipo de empaque para tus envíos.</p>
                <SelectSearch
                    ref={refPackage}
                    options={optionsPackage}
                    search
                    filterOptions={(e) => handleFilter(e, optionsPackage)}
                    value={valuePackage}
                    name="package"
                    placeholder="Seleccionar"
                    onChange={handlePackageChange}
                    className="waybill-select"
                />
            </div>

            <div className='waybill-select-wrapper'>
                <h4 className='main-subtitle semibold mb-3'>Clase de producto</h4>
                <p className='main-subtitle mb-3'>Selecciona la clase de producto. La clasificación corresponde al catálogo del SAT.</p>
                <ClassesInfiniteScroll body={body} setBody={setBody} />
                {body.waybillClass.name && <p className='main-subtitle'>Clase seleccionada: {body.waybillClass.name}</p>}
                <p className='extra-info'>El Sistema de Administración Tributaria (SAT) exige el requisito legal Carta Porte para realizar tu envío.</p>
                <a href="https://blog.skydropx.com/complemento-carta-porte/" target="_blank" rel="noreferrer" className='extra-info-external-link'>Conocer más sobre Complemento Carta Porte Digital.</a>
            </div>
        </div>
    )
}
