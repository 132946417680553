import React from 'react';
import { DateTime } from "luxon";
import { MultiSelect } from "react-multi-select-component";
import { spanishStrings } from '../../../services/panel.service';
import { FiSearch } from "react-icons/fi";
import SelectSearch from 'react-select-search';
import filter from '../../../assets/icons/filter.svg';
import filterBlack from '../../../assets/icons/filterBlack.svg';
import close from '../../../assets/icons/close.svg';
import { ButtonSmall } from '../../units/Button';
import { DefaultItemRenderer } from './DefaultItemRenderer';

export const SearchAndFilters = ({ ...filtersProps }) => {

  const {
    setIsFilterDropdownShown,
    statusOptions,
    statusFilterSelected,
    setStatusFilterSelected,
    dateInput,
    dateOptions,
    dateFilterSelected,
    setDateFilterSelected,
    setDateFilterFrom,
    setDateFilterTo,
    storeFilterOptions,
    storeFilterSelected,
    setStoreFilterSelected,
    deliveryServiceFilterOptions,
    deliveryServiceFilterSelected,
    setDeliveryServiceFilterSelected,
    isFilterDropdownShown,
    cleanupFilters,
    searchTag,
    setSearchTag,
    filterOrders,
    ecommerce
  } = filtersProps;

  const handleDateChange = (selectedValue) => {
    let dateFrom = DateTime;
    let dateTo = DateTime;
    let defaultInitialDate = "";

    switch (selectedValue) {
      case "ALL":
        defaultInitialDate = "1970-01-01T00:00:00Z";
        dateTo = DateTime.now().toUTC().endOf("day");
      case 'TODAY':
        dateFrom = DateTime.now().toUTC().startOf("day");
        dateTo = DateTime.now().toUTC().endOf("day");
        break;
      case 'YESTERDAY':
        dateFrom = DateTime.now().toUTC().minus({ days: 1 }).startOf('day');
        dateTo = DateTime.now().toUTC().minus({ days: 1 }).endOf("day");
        break;
      case 'LAST_WEEK':
        dateFrom = DateTime.now().toUTC().minus({ days: 7 }).startOf('day');
        dateTo = DateTime.now().toUTC().endOf("day");
        break;
      case 'LAST_FIFTEEN_DAYS':
        dateFrom = DateTime.now().toUTC().minus({ days: 15 }).startOf('day');
        dateTo = DateTime.now().toUTC().endOf("day");
        break;
      case 'LAST_THIRTY_DAYS':
        dateFrom = DateTime.now().toUTC().minus({ days: 30 }).startOf('day');
        dateTo = DateTime.now().toUTC().endOf("day");
        break;
      case 'THIS_MONTH':
        dateFrom = DateTime.now().toUTC().startOf('month').startOf('day');
        dateTo = DateTime.now().toUTC().endOf("day");
        break;
      case 'LAST_MONTH':
        dateFrom = DateTime.now().toUTC().minus({ month: 1 }).startOf('month').startOf('day');
        dateTo = DateTime.now().toUTC().minus({ month: 1 }).endOf("month").endOf("day");
        break;
      case 'LAST_TWO_MONTHS':
        dateFrom = DateTime.now().toUTC().minus({ month: 2 }).startOf('month').startOf('day');
        dateTo = DateTime.now().toUTC().minus({ month: 0 }).endOf("month").endOf("day");
        break;
      case 'LAST_THREE_MONTHS':
        dateFrom = DateTime.now().toUTC().minus({ month: 3 }).startOf('month').startOf('day');
        dateTo = DateTime.now().toUTC().minus({ month: 0 }).endOf("month").endOf("day");
        break;
      default:
        throw new Error('Invalid date filter type');
    }
    if (defaultInitialDate) {
      setDateFilterFrom(defaultInitialDate);
    } else {
      setDateFilterFrom(dateFrom.toString());
    }
    setDateFilterTo(dateTo.toString());
    setDateFilterSelected(selectedValue);
    defaultInitialDate = "";
  }

  return (
    <div className='search-and-filter-container'>
      <form className='search-wrapper' onSubmit={(e) => filterOrders(e, e.target.value)}>
        <FiSearch size={20} />
        <input onChange={(e) => setSearchTag(e.target.value)} value={searchTag} type="text" name="q" id="search-input" placeholder='Buscar por núm. de rastreo, orden, nombre o ciudad de destino' />
      </form>
      <div className='filter-wrapper'>
        <img src={filter} alt="filter" />
        <button className='filter-dropdown-btn'
          type='button'
          onClick={() => { setIsFilterDropdownShown(!isFilterDropdownShown) }}
        >
          Filtrar
        </button>
      </div>
      {isFilterDropdownShown &&
        <div id='filter-dropdown-container'>
          <div className='dropdown-top mb-4'>
            <div className='dropdown-title'>
              <img src={filterBlack} alt="filter" />
              <span className='main-subtitle'>
                Filtrar
              </span>
            </div>
            <button className='filter-dropdown-close'
              onClick={() => { setIsFilterDropdownShown(false) }}
            >
              <img src={close} alt="close" />
            </button>
          </div>

          <MultiSelect
            options={statusOptions}
            ItemRenderer={DefaultItemRenderer}
            value={statusFilterSelected}
            onChange={setStatusFilterSelected}
            labelledBy="Select"
            overrideStrings={{ ...spanishStrings, "selectSomeItems": "Estatus", "selectAll": "Todos" }}
            className='multi-select-container inside-grid'
            disableSearch={true}
          />

          <MultiSelect
            options={deliveryServiceFilterOptions}
            value={deliveryServiceFilterSelected}
            onChange={setDeliveryServiceFilterSelected}
            labelledBy="Select"
            overrideStrings={{ ...spanishStrings, "selectSomeItems": "Paquetería" }}
            className='multi-select-container inside-grid'
            disableSearch={true}
          />

          <SelectSearch
            ref={dateInput}
            options={dateOptions}
            value={dateFilterSelected}
            name="class"
            placeholder="Fecha de ingreso"
            onChange={(e) => handleDateChange(e)}
            className='date-select'
          />

          {ecommerce !== "bigcommerce" &&
            <MultiSelect
              options={storeFilterOptions}
              value={storeFilterSelected}
              onChange={setStoreFilterSelected}
              labelledBy="Select"
              overrideStrings={{ ...spanishStrings, "selectSomeItems": "Tienda" }}
              className='multi-select-container inside-grid'
              disableSearch={true}
            />
          }

          <div className='filter-btn-wrapper'>
            <ButtonSmall
              type="button"
              btnTxt="X Eliminar filtros"
              showSpinner={false}
              isDisabled={false}
              onClickFn={() => cleanupFilters()}
              extraClass="btn-outline-primary fw-light clean-filters"
            />
          <ButtonSmall
            type="button"
            btnTxt="Aplicar"
            showSpinner={false}
            isDisabled={false}
            onClickFn={(e) => { filterOrders(e); setIsFilterDropdownShown(false) }}
            extraClass="btn-outline-primary fw-light block"
          />
          </div>
        </div>
      }
    </div>
  )
}