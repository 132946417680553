import React, { useEffect, useState } from 'react';
import tableCellLogo from '../../../assets/customerLogo/tableCellLogo.png';
import { classColorSetter, colorSetter, dateFormatter, packageSizeFormatter, statusTranslator } from '../../../services/grid.service';
import { nameShortener, validateFullInfoComplete } from '../../../services/shared/utils.service';
import { ButtonSmall } from '../../units/Button';
import { BsPlus } from "react-icons/bs";
import dropdownFullArrow from "../../../assets/icons/dropdownFullArrow.svg";
import { BsDownload, BsThreeDotsVertical } from "react-icons/bs";
import { SubactionsMenuModal } from './SubactionsMenuModal';
import { PackageSetterModal } from './PackageSetterModal';
import { FulfillOrderModal } from './FulfillOrderModal';
import { QuotationSetterModal } from './QuotationSetterModal';
import { OrderDetailsModal } from './OrderDetailsModal';
import { CancelConfirmationModal } from './CancelConfirmationModal';

export const GridRow = ({ orderInfo, updateTable, rowIndex, isWaiting, pageSize }) => {
    const [isPackageSet, setIsPackageSet] = useState(false);
    const [isDeliveryServiceSet, setIsDeliveryServiceSet] = useState(false);
    const [isSubactionsModalShown, setIsSubactionsModalShown] = useState(false);
    const [isPackageModalShown, setIsPackageModalShown] = useState(false);
    const [isQuotationModalShown, setIsQuotationModalShown] = useState(false);
    const [isFullfillModalShown, setIsFullfillModalShown] = useState(false);
    const [isDetailsModalShown, setIsDetailsModalShown] = useState(false);
    const [isCancelConfirmationModalShown, setIsCancelConfirmationModalShown] = useState(false);
    const { weight, height, width, length, quantity, packageType, waybillClass } = orderInfo.packageInfo;
    const { provider, serviceLevelName, estimatedTime, shippingCost, currency } = orderInfo.deliveryService;

    useEffect(() => {
        validateFullInfoComplete([weight, height, width, length, quantity, packageType.id, waybillClass.id], setIsPackageSet);
        validateFullInfoComplete([provider, serviceLevelName, estimatedTime, shippingCost, currency], setIsDeliveryServiceSet);
    }, [orderInfo])

    return (
        <ul className={`table-row ${classColorSetter(orderInfo.trackingStatus)}`}>
            <li className='cell-origin'>
                <div className='line-top'>
                    <img src={tableCellLogo} alt="logo" />
                    <p className='black-semibold'>{orderInfo.orderNumber}</p>
                </div>
                <p className='secondary'>{dateFormatter(orderInfo.creationDate)}</p>
            </li>
            <li className='cell-destination'>
                <p className='black-semibold'>{orderInfo.userData.name}</p>
                <p className='secondary'>{orderInfo.userData.city}, {orderInfo.userData.province}</p>
            </li>

            {!isPackageSet ?
                <li className='cell-button green-item'>
                    <ButtonSmall
                        type="button"
                        btnTxt="Paquete"
                        showSpinner={false}
                        isDisabled={false}
                        onClickFn={() => setIsPackageModalShown(!isPackageModalShown)}
                        extraClass="btn-link green"
                        icon={<BsPlus />}
                    />
                    {isPackageModalShown &&
                        <PackageSetterModal orderData={orderInfo} handleClose={() => setIsPackageModalShown(false)} updateTable={updateTable} rowIndex={rowIndex} pageSize={pageSize} />
                    }
                </li>
                :
                <>
                    {orderInfo.trackingStatus === "PENDING" ?
                        <li className='cell-package'>
                            <div className='package-info'>
                                <p>{nameShortener(orderInfo.packageInfo.packageType?.name, 14, 14)}</p>
                                <p className='secondary'>{packageSizeFormatter(orderInfo.packageInfo)} {orderInfo.packageInfo.weight}Kg.</p>
                            </div>
                            <button
                                type='button'
                                onClick={() => setIsPackageModalShown(!isPackageModalShown)}
                            >
                                <img src={dropdownFullArrow} alt="package menu" />
                            </button>
                            {isPackageModalShown &&
                                <PackageSetterModal orderData={orderInfo} handleClose={() => setIsPackageModalShown(false)} updateTable={updateTable} rowIndex={rowIndex} pageSize={pageSize} />
                            }
                        </li>
                        :
                        <li className='package-info'>
                            <p>{nameShortener(orderInfo.packageInfo.packageType?.name, 14, 14)}</p>
                            <p className='secondary'>{packageSizeFormatter(orderInfo.packageInfo)} {orderInfo.packageInfo.weight}Kg.</p>
                        </li>
                    }
                </>
            }

            {!isPackageSet || !isDeliveryServiceSet ?
                <li className='cell-button green-item'>
                    <ButtonSmall
                        type="button"
                        btnTxt="Cotizar"
                        showSpinner={false}
                        isDisabled={false}
                        onClickFn={() => setIsQuotationModalShown(!isQuotationModalShown)}
                        extraClass="btn-link green"
                        icon={<BsPlus />}
                    />
                    {isQuotationModalShown &&
                        <QuotationSetterModal orderData={orderInfo} handleClose={() => setIsQuotationModalShown(false)} updateTable={updateTable} isPackageSet={isPackageSet} rowIndex={rowIndex} pageSize={pageSize} />
                    }
                </li>
                :
                <>
                    {orderInfo.trackingStatus === "PENDING" ?
                        <li className='cell-quote'>
                            <div className='quote-info'>
                                <p className='black-semibold'>{nameShortener(orderInfo.deliveryService.provider, 12, 12)}</p>
                                <p className='secondary'>${orderInfo.deliveryService.shippingCost + " " + orderInfo.deliveryService.currency}</p>
                            </div>
                            <button
                                type='button'
                                onClick={() => setIsQuotationModalShown(!isQuotationModalShown)}
                            >
                                <img src={dropdownFullArrow} alt="delivery menu" />
                            </button>
                            {isQuotationModalShown &&
                                <QuotationSetterModal orderData={orderInfo} handleClose={() => setIsQuotationModalShown(false)} updateTable={updateTable} isPackageSet={isPackageSet} rowIndex={rowIndex} pageSize={pageSize} />
                            }
                        </li>
                        :
                        <li className='quote-info'>
                            <p className='black-semibold'>{orderInfo.deliveryService.provider} <a href={orderInfo?.trackingUrl} className='blue-id' target="_blank" rel="noreferrer">{orderInfo.trackingNumber}</a></p>
                            <p className='secondary'>${orderInfo.deliveryService.shippingCost + " " + orderInfo.deliveryService.currency}</p>
                        </li>
                    }
                </>
            }
            <li className='cell-status'>
                <div className='line-top'>
                    <img src={colorSetter(statusTranslator(orderInfo.trackingStatus))} alt={`${orderInfo.trackingStatus}`} />
                    <p className='black-semibold'>{statusTranslator(orderInfo.trackingStatus)}</p>
                </div>
                <p className='secondary'>{dateFormatter(orderInfo.updateStatusDate, false)}</p>
            </li>
            {orderInfo.trackingStatus !== "PENDING" ?
                <>
                    <li className='cell-button'>
                        <ButtonSmall
                            type="button"
                            btnTxt="Ver detalles"
                            showSpinner={false}
                            isDisabled={false}
                            onClickFn={() => setIsDetailsModalShown(true)}
                            extraClass="btn-link"
                        />
                    </li>
                    {orderInfo.trackingStatus !== "ERROR" &&
                        <li className='cell-actions'>
                            <a href={orderInfo.label} target="_blank" rel="noreferrer" download="etiqueta">
                                <BsDownload size={20} />
                            </a>
                            <div className={`actions-wrapper ${rowIndex + 1 === pageSize ? "last" : ""}`}>
                                <button type='button'
                                    onClick={() => setIsSubactionsModalShown(!isSubactionsModalShown)}>
                                    <BsThreeDotsVertical size={20} />
                                </button>
                                {isSubactionsModalShown &&
                                    <SubactionsMenuModal orderData={orderInfo} updateTable={updateTable} handleClose={() => setIsSubactionsModalShown(false)} showConfirmationModal={setIsCancelConfirmationModalShown} />
                                }
                            </div>
                        </li>
                    }
                </>
                :
                <li className='cell-button'>
                    <ButtonSmall
                        type="button"
                        btnTxt="Revisar orden"
                        showSpinner={false}
                        isDisabled={isPackageSet && isDeliveryServiceSet ? false : true}
                        onClickFn={() => setIsFullfillModalShown(true)}
                        extraClass="btn-link"
                    />
                </li>
            }
            {isFullfillModalShown &&
                <FulfillOrderModal show={isFullfillModalShown} handleClose={() => setIsFullfillModalShown(false)} orderData={orderInfo} isPackageSet={isPackageSet} isDeliveryServiceSet={isDeliveryServiceSet} updateTable={updateTable} isWaiting={isWaiting} />
            }
            {isDetailsModalShown &&
                <OrderDetailsModal show={isDetailsModalShown} handleClose={() => setIsDetailsModalShown(false)} orderData={orderInfo} />
            }
            {isCancelConfirmationModalShown &&
                <CancelConfirmationModal show={isCancelConfirmationModalShown} handleClose={() => setIsCancelConfirmationModalShown(false)} orderData={orderInfo} updateTable={updateTable} />
            }
        </ul>
    )
}
