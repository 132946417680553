import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { signIn } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { validateInputs } from '../../../services/shared/utils.service';
import { useAuthStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { InputsSimple } from '../../units/Inputs';
import { motion } from 'framer-motion';
import { childVariants, growBigBtn } from '../../../services/shared/animation.service';
import { customerRouteName } from '../../../data/InfoSource';
import { FiEye, FiEyeOff } from "react-icons/fi";

export const SignInForm = ({ ecommerce }) => {
    const [isLoading, setIsLoading] = useState("");
    const [formErrors, setFormErrors] = useState({})
    const [body, setBody] = useState({
        storeUrl: "",
        password: ""
    });
    const [showPassword, setShowPassword] = useState(false);

    const addAuth = useAuthStore((state) => state.addAuthData);
    const user = useAuthStore((state) => state.authData.user);
    let history = useHistory();

    const handleChange = (e) => {
        setBody({ ...body, [e.target.id]: (e.target.value).trim() })
    }

    const signInVtex = async (e) => {
        e.preventDefault();
        if (validateInputs(body, setFormErrors)) {
            setIsLoading(true);
            try {
                const result = await signIn(body);
                if (result && result.success) {
                    addAuth({
                        user: {
                            ...user,
                            storeUrl: body.storeUrl,
                            token: result.token
                        }
                    });
                    showSuccess(result.message);
                    history.replace(`/${customerRouteName}/${ecommerce}/panel/order`);
                }
                setIsLoading(false);
            } catch (error) {
                setFormErrors({storeUrl: "error", password: "error"})
                setIsLoading(false);
                showError(error);
            }
        } else {
            return
        }
    }

    return (
        <form onSubmit={signInVtex}>
            <motion.div variants={childVariants}>
                <InputsSimple
                    value={body.storeUrl}
                    id="storeUrl"
                    type="text"
                    placeholder="https://nombredetienda.myvtex.com"
                    labelTxt="URL de la tienda de VTEX*"
                    onChangeFn={handleChange}
                    resultValidation={formErrors?.storeUrl ? "is-invalid" : ""}
                />
            </motion.div>

            {ecommerce === "vtex" &&
                <motion.div variants={childVariants} className="parent-box-input">
                    <InputsSimple
                        value={body.password}
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder={showPassword ? body.password : '******'}
                        labelTxt="Contraseña*"
                        onChangeFn={handleChange}
                        resultValidation={formErrors?.password ? "is-invalid" : ""}
                        errorTxt={false}
                    />
                    <div className="child-icon">
                        {showPassword ? <FiEyeOff onClick={() => setShowPassword(!showPassword)} /> : <FiEye onClick={() => setShowPassword(!showPassword)} />}
                    </div>
                </motion.div>
            }

            <motion.div variants={childVariants}>
                <p className="small-subtitle">
                    ¿Es tu primera vez con esta integración? <button type='button' onClick={() => history.push(`/${customerRouteName}/vtex/vconfig`)} target="_blank" rel="noreferrer" className="btn-link initial-link p-0">Comienza aquí</button>.
                </p>
            </motion.div>

            <motion.div variants={growBigBtn} className="login-btn">
                <ButtonSmall
                    type="submit"
                    btnTxt="Ingresar"
                    showSpinner={isLoading}
                    isDisabled={(body.storeUrl && body.password) ? false : true}
                    extraClass="block"
                />
            </motion.div>
        </form>
    )
}
