import React from 'react';
import { motion } from 'framer-motion';
import { customerRouteName } from '../../../data/InfoSource';
import { childVariants, fadeInAndUpNoDelay, growBtn, growBtnSmallDelay } from '../../../services/shared/animation.service';
import { Link } from 'react-router-dom';
import { changeShopifyName, noSpacedNameShortener, validateFullAddress } from '../../../services/shared/utils.service';
import { useAuthStore } from '../../../store';
import outOfCoverageIllustration from '../../../assets/outOfCoverageIllustration.png';

export const OutOfCoverage = ({ ecommerce, singleLocationIndex, ...singleLocation }) => {
    const user = useAuthStore((state) => state.authData.user);
    const previousRouteParam = user?.insidePanel ? "panel/location-list" : "location-list";

    return (
        <div className="outOfCoverage-wrapper">
            <motion.div variants={fadeInAndUpNoDelay} initial="hidden" animate="visible">
                <div className='text-wrapper'>
                    <h1 className="main-title">{singleLocation?.name ? noSpacedNameShortener(singleLocation.name, 30) : `Tienda ${singleLocationIndex + 1}`}</h1>
                    <h2 className="main-subtitle mb-4 mb-md-5">{validateFullAddress([singleLocation.address, singleLocation.city, singleLocation.province, singleLocation.country])}</h2>
                </div>
                {ecommerce === "vtex" &&
                    <motion.div className='notification' variants={childVariants}>
                        <div className="notification-text-wrapper">
                            <p className='main-subtitle'>La tienda se encuentra <span className="fw-bold">fuera de la zona de entrega</span>.<br></br>Si hay algún error en la configuración de esta tienda, puedes modificarla desde tu <span className="fw-bold">administrador de VTEX</span>.</p>
                        </div>
                    </motion.div>
                }
                <img className='outOfCoverage-illustration' src={outOfCoverageIllustration} alt="out of coverage" />
                <div className="bottomPage doubleBtn">
                    <motion.div variants={growBtnSmallDelay} initial="hidden" animate="visible">
                        <Link to={`/${customerRouteName}/${changeShopifyName(ecommerce)}/${previousRouteParam}`} className="btn-link">Regresar</Link>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    )
}
